import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { apiUrl } from '../../apiUrl/ApiUrl';

export default function ToShipList() {
    const [newData,setNewData] = useState(null);
    const [selected, setSelected] = useState('');
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('出貨明細');
    const [page,setPage] = useState(1);
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
    }

    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row' style={workMargin}>
                <div className='col-6'>
                    <SelectFilter setNewData={setNewData}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {/* {model === '訂單總覽'?<h2><span className="badge bg-dark">搜尋條件</span></h2>:null} */}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {/* {model === '訂單總覽'?<SelectFilter setNewData={setNewData}/> : null}
                    {model === '訂單輸入'?<CreateForm/> : null} */}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">出貨資料</span></h2>
                </div>        
            </div>
            <div className='row'>
                <div className='col-12'>
                    {model === '出貨明細'?<ListTable setDetailData={setDetailData} 
                    setNewData={setNewData} data={newData} 
                    setModel={setModelandGetDate} page={page} 
                    setPage={setPage}/> :<div>目前還沒取得資料或取不到資料</div>}
                </div>             
            </div>
        </div>     
    )
}



function ListTable({data,setNewData,setModel,setDetailData,page,setPage}) {
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }
    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0'); // 轉換月份為兩位數字
        const day = d.getDate().toString().padStart(2, '0'); // 轉換日為兩位數字
        return `${year}/${month}/${day}`;
    };

    const nextPage = () => {
        const today = formatDate(new Date()); // 獲取當天日期
        axios.get(`${apiUrl}/ToShip/GetMains?month=${today}&page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                } else {
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error => {
                console.log(error);
            })
    };
    
    const prePage = () => {
        const today = formatDate(new Date()); // 獲取當天日期
        axios.get(`${apiUrl}/ToShip/GetMains?month=${today}&page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }            
            })
            .catch(error => {
                console.log(error);
            })
    };

    return(
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" style={thStyle.centered}>#</th>
                        <th scope="col" style={thStyle.centered}>客戶</th>
                        <th scope="col" style={thStyle.centered}>出貨日期</th>
                        <th scope="col" style={thStyle.centered}>出貨單號</th>
                        <th scope="col" style={thStyle.centered}>批號</th>
                        <th scope="col" style={thStyle.centered}>圖號</th>
                        <th scope="col" style={thStyle.centered}>項次</th>
                        <th scope="col" style={thStyle.centered}>物品名</th>
                        <th scope="col" style={thStyle.centered}>實交貨數</th>
                        <th scope="col" style={thStyle.centered}>單位</th>
                        <th scope="col" style={thStyle.centered}>訂購單價</th>
                        <th scope="col" style={thStyle.centered}>應收金額</th>
                        <th scope="col" style={thStyle.centered}>訂單號碼</th>
                    </tr>
                </thead>
                <tbody>
                    {data ? (
                        data.map((item, index) => (
                            <tr key={uuidv4()}>
                                <th scope="row" style={thStyle.centered}>{index + 1}</th>
                                <td style={thStyle.centered}>{item.client}</td>  
                                <td style={thStyle.centered}>{item.toShipDate}</td> 
                                <td style={thStyle.centered}>{item.toShipNum}</td> 
                                <td style={thStyle.centered}>{item.piNum}</td> 
                                <td style={thStyle.centered}>{item.drawNum}</td> 
                                <td style={thStyle.centered}>{item.itemNum}</td> 
                                <td style={thStyle.centered}>{item.itemName}</td> 
                                <td style={thStyle.centered}>{item.realGive}</td> 
                                <td style={thStyle.centered}>{item.unit}</td> 
                                <td style={thStyle.centered}>{item.orderPrice}</td> 
                                <td style={thStyle.centered}>{item.amountReceivable}</td> 
                                <td style={thStyle.centered}>{item.orderNum}</td>          
                            </tr>
                        ))
                    ) : (
                        <span>No data</span>
                    )}
                </tbody>
            </table>
            <div style={{textAlign: 'center'}} className='mt-3'>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                    <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
            </div>
        </>
    )
}


function SelectFilter({setNewData}) {
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [client, setClient] = useState("");
    const [piNum, setPiNum] = useState("");
    const [drawNum, setDrawNum] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Replace with your actual endpoint
        const RealapiUrl = `${apiUrl}/ToShip/GetMainsBetween`;
        const params = {
            start,
            client,
            end,
            piNum,
            drawNum
        };
        const response = await axios.get(RealapiUrl, { params });
        setNewData(response.data.toShips)
        console.log(response.data.toShips)
    };
    return(
        <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row mb-3'>
                <div className='col-4'>
                    <label htmlFor="inputText1">起始日
                    <input type="text" placeholder='yyyy/mm/dd' className="form-control" id="inputText1" value={start} onChange={(e) => setStart(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <label htmlFor="inputText1">截止日
                    <input type="text" placeholder='yyyy/mm/dd' className="form-control" id="inputText2" value={end} onChange={(e) => setEnd(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <label htmlFor="inputText2">客戶
                    <input type="text" className="form-control" id="inputText2" value={client} onChange={(e) => setClient(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-4'>
                    <label htmlFor="inputText1">批號
                    <input type="text"  className="form-control" id="inputText3" value={piNum} onChange={(e) => setPiNum(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <label htmlFor="inputText1">圖號
                    <input type="text"  className="form-control" id="inputText4" value={drawNum} onChange={(e) => setDrawNum(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='col-12'>
                    <button type="submit" className="btn btn-dark">提交</button>
                </div>
            </div>
        </div>      
  </form>
    )
}




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate,useParams  } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import ReplyIcon from '@mui/icons-material/Reply';
import InputLabel from '@mui/material/InputLabel';
import SendIcon from '@mui/icons-material/Send';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchIcon from '@mui/icons-material/Search';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import NativeSelect from '@mui/material/NativeSelect';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateField } from '@mui/x-date-pickers/DateField';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import { DataGrid } from '@mui/x-data-grid';
import { apiUrl } from '../../apiUrl/ApiUrl';


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const options = [
    { value: '空白', label: '空白' },
    { value: '盤點', label: '盤點' },
    { value: '調帳', label: '調帳' },
    { value: '生產領用', label: '生產領用'},
    { value: '採購', label: '採購' },
    { value: '入庫', label: '入庫' },
    { value: '出貨扣帳', label: '出貨扣帳'},
];

export default function NewMatCardStatistics() {

    const columns = [
     
      {   field: 'id', headerName: 'ID', width: 90 },
      {
          field: 'drawNum',
          headerName: '圖號',
          width: 100,
          editable: true,
      },
      {
          field: 'total',
          headerName: '總計',
          width: 100,
          editable: true,
      },
      {
          field: 'january',
          headerName: '一月',
          width: 100,
          editable: false,
      },
      {
        field: 'february',
        headerName: '二月',
        width: 100,
        editable: false,
      },
      {
        field: 'march',
        headerName: '三月',
        width: 100,
        editable: false,
      },
      {
        field: 'april',
        headerName: '四月',
        width: 100,
        editable: false,
      },
      {
        field: 'may',
        headerName: '五月',
        width: 100,
        editable: false,
      },
      {
        field: 'june',
        headerName: '六月',
        width: 100,
        editable: false,
      },
      {
        field: 'july',
        headerName: '七月',
        width: 100,
        editable: false,
      },
      {
        field: 'august',
        headerName: '八月',
        width: 100,
        editable: false,
      },
      {
        field: 'september',
        headerName: '九月',
        width: 100,
        editable: false,
      },
      {
        field: 'october',
        headerName: '十月',
        width: 100,
        editable: false,
      },
      {
        field: 'november',
        headerName: '十一月',
        width: 100,
        editable: false,
      },
      {
        field: 'december',
        headerName: '十二月',
        width: 100,
        editable: false,
      },
    ];
    const currentYear = new Date().getFullYear();
    const [page, setPage] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [Kind,setKind] = React.useState('空白');
    const [Year,setYear] = React.useState(currentYear);

    const yearOptions = [];

    // eslint-disable-next-line no-plusplus
    for (let i = currentYear - 10; i <= currentYear + 10; i++) {
      yearOptions.push({ value: i.toString(), label: i.toString() });
    }

  
    const handleSubmit = async () => {
      const response = await axios.get(`${apiUrl}/MatCard/GetStatisticsMains?kind=${Kind}&month=${Year}`);
      setRows(response.data.matStatistics);
      setPage(0);  
    };
    const fetchData = async () => {
        try {    
          const response = await axios.get(`${apiUrl}/MatCard/GetStatisticsMains`);
          if (response.data.statusCode === 200) {
            setRows(response.data.matStatistics);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
      fetchData();
    }, []); 

  return (
    <>
      <Box
        sx={{
          margin:'auto',
          width: '90%',
          height: 700,
        }}
      >
        <Grid container spacing={2} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                    生管資材卡統計表
                </Typography>
            </Grid>
            <Grid container spacing={10}>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        年份
                        </InputLabel>
                        <NativeSelect
                        onChange={(e)=>setYear(e.target.value)}
                        defaultValue={currentYear}
                        inputProps={{
                            name: 'year',
                            id: 'uncontrolled-native',
                        }}
                        >
                        {yearOptions.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        類別
                        </InputLabel>
                        <NativeSelect
                        onChange={(event) => setKind(event.target.value)}
                        defaultValue={Kind}
                        inputProps={{
                            name: 'kind',
                            id: 'uncontrolled-native',
                        }}
                        >
                        {options.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Stack spacing={20} direction="row">
                        <Button variant="outlined" onClick={handleSubmit}>搜尋</Button>
                        <Button variant="outlined">列印</Button>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 30,
                },
            },
            }}
            pageSizeOptions={[30,20,10]}
            disableRowSelectionOnClick
        />
      </Box>
    </>
  );
}

import { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { GlobalContext } from '../../../components/globalContext/GlobalContext';
import Dialog from '../../../components/dialog/Dialog';
import { apiUrl } from '../../../components/apiUrl/ApiUrl';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [globalVar, setGlobalVar] = useContext(GlobalContext);

  console.log(`${apiUrl}/Login/CheckAuth`)
  const handleClick = async () => {
    try {
      const response = await axios.post(`${apiUrl}/Login/CheckAuth`, { username, password });
  
      if (response.status === 200) {
        
  
        const base64Url = response.data.tokenRespense.split('.')[1];

        // 将 Base64Url 编码转换为标准 Base64 编码
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        
        // 使用 decodeURIComponent 和 escape 解码非 ASCII 字符
        const jsonData = JSON.parse(decodeURIComponent(escape(window.atob(base64))));
        
        console.log(response.data);
        console.log(jsonData);
// 使用jsonData進行後續操作
        setGlobalVar(jsonData.name)
        // Store jsonData in sessionStorage.
        sessionStorage.setItem('jwtData', JSON.stringify(jsonData));
        sessionStorage.setItem('userName', jsonData.name);
        sessionStorage.setItem('Id', response.data.id);
        sessionStorage.setItem('Can', response.data.canCRUDuser);
        setOpen(true)
        setIsLogin(true);
      } else {
        // Handle error
        sessionStorage.clear(); // Clear sessionStorage
        setOpen(true)
        setIsLogin(false);
        setGlobalVar('帳號密碼錯誤')
      }
    } catch (error) {
      sessionStorage.clear(); // Clear sessionStorage
      setOpen(true)
      setIsLogin(false);
      setGlobalVar('帳號密碼錯誤')
    }
  };

  const nav = () => {
    navigate('/dashboard', { replace: true });  // Navigate after closing the dialog
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField 
          name="account" 
          label="User Account" 
          value={username}
          onChange={e => setUsername(e.target.value)}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton> 
      <Dialog open={open} setOpen={setOpen} isLogin={isLogin} nav={nav} user={globalVar}/>
    </>
  );
}

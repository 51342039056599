import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../../apiUrl/ApiUrl';

export default function WorkPlace({data}) {
    const [newData,setNewData] = useState(null);
    const [model,setModel] = useState('搜尋模式');
    const workMargin = {
        marginTop: '5%',
        marginBottom:'5%'
    } 
    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
        axios.get(`${apiUrl}/WorkPlace/list`)
        .then(response => {
            if(response.data.statusCode === 200) {

                setNewData(response.data);

            }else {
                alert('失敗，請檢查網路問題')
            }
        })
        .catch(error => {
            // handle error
            alert(error);
        });
    }
    return(
        <div className='container-fluid'>
            <div className='row' style={workMargin}>
                <div className='col-1'>
                    <ChooseModel setModel={setModelandGetDate}/>
                </div>
                <div className='col-2'/>
                <div className='col-7'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                    {model !== '讀取模式'? <OperateForm setNewData={setNewData} model={model}/> : null}
                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null?<ListTable setNewData={setNewData} data={newData || data}/> :null}                 
                </div>              
            </div>
        </div>
        
    )
}

function ListTable({data,setNewData}) {
// ${apiUrl}/WorkPlace/delete/WorkPlacemain?WorkPlaceName=%E5%89%AA%E5%BA%8A2
    const handleDelete = workplaceName => {
        axios.get(`${apiUrl}/WorkPlace/DeleteMain?WorkPlaceName=${workplaceName}`)
        .then(response => {
            // handle success
            if(response.data === 200) {
                axios.get(`${apiUrl}/WorkPlace/list`)
                    .then(response => {
                        // Update the data
                        setNewData(response.data);
                        alert('刪除成功')
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                        alert('刪除失敗，找不到可以刪除的職場名稱')
                    });
            }else {
                alert('刪除失敗，找不到可以刪除的職場名稱')
            }
        })
        .catch(error => {
            // handle error
            alert(error);
        });
    }  /* #212B36 */ 
    return(
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">職場名稱</th>
                    <th scope="col">操作</th>
                </tr>
            </thead>
            <tbody>
                {data.workPlacesList.map((workplace, index) => (
                    <tr key={uuidv4()}>
                        <th scope="row">{index + 1}</th>
                        <td>{workplace.workPlaceName}</td>
                        <td>
                            <button type="button" className="btn btn-danger" onClick={() => handleDelete(workplace.workPlaceName)}>刪除</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

function OperateForm({setNewData,model}) {
    const [errorMessage, setErrorMessage] = useState('');
    const handleSubmit = event => {
        if(model === '新增模式')
        {
            event.preventDefault();
        
            let workplaceName = event.target.elements.workplaceName.value;
            workplaceName = workplaceName.trim(); 
    
            axios.post(`${apiUrl}/WorkPlace/CreateWorkPlaceMain`, { workplaceName })
            .then(response => {
                // handle success
                console.log(response)
                if(response.data === 200) {
                    axios.get(`${apiUrl}/WorkPlace/list`)
                        .then(response => {
                            // Update the data
                            setNewData(response.data);
                        })
                        .catch(error => {
                            // handle error
                            console.log(error);
                        });
                }else {
                    alert('新增失敗，已有相同的職場名稱')
                }
            })
            .catch(error => {
                // handle error
                alert('新增失敗，已有相同的職場名稱')
            });
        } else if (model === '搜尋模式') 
        {
            event.preventDefault();
        
            let workplaceName = event.target.elements.workplaceName.value;
            workplaceName = workplaceName.trim(); 
            axios.get(`${apiUrl}/WorkPlace/Get?WorkPlaceName=${workplaceName}`)
            .then(response => {
                console.log(response)// handle success
                if(response.data.statusCode === 200) {
                    response.data.workPlacesList = [];
                    response.data.workPlacesList.push(response.data.workPlace)
                    setNewData(response.data);
                }else {
                    alert('搜尋失敗，找不到可以搜尋的職場名稱')
                }
            })
            .catch(error => {
                // handle error
                alert(error);
            });

        }
    };
    
    const checkValue = event => {
        if (!event.target.value.trim()) {
            setErrorMessage('名稱欄位不得為空白')
        }else{
            setErrorMessage('')
        }
    };

    return(
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="workplaceName" className="form-label">職場名稱
                <input type="text" className="form-control" id="workplaceName" onBlur={checkValue}/>
                <div id="emailHelp" className="form-text">{errorMessage}</div>
                </label>
            </div>
            <button type="submit" className="btn btn-dark">提交</button>
        </form>
    )
}

function ChooseModel({setModel}) {
    const buttonStyle = {
        display: 'block',
        width: '100%',
        border: 'none',
        backgroundColor: '#6c757d',
        color: 'white', // set the text color
        textAlign: 'center',
        padding: '10px 0'
    };

    return(
        <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
                <button type="button" style={buttonStyle} onClick={() => setModel('新增模式')}>新增模式</button>
            </div>
            <div className="accordion-item">
                <button type="button" style={buttonStyle} onClick={() => setModel('搜尋模式')}>搜尋模式</button>
            </div>
        </div>
    )
}
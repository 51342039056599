import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import ReactPaginate from "react-paginate";
import '../../../css/tableStyle.css'
import { apiUrl } from '../../apiUrl/ApiUrl';

export default function Order({data}) {
    const [newData,setNewData] = useState(null);
    const [selected, setSelected] = useState('');
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('訂單總覽');
    const [page,setPage] = useState(1);
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
    }

    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                    <ChooseModel setModel={setModel} setNewData={setNewData} setSelected={setSelected}/>
                </div>
                <div className='col-5'>
                    {/* {model === '主表新增' ? <InsertMainForm setNewData={setNewData}/> : null}  */}
                    {/* {model === '副表新增' ? <InsertDetailForm setDetailData={setDetailData}/> : null} 
                    {model === '主表搜尋' ? <SelectMainForm setNewData={setNewData} 
                    setDetailData={setDetailData} 
                    page={page} 
                    setPage={setPage}/> : null}  
                    {model === '副表修改' ? <EditDetailForm data={editItem} setDetailData={setDetailData}/> : null} */}
                    {/* {model === '修改模式'?<EditForm model={model} editItem={editItem} setNewData={setNewData}/> :<OperateForm setNewData={setNewData} model={model}/>} */}
                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {model === '訂單總覽'?<h2><span className="badge bg-dark">搜尋條件</span></h2>:null}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {model === '訂單總覽'?<SelectFilter setNewData={setNewData} apiUrl={apiUrl}/> : null}
                    {/* {model === '訂單輸入'?<CreateForm/> : null} */}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">{model}</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null && model === '訂單總覽'?<ListTable setDetailData={setDetailData} 
                    setNewData={setNewData} data={newData || data} 
                    setModel={setModelandGetDate} page={page} 
                    setPage={setPage}/> :<div>目前還沒取得資料或取不到資料</div>}
                </div>              
            </div>
        </div>     
    )
}



function ListTable({data,setNewData,setModel,setDetailData,page,setPage}) {
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }


    const nextPage = () => {

        axios.get(`${apiUrl}/OrderManage/NoFilterGetMains?page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
    }

    const prePage = () => {

        axios.get(`${apiUrl}/OrderManage/NoFilterGetMains?page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
    }



    return(
        <>
            <div style={{overflowX: "auto", height: "70vh"}}>
                <table className="table custom-table table-striped" style={{width: "3000px"}}>
                    <thead className="sticky-header">
                    <tr>
                        <th scope="col" style={thStyle.centered}>#</th>
                        <th scope="col" style={thStyle.centered}>承製公司</th>
                        <th scope="col" style={thStyle.centered}>客戶</th>
                        <th scope="col" style={thStyle.centered}>批號</th>
                        <th scope="col" style={thStyle.centered}>圖號</th>
                        <th scope="col" style={thStyle.centered}>項次</th>
                        <th scope="col" style={thStyle.centered}>物品名</th>
                        <th scope="col" style={thStyle.centered}>訂單號碼</th>
                        <th scope="col" style={thStyle.centered}>流水號</th>
                        <th scope="col" style={thStyle.centered}>訂單數量</th>
                        <th scope="col" style={thStyle.centered}>單位</th>
                        <th scope="col" style={thStyle.centered}>發動數量</th>
                        <th scope="col" style={thStyle.centered}>客戶納期</th>
                        <th scope="col" style={thStyle.centered}>累積交貨</th>
                        <th scope="col" style={thStyle.centered}>尚未交貨</th>
                        <th scope="col" style={thStyle.centered}>實交貨日</th>
                        <th scope="col" style={thStyle.centered}>訂購</th>
                        <th scope="col" style={thStyle.centered}>說明</th>
                        <th scope="col" style={thStyle.centered}>註記代號</th>
                        <th scope="col" style={thStyle.centered}>單價</th>
                        <th scope="col" style={thStyle.centered}>接收日期</th>
                    </tr>
                </thead>
                <tbody>
                    {data.orderManages ? (
                        data.orderManages.map((item, index) => (
                        <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>{index + 1}</th>
                            <td style={thStyle.centered}>{item.company}</td>
                            <td style={thStyle.centered}>{item.client}</td>
                            <td style={thStyle.centered}>{item.piNum}</td>
                            <td style={thStyle.centered}>{item.drawNum}</td>
                            <td style={thStyle.centered}>{item.itemNum}</td>
                            <td style={thStyle.centered}>{item.itemName}</td>
                            <td style={thStyle.centered}>{item.orderNum}</td>
                            <td style={thStyle.centered}>{item.orderSerial}</td> 
                            <td style={thStyle.centered}>{item.orderAmount}</td>
                            <td style={thStyle.centered}>{item.unit}</td>
                            <td style={thStyle.centered}>{item.launchAmount}</td>
                            <td style={thStyle.centered}>{item.clientDate}</td> 
                            <td style={thStyle.centered}>{item.accumulation}</td>
                            <td style={thStyle.centered}>{item.notYet}</td>
                            <td style={thStyle.centered}>{item.tradeLocation}</td>
                            <td style={thStyle.centered}>{item.tureTradeDate}</td> 
                            <td style={thStyle.centered}>{item.introduce}</td>
                            <td style={thStyle.centered}>{item.markNum}</td>
                            <td style={thStyle.centered}>{item.price}</td>
                            <td style={thStyle.centered}>{item.receiveDate}</td>         
                        </tr>
                    ))
                    ) : (
                        <tr>
                        <td colSpan="21" style={{textAlign: 'center'}}>Loading...</td>
                        </tr>
                    )}
                </tbody>
            </table>
            </div>
            <div style={{textAlign: 'center'}} className='mt-3'>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                    <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
            </div>
        </>
    )
}

function SelectFilter({setNewData,apiUrl}) {

    const [isTaiRih, setIsTaiRih] = useState('台日');
    const [isGiven, setIsGiven] = useState('未交');
    const [filterField, setFilterField] = useState('批號');
    const [sortWay, setSortWay] = useState('批號');
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Replace with your actual endpoint
        const RealapiUrl = `${apiUrl}/OrderManage/GetMains`;
        const params = {
            isTaiRih,
            isGiven,
            filterField,
            sortWay,
            start,
            end,
        };
        console.log(apiUrl);    
        const response = await axios.get(RealapiUrl, { params });
        setNewData(response.data)
    };
    return(
        <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-3'>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="isTaiRih">
                            <input className="form-check-input" type="radio" name="isTaiRih" id="isTaiRih1" checked={isTaiRih === '台日'} onChange={() => setIsTaiRih('台日')}/>                       
                                台日
                        </label>
                        </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="isTaiRih">
                            <input className="form-check-input" type="radio" name="isTaiRih" id="isTaiRih2" checked={isTaiRih === '其它'} onChange={() => setIsTaiRih('其它')}/>                       
                            其它
                        </label>
                    </div>                 
                </div>
                <div className='col-3'>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="isGiven">
                            <input className="form-check-input" type="radio" name="isGiven" id="isGiven1" checked={isGiven === '全部'} onChange={() => setIsGiven('全部')}/>                       
                               全部
                        </label>
                        </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="isGiven">
                            <input className="form-check-input" type="radio" name="isGiven" id="isGiven2" checked={isGiven === '未交'} onChange={() => setIsGiven('未交')}/>                       
                                未交
                        </label>
                    </div>
                </div>
                <div className='col-3'>
                    過濾
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="filterField">
                            <input className="form-check-input" type="radio" name="filterField" id="filterField1" checked={filterField === '批號'} onChange={() => setFilterField('批號')}/>                       
                                批號
                        </label>
                        </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="filterField">
                            <input className="form-check-input" type="radio" name="filterField" id="filterField2" checked={filterField === '客戶納期'} onChange={() => setFilterField('客戶納期')}/>                       
                            客戶納期
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="filterField">
                            <input className="form-check-input" type="radio" name="filterField" id="filterField3" checked={filterField === '機種'} onChange={() => setFilterField('機種')}/>                       
                            機種
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="filterField">
                            <input className="form-check-input" type="radio" name="filterField" id="filterField4" checked={filterField === '圖號'} onChange={() => setFilterField('圖號')}/>                       
                            圖號
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="filterField">
                            <input className="form-check-input" type="radio" name="filterField" id="filterField5" checked={filterField === '訂單號碼'} onChange={() => setFilterField('訂單號碼')}/>                       
                            訂單號碼
                        </label>
                    </div>
                </div>
                <div className='col-3'>
                    排序
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="sortWay">
                            <input className="form-check-input" type="radio" name="sortWay" id="sortWay1" checked={sortWay === '批號'} onChange={() => setSortWay('批號')}/>                       
                                批號
                        </label>
                        </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="sortWay">
                            <input className="form-check-input" type="radio" name="sortWay" id="sortWay2" checked={sortWay === '客戶納期'} onChange={() => setSortWay('客戶納期')}/>                       
                            客戶納期
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="sortWay">
                            <input className="form-check-input" type="radio" name="sortWay" id="sortWay3" checked={sortWay === '納期+圖號+批號'} onChange={() => setSortWay('納期+圖號+批號')}/>                       
                            納期+圖號+批號
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="sortWay">
                            <input className="form-check-input" type="radio" name="sortWay" id="sortWay4" checked={sortWay === '圖號'} onChange={() => setSortWay('圖號')}/>                       
                            圖號
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="sortWay">
                            <input className="form-check-input" type="radio" name="sortWay" id="sortWay5" checked={sortWay === '訂單號碼'} onChange={() => setSortWay('訂單號碼')}/>                       
                            訂單號碼
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            <input className="form-check-input" type="radio" name="sortWay" id="sortWay6" checked={sortWay === '機種'} onChange={() => setSortWay('機種')}/>                       
                            機種
                        </label>
                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-6'>
                    <label htmlFor="inputText1">從
                    <input type="text" className="form-control" id="inputText1" value={start} onChange={(e) => setStart(e.target.value)}/>
                    </label>
                </div>
                <div className='col-6'>
                    <label htmlFor="inputText2">到
                    <input type="text" className="form-control" id="inputText2" value={end} onChange={(e) => setEnd(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='col-12'>
                    <button type="submit" className="btn btn-dark" onClick={handleSubmit}>提交</button>
                </div>
            </div>
        </div>      
  </form>
    )
}

function ChooseModel({ setModel,selected,setSelected}) { 
    const handleChange = (event) => {
        setSelected(event.target.id);
    };
    const selectModel = (event) => {
        if (event.target.id === "btnradio2") {
            setModel('訂單總覽')
        }else if (event.target.id === "btnradio3") {
            setModel('訂單輸入')
        }
        // else if (event.target.id === "btnradio4") {
        //     setModel('訂單接收')
        // }
        // else if (event.target.id === "btnradio5") {
        //     setModel('讀取模式')
        // }
    }
        
    return (
        <div className="btn-group-vertical">
            <label className={selected === 'btnradio2' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio2">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    checked={selected === 'btnradio2'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>訂單總覽</strong>
            </label>
            {/* <label className={selected === 'btnradio3' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio3">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio3"
                    checked={selected === 'btnradio3'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>訂單輸入</strong>
            </label> */}
            {/* <label className={selected === 'btnradio4' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio4">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio4"
                    checked={selected === 'btnradio4'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>訂單接收</strong>
            </label> */}
            {/* <label className={selected === 'btnradio5' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio5">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio5"
                    checked={selected === 'btnradio5'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>讀取模式</strong>
            </label> */}
        </div>
    );
}

function CreateForm() {

    const [selectValue, setSelectValue] = useState("");
    const [selectCom, setSelectCom] = useState("福蒙");
    const [newPinum, setNewPinum] = useState("");
    const [options, setOptions] = useState([]);
    const [formData, setFormData] = useState({
        client: '',
        receiveDate: '',
        orderNum: ''
        // 更多的字段...
    });


    const handleSelectChange = (event) => {
        setSelectValue(event.target.value);
    }
    const today = new Date(); 
    const randomCode = Math.floor(1000 + Math.random() * 9000);
    const variable = `-${randomCode}`;
    const formattedToday = today.getFullYear().toString().slice(-2) + (today.getMonth() + 1).toString().padStart(2, '0') + today.getDate().toString().padStart(2, '0') + variable;
    const hahatoday = today.getFullYear() + (today.getMonth() + 1).toString().padStart(2, '0') + today.getDate().toString().padStart(2, '0');

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/OrderManage/GetOptions`);
                setOptions(response.data);
            } catch (error) {
                console.error('Failed to fetch data', error);
            }
        };

        fetchData();
    }, []);  

    const setNewPinumLot = () => {
        setNewPinum(formattedToday);
    }

    const handleInsertSubmit = async (event) => {
        event.preventDefault();
        if(selectValue === '')
        {
            alert('請選擇客戶')
        }
        else
        {
            formData.client= selectValue;
            formData.receiveDate = hahatoday;
            formData.orderNum = newPinum;
            formData.piNum = newPinum;
            formData.company = selectCom;
            try {
                // 使用你的API URL
                const response = await axios.post(`${apiUrl}/OrderManage/CreateMain`, formData);
                if (response.status === 200) {
                    // 处理响应
                    console.log('Data successfully submitted');
                }
            } catch (error) {
                console.error('There was an error!', error);
            }
        }

    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };
    return(
        <form onSubmit={handleInsertSubmit}>
                        <div className='container-fluid'>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>客戶</span>
                                </div>
                                <div className='col-2 ps-0'>
                                <select className="form-select" id="client" value={selectValue} onChange={handleSelectChange}>
                                {options.companyOptions != null?options.companyOptions.map((item, index) => (
                                    <option key={index} value={item.comSimpleName}>
                                        {`${item.comNum}  ${item.comSimpleName}  ${item.comFullName}`}
                                    </option>
                                )):null}                                
                                </select>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>接收日期</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="receiveDate" className="form-control" aria-describedby="passwordHelpInline"
                                    value={hahatoday}
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>訂單號碼</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="orderNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value={newPinum}
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>批號</span>
                                    <button type="button" className="btn btn-light" onClick={setNewPinumLot}>New</button>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="piNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value={newPinum}
                                    onChange={handleInputChange}/>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>機種Model</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="model" className="form-control" aria-describedby="passwordHelpInline"
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>數量Qty</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="qty" className="form-control" aria-describedby="passwordHelpInline"
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>圖號</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="drawNum" className="form-control" aria-describedby="passwordHelpInline"
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>項次</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="itemNum" className="form-control" aria-describedby="passwordHelpInline"
                                    onChange={handleInputChange}/>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>物品名</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="itemName" className="form-control" aria-describedby="passwordHelpInline"
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>單位</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="unit" className="form-control" aria-describedby="passwordHelpInline"
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>訂單數量</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="orderAmount" className="form-control" aria-describedby="passwordHelpInline"
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>訂購單價</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="price" className="form-control" aria-describedby="passwordHelpInline"
                                    onChange={handleInputChange}/>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>客戶納期</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="clientDate" className="form-control" aria-describedby="passwordHelpInline"
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>承製公司</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <select className="form-select" id="company" value={selectCom} onChange={setSelectCom}>                               
                                        <option value='福蒙'>
                                        福蒙
                                        </option>
                                        <option value='華辰'>
                                        華辰
                                        </option>                                                         
                                    </select>   
                                </div>
                                 
                            </div>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <button type="submit" className="btn btn-dark">提交</button>
                                </div>
                            </div>
                        </div>
                    </form>
    )
}


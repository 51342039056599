import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import React,{ useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../../apiUrl/ApiUrl';





export default function BomActions ({params,setRows}) {
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };


    const onDestory = async () => {
        console.log(setRows);
        try {
            const response = await axios.post(`${apiUrl}/Bom/DeleteBom`, params.row);
            handleClose();
            if(response.data.statusCode === 200) {
            console.log(response.data)
            setRows(response.data.boms);
            alert('刪除成功');       
            }else {
                alert('刪除失敗');
            }
        
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            alert('刪除失敗');
        }
    };

    return <>
        <Box sx={{m:1,position:'relative'}}>
            <IconButton aria-label="delete" onClick={handleClickOpen}>
                <DeleteIcon/>
            </IconButton>
        </Box>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"防呆機制"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            確定要刪除嗎? 刪除後資料不可復原
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>不同意</Button>
          <Button onClick={onDestory} autoFocus>
            同意
          </Button>
        </DialogActions>
      </Dialog>
    </>
}
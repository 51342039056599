import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Input  from '@mui/material/Input';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import { apiUrl } from '../../apiUrl/ApiUrl';


const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);



export default function OrderExcel() {
      
    // const config = {
    //     headers: {
    //       'X-Ap-Token': appsetting.token,
    //       'X-Ap-CompanyId': sessionStorage.getItem('CompanyId'),
    //       'X-Ap-UserId': sessionStorage.getItem('UserId'),
    //       'X-Ap-AdminToken':sessionStorage.getItem('AdminToken'),
    //       'X-Ap-SuperToken':sessionStorage.getItem('SuperToken'),
    //     }
    // };
    const currencySymbols = {
        'TWD': 'NT$',  // 台币
        'USD': '$',    // 美元
        'CNY': '¥',    // 人民币
        // ... 如果有其他货币，您也可以继续添加
    };
    function getCurrencySymbol(code) {
        return currencySymbols[code] || code;  // 如果映射中没有找到，就返回代码本身
    }

    const columns = [
     
        {   field: 'id', headerName: 'ID', width: 90 },
        {
            field: '供應商',
            headerName: '供應商',
            width: 150,
            editable: false,
        },
        {
            field: '說明',
            headerName: '說明',
            width: 100,
            editable: false,
        },
        {
            field: '訂單編號',
            headerName: '訂單編號',
            width: 120,
            editable: false,
        },
        {
            field: '料件',
            headerName: '料件',
            width: 120,
            editable: false,
        },
        {
            field: '物品名',
            headerName: '物品名',
            width: 120,
            editable: false,
        },
        {
            field: '單位',
            headerName: '單位',
            width: 120,
            editable: false,
        },
        {
            field: '單價',
            headerName: '單價',
            width: 120,
            editable: false,
        },
        {
            field: '幣別',
            headerName: '幣別',
            width: 120,
            editable: false,
            renderCell: (params) => {
                const currencyCode = params.value;
                console.log(params)
                const symbol = getCurrencySymbol(currencyCode);
                return (
                    <div>
                        {symbol} ({currencyCode}) 
                    </div>
                );
            },
        },
        {
            field: '客戶納期',
            headerName: '客戶納期',
            width: 120,
            editable: false,
        },
        {
            field: '訂單日期',
            headerName: '訂單日期',
            width: 120,
            editable: false,
        },
        {
            field: '保存日',
            headerName: '保存日',
            width: 120,
            editable: false,
        },
        {
            field: '採購員',
            headerName: '採購員',
            width: 120,
            editable: false,
        },
        {
            field: '英文部品名',
            headerName: '英文部品名',
            width: 120,
            editable: false,
        },
        {
            field: '替代料件',
            headerName: '替代料件',
            width: 120,
            editable: false,
        },
        {
            field: '料件群組',
            headerName: '料件群組',
            width: 120,
            editable: false,
        },
        {
            field: '工單',
            headerName: '工單',
            width: 120,
            editable: false,
        },
        {
            field: '使用工單',
            headerName: '使用工單',
            width: 120,
            editable: false,
        },
        {
            field: '工單行',
            headerName: '工單行',
            width: 120,
            editable: false,
        },
        {
            field: '操作',
            headerName: '操作',
            width: 120,
            editable: false,
        },
        {
            field: '專案',
            headerName: '專案',
            width: 120,
            editable: false,
        },
        {
            field: '材質',
            headerName: '材質',
            width: 120,
            editable: false,
        },
        {
            field: '尺寸',
            headerName: '尺寸',
            width: 120,
            editable: false,
        },
        {
            field: '倉庫',
            headerName: '倉庫',
            width: 120,
            editable: false,
        },
        {
            field: '存放位置',
            headerName: '存放位置',
            width: 120,
            editable: false,
        },
        {
            field: '收貨中心',
            headerName: '收貨中心',
            width: 120,
            editable: false,
        },
        {
            field: '現場位置',
            headerName: '現場位置',
            width: 120,
            editable: false,
        },
        {
            field: '檢驗',
            headerName: '檢驗',
            width: 120,
            editable: false,
        },
        {
            field: '作成日',
            headerName: '作成日',
            width: 120,
            editable: false,
        },
        {
            field: '生產機種',
            headerName: '生產機種',
            width: 120,
            editable: false,
        },
        {
            field: '預計組立日期',
            headerName: '預計組立日期',
            width: 120,
            editable: false,
        },
    ];
    const [rows,setRows] = useState([]);
    const [filterRows,setFilterRows] = useState([]);
    const [file, setFile] = useState(null);
    const [com, setCom] = useState("");
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        
        if (!file) {
          console.error('No file selected.');
          return;
        }
    
        // 創建FormData對象以準備發送文件
        const formData = new FormData();
        formData.append('excel', file);
        formData.append('com', com);
        
        try {
          const response = await axios.post(`${apiUrl}/OrderManage/OrderExcel`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
    
          // 處理上傳後的響應
          if (response.status === 200) {
            setFilterRows(response.data);
            const response2 = await axios.post(`${apiUrl}/OrderManage/ImportExcel`, response.data, {
                responseType: 'blob'  // 确保处理文件流
            });
            
            // 创建一个下载链接元素
            const url = window.URL.createObjectURL(new Blob([response2.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'filename.xlsx'); // 设定下载文件名
            document.body.appendChild(link);
            link.click();
            
            // 清理DOM和URL
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            handleClose();
          }
        } catch (error) {
            alert('失敗')
          console.error('Error uploading file:', error);
        }
    };

    // const onDataSubmit = async (e) => {
    //     e.preventDefault();
        
    //     if (!file) {
    //       console.error('No file selected.');
    //       return;
    //     }
    
    //     // 創建FormData對象以準備發送文件
    //     const formData = new FormData();
    //     formData.append('excel', file);
    //     formData.append('com', com);
        
    //     try {
    //       const response = await axios.post(`${apiUrl}/OrderManage/OrderExcel`, formData, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data'
    //         }
    //       });
    
    //       // 處理上傳後的響應
    //       if (response.status === 200) {
    //         setFilterRows(response.data);
    //         handleClose();
    //       }
    //     } catch (error) {
    //         alert('失敗')
    //       console.error('Error uploading file:', error);
    //     }
    // };


    const onFileChange = (e) => {
        setFile(e.target.files[0]);
      };
    
      const onComChange = (e) => {
        setCom(e.target.value);
    };


    // useEffect(() => {
    //     fetchData();
    // }, []); 
 
  return (
    <>
    <Box
      sx={{
        margin:'2%',
        width: '95%',
        height: 700,
      }}
    >

        <Grid container spacing={0} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                    台日訂單接收
                </Typography>
            </Grid>
            {/* <Grid item xs={6}>      
                <StaffSearch rows={rows} setFilterRows={setFilterRows}/>
            </Grid>
            <Grid item xs={6}/> */}
            <Grid item xs={2}>      
                <Button variant="outlined" endIcon={<PersonAddIcon/>} onClick={handleClickOpen}>訂單匯入</Button>
            </Grid>     
        </Grid>
        
        <DataGrid
            rows={filterRows}
            columns={columns}
            // onCellDoubleClick={handleGetDetail}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 30,
                },
            },
            }}
            pageSizeOptions={[30,20,10]}
            disableRowSelectionOnClick
        />
    </Box>


    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>訂單輸入</DialogTitle>
        <DialogContent>
          <DialogContentText>
            請選擇正確excel
          </DialogContentText>
          <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
            }}
            onSubmit={onFormSubmit}
            >
            <Typography variant="h6" component="div">
            上傳EXCEL檔案
            </Typography>
            <label htmlFor="contained-button-file">
                <input
                accept=".xls,.xlsx"
                id="contained-button-file"
                multiple
                type="file"
                onChange={onFileChange}
                style={{ display: 'none' }}
                />
                <Button variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
                上傳檔案
                </Button>
            </label>
            <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="company-select-label">選擇廠商</InputLabel>
                <Select
                    labelId="company-select-label"
                    id="com"
                    value={com}
                    onChange={onComChange}
                    label="Company Name"
                    required
                >
                    <MenuItem value={'福蒙'}>福蒙</MenuItem>
                    <MenuItem value={'華辰'}>華辰</MenuItem>
                </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary">
                送出
            </Button>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import { apiUrl } from '../../apiUrl/ApiUrl';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export default function ComapnyBasicSet() {
    const columns = [
        {
            field: 'action',
            headerName: '操作',
            width: 80,
            renderCell: (params) => (
                // eslint-disable-next-line react/button-has-type
                <Button onClick={() => destorySubmit(params.row.manufacturer_Seq)}>刪除</Button>
              )
        },       
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'manufacturer_Seq',
          headerName: '廠商編號',
          width: 150,
          editable: true,
        },
        {
            field: 'manufacturer_AbbreviationName',
            headerName: '廠商簡寫',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_FullName',
            headerName: '廠商全名',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_CompanyPhone',
            headerName: '公司電話',
            width: 150,
            editable: true,
        },
        {
            field: 'Manufacturer_CompanyFax',
            headerName: '公司傳真',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_UniformNum',
            headerName: '統一編號',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_CityCode',
            headerName: '郵遞區號',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_FaxNum',
            headerName: '傳真號碼',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_BossName',
            headerName: '負責人名稱',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_ContactName',
            headerName: '聯絡人名稱',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_CompanyAddress',
            headerName: '公司地址',
            width: 150,
            editable: true,
        },
        {
          field: 'manufacturer_ShipAddress',
          headerName: '送貨地址',
          width: 150,
          editable: true,
        },
        {
            field: 'manufacturer_ShipAddress2',
            headerName: '送貨地址2',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_ShipAddress3',
            headerName: '送貨地址3',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_FactoryNum',
            headerName: '工廠電話',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_FactoryFax',
            headerName: '工廠傳真',
            width: 150,
            editable: true,
        },    
        {
            field: 'manufacturer_AccCode',
            headerName: 'AccCode',
            width: 150,
            editable: true,
        },
        {
            field: 'manufacturer_PayMent',
            headerName: '付款方式',
            width: 150,
            editable: true,
        },
    ];
    const [rows,setRows] = useState([])
    const [simple,setSimple] = useState('')
    const [name,setName] = useState('')
    const [person,setPerson] = useState('')
    const [manufacturer,setManufacturer] = useState({
        Id:0,
        Seq:'',
        BillNum:'',
        ComPhone:'',
        ComFax:'',
        EasyName:'',
        Boss:'',
        ComAddress:'',
        ComFullName:'',
        ContactPerson:'',
        AccCode:'',
        FacPhone:'',
        FacFax:'',
        PayWay:'',
        SendAddress:'',
        SendAddress2:'',
        SendAddress3:'',
    })

    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem('jwtData');
        if (!token) {
            navigate('/login');
        }
    }, [navigate]);
    const fetchData = async () => {
        try {
          
          const response = await axios.get(`${apiUrl}/Manufacturer/list`);
          // 檢查響應的結果，並設置到 state
          if (response.status === 200) {
            setRows(response.data.manufacturers);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const fetchFilterData = async () => {
        try {
          // 使用 encodeURIComponent 来确保参数被正确编码
          const simpleEncoded = encodeURIComponent(simple);
          const nameEncoded = encodeURIComponent(name);
          const personEncoded = encodeURIComponent(person);
    
          const response = await axios.get(`${apiUrl}/Manufacturer/filterlist?simpleName=${simpleEncoded}&name=${nameEncoded}&person=${personEncoded}`);
          // 检查响应的结果，并设置到 state
          if (response.status === 200) {
            setRows(response.data.manufacturers);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    


    useEffect(() => {
        // 定義非同步 function 來獲取數據
        fetchData();
    }, []); 

    const handleGetDetail = async (params) => {
        setManufacturer({
          Id: params.row.id, // 如果为 NULL，设置为空字符串
          Seq: params.row.manufacturer_Seq || "",
          BillNum: params.row.manufacturer_UniformNum || "",
          ComPhone: params.row.manufacturer_CompanyPhone || "",
          ComFax: params.row.manufacturer_CompanyFax || "",
          EasyName: params.row.manufacturer_AbbreviationName || "",
          Boss: params.row.manufacturer_BossName || "",
          ComAddress: params.row.manufacturer_CompanyAddress || "",
          ComFullName: params.row.manufacturer_FullName || "",
          ContactPerson: params.row.manufacturer_ContactName || "",
          AccCode: params.row.manufacturer_AccCode || "",
          FacPhone: params.row.manufacturer_FactoryNum || "",
          FacFax: params.row.manufacturer_FactoryFax || "",
          PayWay: params.row.manufacturer_PayMent || "",
          SendAddress: params.row.manufacturer_ShipAddress || "",
          SendAddress2: params.row.manufacturer_ShipAddress2 || "",
          SendAddress3: params.row.manufacturer_ShipAddress3 || "",
        });
    };
    const handleInputChange = (event, propertyName) => {
        const value = event.target.value;

        setManufacturer((prevData) => ({
            ...prevData,
            [propertyName]: value,
        }));
    };

    const handleSubmit = async () => {
        // const config = {
        //         headers: {
        //         'X-Ap-Token': appsetting.token,
        //         'X-Ap-CompanyId': sessionStorage.getItem('CompanyId'),
        //         'X-Ap-UserId': sessionStorage.getItem('UserId'),
        //         }
        //     };     
            try {
              const response = await axios.post(`${apiUrl}/Manufacturer/UpdateRow`, manufacturer);
              if (response.status === 200) {
                alert('成功');
                fetchData();
              }
            } catch (error) {
              console.error("Error logging in:", error);
              alert('失敗 欄位有誤');
            }          
    }
    const destorySubmit = async (seq) => {
        // const config = {
        //         headers: {
        //         'X-Ap-Token': appsetting.token,
        //         'X-Ap-CompanyId': sessionStorage.getItem('CompanyId'),
        //         'X-Ap-UserId': sessionStorage.getItem('UserId'),
        //         }
        //     };     
            try {
              const response = await axios.get(`${apiUrl}/Manufacturer/delete/${seq}`);
              if (response.status === 200) {
                alert('成功');
                fetchData();
              }
            } catch (error) {
              console.error("Error logging in:", error);
              alert('失敗 欄位有誤');
            }          
    }

  return (
    <>
    <Box
      sx={{
        margin:'2%',
        width: '95%',
        height: 400,
      }}
    >
       <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Button variant="text" onClick={()=>setManufacturer({
                        Id:0,
                        Seq:'',
                        BillNum:'',
                        ComPhone:'',
                        ComFax:'',
                        EasyName:'',
                        Boss:'',
                        ComAddress:'',
                        ComFullName:'',
                        ContactPerson:'',
                        AccCode:'',
                        FacPhone:'',
                        FacFax:'',
                        PayWay:'',
                        SendAddress:'',
                        SendAddress2:'',
                        SendAddress3:'',
                    })}>新增模式</Button>
                </Grid>
                <Grid item xs={3}/>
                <Grid item xs={3}/>
                <Grid item xs={3}/>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="廠商編號"
                     type="search" value={manufacturer.Seq}
                     onChange={(e) => handleInputChange(e, 'Seq')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="統一編號"
                     type="search" value={manufacturer.BillNum}
                     onChange={(e) => handleInputChange(e, 'BillNum')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="公司電話"
                     type="search" value={manufacturer.ComPhone}
                     onChange={(e) => handleInputChange(e, 'ComPhone')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="公司傳真"
                     type="search" value={manufacturer.ComFax}
                     onChange={(e) => handleInputChange(e, 'ComFax')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="簡稱"
                     type="search" value={manufacturer.EasyName}
                     onChange={(e) => handleInputChange(e, 'EasyName')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="負責人"
                     type="search" value={manufacturer.Boss}
                     onChange={(e) => handleInputChange(e, 'Boss')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="公司地址"
                     type="search" value={manufacturer.ComAddress}
                     onChange={(e) => handleInputChange(e, 'ComAddress')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="公司名稱"
                     type="search" value={manufacturer.ComFullName}
                     onChange={(e) => handleInputChange(e, 'ComFullName')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="聯絡人"
                     type="search" value={manufacturer.ContactPerson}
                     onChange={(e) => handleInputChange(e, 'ContactPerson')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="會計科目"
                     type="search" value={manufacturer.AccCode}
                     onChange={(e) => handleInputChange(e, 'AccCode')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="工廠電話"
                     type="search" value={manufacturer.FacPhone}
                     onChange={(e) => handleInputChange(e, 'FacPhone')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="工廠傳真"
                     type="search" value={manufacturer.FacFax}
                     onChange={(e) => handleInputChange(e, 'FacFax')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="付款方式"
                     type="search" value={manufacturer.PayWay}
                     onChange={(e) => handleInputChange(e, 'PayWay')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="送貨地址1"
                     type="search" value={manufacturer.SendAddress}
                     onChange={(e) => handleInputChange(e, 'SendAddress')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="送貨地址2"
                     type="search" value={manufacturer.SendAddress2}
                     onChange={(e) => handleInputChange(e, 'SendAddress2')}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-search" label="送貨地址3"
                     type="search" value={manufacturer.SendAddress3}
                     onChange={(e) => handleInputChange(e, 'SendAddress3')}/>
                </Grid>
                <Grid item xs={4}/>
                <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>
                    <Button variant="text" onClick={handleSubmit}>送出新增或修改</Button>
                </Grid>              
            </Grid>
        </Box>
    </Box>
    <Box
      sx={{
        margin:'2%',
        width: '95%',
        height: 700,
      }}
    >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField id="outlined-search" label="簡稱"
                     type="search" value={simple}
                     onChange={(e) => setSimple(e.target.value)}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField id="outlined-search" label="公司名稱"
                     type="search" value={name}
                     onChange={(e) => setName(e.target.value)}/>
                </Grid>
                <Grid item xs={4}>
                    <TextField id="outlined-search" label="聯絡人"
                     type="search" value={person}
                     onChange={(e) => setPerson(e.target.value)}/>
                </Grid>
                <Grid item xs={4}/>
                <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>
                    <Button variant="text" onClick={fetchFilterData}>搜尋</Button>
                </Grid>              
            </Grid>
        <DataGrid
            rows={rows}
            columns={columns}
            onCellClick={handleGetDetail}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 100,
                },
            },
            }}
            pageSizeOptions={[100,75,50]}
            disableRowSelectionOnClick
        />
    </Box>
    </>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; 
import { Document, Page, Text, StyleSheet, View, Image, PDFDownloadLink, PDFViewer, Font } from '@react-pdf/renderer';
import chFont from '../../../css/font/TaipeiSansTCBeta-Bold.ttf'
import chLight from '../../../css/font/TaipeiSansTCBeta-Light.ttf'
import { apiUrl } from '../../apiUrl/ApiUrl';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function MakeCmd() {
    const currentDate = new Date();
    const formattedDate = dayjs(currentDate).format('YYYY-MM-DD');
    const [value, setValue] = React.useState(dayjs(formattedDate));
    const [data, setData] = React.useState([]);
    const [piNum, setPinum] = React.useState('');
    const [makeAmount, setMakeAmount] = React.useState('');
    const [machModel, setMachModel] = React.useState('');
    const [cmdNoStart, setCmdNoStart] = React.useState('');
    const [cmdNoEnd, setCmdNoEnd] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [bomOpen, setBomOpen] = React.useState(false);
    const [printItems, setPrintItems] = React.useState([]);
    const [pdfData, setPdfData] = React.useState([]);

    const handleClickOpen = () => {
        if (cmdNoStart !== '' && cmdNoEnd !== '') {
            axios.get(`${apiUrl}/MatCard/GetPrintMakeCmd?start=${cmdNoStart}&end=${cmdNoEnd}`)
                .then(response => {
                    if (response.status === 200) {
                        setPrintItems(response.data);
                        setOpen(true);
                    } else {
                        alert('發生錯誤');
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            alert('請選擇製令起始');
        }
    };

    const downloadBomPdf = async () => {
        try {
            const response = await axios.get(`${apiUrl}/MatCard/PrintSaveDataAfterAlertStep6`, {
                params: {
                    user: sessionStorage.getItem('userName'),
                    model: '',
                    qty: makeAmount,
                    date: value.format('YYYY-MM-DD'),
                    isHand: 0,
                    way: 'piNum',
                    num: piNum
                }
            });
            if (response.status === 200) {
                setPdfData(response.data);
                setBomOpen(true);
            }
        } catch (error) {
            console.error("There was an error downloading the file!", error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setBomOpen(false);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            axios.get(`${apiUrl}/MatCard/Getcmds?piNum=${piNum}`)
                .then(response => {
                    if (response.data.statusCode === 200) {
                        setData(response.data.makeCmds);
                    } else {
                        alert('發生錯誤');
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        if (data.length > 0) {
            setMakeAmount(data[0].makeAmount);
            setMachModel(data[0].machModel);
        }
    }, [data]);

    useEffect(() => {
        if (printItems.length > 0) {
            console.log(printItems);
        }
    }, [printItems]);

    return (
        <Box sx={{ height: 800, width: '60%' }}>
            <div style={{ margin: '20px' }}>
                <TextField id="outlined-basic" value={piNum} label="批號" variant="outlined" onChange={(e) => setPinum(e.target.value)} onKeyPress={handleKeyPress} />
            </div>
            <div style={{ margin: '20px' }}>
                <TextField id="outlined-basic" label="機種" variant="outlined" value={machModel} />
            </div>
            <div style={{ margin: '20px' }}>
                <TextField id="outlined-basic" label="台數" variant="outlined" value={makeAmount} onChange={(e) => setMakeAmount(e.target.value)} />
            </div>
            <div style={{ margin: '20px' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                            label="需求日"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
            <div style={{ margin: '20px' }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">製令起始No</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={cmdNoStart}
                        label="製令"
                        onChange={(e) => setCmdNoStart(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {data.map((option, index) => (
                            <MenuItem key={index} value={option.num}>
                                {option.num}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div style={{ margin: '20px' }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">製令結束No</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={cmdNoEnd}
                        label="製令"
                        onChange={(e) => setCmdNoEnd(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {data.map((option, index) => (
                            <MenuItem key={index} value={option.num}>
                                {option.num}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Button variant="text" onClick={downloadBomPdf}>Bom用料表</Button>
            <Dialog
                fullScreen
                open={bomOpen}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            列印預覽
                        </Button>
                    </Toolbar>
                </AppBar>
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <MyBomDocument data={pdfData} day={value.format('YYYY/MM/DD')} />
                </PDFViewer>
            </Dialog>
            <Button variant="text" onClick={handleClickOpen}>印製令</Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            列印預覽
                        </Button>
                    </Toolbar>
                </AppBar>
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <MyDocument data={printItems} day={value.format('YYYY/MM/DD')} />
                </PDFViewer>
            </Dialog>
        </Box>
    );
}

// Font Registration
Font.register({
    family: 'TaipeiSansTCBeta-Bold',
    src: chFont, // 使用导入的字体
});

Font.register({
    family: 'TaipeiSansTCBeta-Light',
    src: chLight, // 使用导入的字体
});

// PDF Styles
const pageWidth = 24.1 * 72 / 2.54;
const pageHeight = 14 * 72 / 2.54;

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 24,
        width: pageWidth,
        height: pageHeight,
    },
    tableContainer: {
        flexDirection: 'row',
    },
    tableHeaderCell: {
        fontSize: 12,
        width: pageWidth / 8,
        fontFamily: 'TaipeiSansTCBeta-Light',
        textAlign: 'center',
        height: 24, // 需要这个属性来确保4行空白行正确渲染
    },
    dayCell: {
        fontSize: 16,
        textAlign: 'flex-start',
        fontFamily: 'TaipeiSansTCBeta-Light',
        marginRight: 100,
    },
});

const BOMstyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 24,
        width: pageWidth,
        height: pageHeight,
    },
    tableContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        fontFamily: 'TaipeiSansTCBeta-Bold',
        width: '100%',
    },
    headerRow: {
        flexDirection: 'row',
        width: '100%',
        textAlign: 'center',
        marginTop: 5,
    },
    headerRowDash: {
        flexDirection: 'row',
        width: '100%',
        textAlign: 'center',
        marginTop: 10,
        borderTop: 1,
        borderBottom: 1,
        borderColor: 'black',
        paddingTop: 5,
        paddingBottom: 5,
    },
    headerItem: {
        flex: 1,
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header子件料號: {
        width: '15%',
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header品名: {
        width: '5%',
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header規格: {
        width: '25%',
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header配賦量: {
        width: '5%',
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header單位: {
        width: '10%',
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header需求量: {
        width: '10%',
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header實領: {
        width: '10%',
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header備註: {
        width: '20%',
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
});

const MyBomDocument = ({ data, day }) => (
    <Document>
        {data.map((item, index) => (
            <Page key={index} size={{ width: pageWidth, height: pageHeight }} style={BOMstyles.page}>
                <View style={BOMstyles.tableContainer}>
                    <Text style={BOMstyles.title}>福蒙BOM用料表</Text>
                </View>
                <View style={BOMstyles.headerRow}>
                    <Text style={BOMstyles.headerItem}>主件料號:{item.主件料號}</Text>
                    <Text style={BOMstyles.headerItem}>規格:{item.規格}</Text>
                    <Text style={BOMstyles.headerItem}>機種:{item.機種}</Text>
                    <Text style={BOMstyles.headerItem}>批量:{item.批量}</Text>
                    <Text style={BOMstyles.headerItem}>日期:{day}</Text>
                </View>
                <View style={BOMstyles.headerRow}>
                    <Text style={BOMstyles.headerItem}>品名:{item.主件料號}</Text>
                    <Text style={BOMstyles.headerItem}>單位:{item.單位}</Text>
                    <Text style={BOMstyles.headerItem}>批號:{item.批號}</Text>
                    <Text style={BOMstyles.headerItem}>Page:{index + 1} / {data.length}</Text>
                </View>
                <View style={BOMstyles.headerRowDash}>
                    <Text style={BOMstyles.header子件料號}>子件料號</Text>
                    <Text style={BOMstyles.header品名}>品名</Text>
                    <Text style={BOMstyles.header規格}>規格</Text>
                    <Text style={BOMstyles.header配賦量}>配賦量</Text>
                    <Text style={BOMstyles.header單位}>單位</Text>
                    <Text style={BOMstyles.header需求量}>需求量</Text>
                    <Text style={BOMstyles.header實領}>實領</Text>
                    <Text style={BOMstyles.header備註}>備註</Text>
                </View>
                {[...Array(12).keys()].map((_, i) => (
                    <View key={i} style={BOMstyles.headerRow}>
                        <Text style={BOMstyles.header子件料號}>{item[`子件料號${i + 1}`]}</Text>
                        <Text style={BOMstyles.header品名}>{item[`品名${i + 1}`]}</Text>
                        <Text style={BOMstyles.header規格}>{item[`規格${i + 1}`]}</Text>
                        <Text style={BOMstyles.header配賦量}>{item[`配賦量${i + 1}`]}</Text>
                        <Text style={BOMstyles.header單位}>{item[`單位${i + 1}`]}</Text>
                        <Text style={BOMstyles.header需求量}>{item[`需求量${i + 1}`]}</Text>
                        <Text style={BOMstyles.header實領}>_____</Text>
                        <Text style={BOMstyles.header備註}>________________</Text>
                    </View>
                ))}
                <View style={BOMstyles.headerRow}>
                    <Text style={BOMstyles.headerItem}>製表人:{sessionStorage.getItem('userName') + day}</Text>
                    <Text style={BOMstyles.headerItem}>發料人:</Text>
                    <Text style={BOMstyles.headerItem}>領料人:</Text>
                </View>
            </Page>
        ))}
    </Document>
);

const MyDocument = ({ data, day }) => (
    <Document>
        {data.map((bigitem, index) => (
            <Page key={index} size={{ width: pageWidth, height: pageHeight }} style={styles.page}>
                <View style={{ height: 32 }} /> {/* 添加额外的空白区域 */}
               
                <View style={styles.tableContainer}>
                    <Text style={styles.tableHeaderCell}>製令No#</Text>
                    <Text style={styles.tableHeaderCell}>{bigitem.單號}</Text>
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell}>{day}</Text>
                    <Text style={styles.tableHeaderCell} />
                </View>
                <View style={{ height: 12 }} />
                <View style={styles.tableContainer}>
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell}>{bigitem.批號}</Text>
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell}>{bigitem.日期}</Text>
                    <Text style={styles.tableHeaderCell} />
                </View>
                <View style={styles.tableContainer}>
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell}>{bigitem.圖號}</Text>
                    <Text style={styles.tableHeaderCell} />
                    <Text
                        style={[styles.tableHeaderCell, { fontSize: 8, flexShrink: 1 }]}
                        numberOfLines={1}
                        ellipsizeMode="clip"
                    >
                        {bigitem.規格}
                    </Text>
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell}>{bigitem.生產量}</Text>
                    <Text style={styles.tableHeaderCell} />
                </View>
                <View style={styles.tableContainer}>
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell}>{bigitem.物品名}</Text>
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell}>{bigitem.素材}</Text>
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                </View>
                <View style={styles.tableContainer}>
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                    <Text style={styles.tableHeaderCell} />
                </View>
                             
                {bigitem.工程內容們.map((item, index1) => (
                    <React.Fragment key={index1}>
                        <View style={{ height: 2 }} />
                       
                        <View style={styles.tableContainer}>
                            <Text style={styles.tableHeaderCell} />
                            <Text style={styles.tableHeaderCell}>{item}</Text>
                            <Text style={styles.tableHeaderCell} />
                            <Text style={styles.tableHeaderCell} />
                            <Text style={styles.tableHeaderCell} />
                            {bigitem.工作職場們.map((item, index2) => (
                                index1 === index2 ? <Text key={index2} style={styles.tableHeaderCell}>{item}</Text> : null
                            ))}

                        </View>
                    </React.Fragment>
                ))}
                <View style={{ position: 'absolute', right: -32, bottom: 24 }}>
                    <Text style={[styles.dayCell, { fontSize: 8 }]}>{`Admin ${day}`}</Text>
                </View>
            </Page>
        ))}
    </Document>
);

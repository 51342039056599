// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;



const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: '使用者列表',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  {
    title: '基本資料設定',
    path: '/dashboard/basic',
    icon: icon('ic_disabled'),
  },
  {
    title: '業務管理',
    path: '/dashboard/business_management',
    icon: icon('ic_blog'),
  },
  {
    title: '生產管理',
    path: '/dashboard/product_management',
    icon: icon('ic_blog'),
  },
  {
    title: '物料管理',
    path: '/dashboard/storage_management',
    icon: icon('ic_blog'),
  },
  {
    title: '採購管理',
    path: '/dashboard/purchase_management',
    icon: icon('ic_blog'),
  },
  {
    title: '帳款管理',
    path: '/dashboard/account_management',
    icon: icon('ic_blog'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;

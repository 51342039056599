import { Navigate, useRoutes,Route ,Routes} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import BasicSetUp from './pages/BasicSetUp';
import BusinessManager from './pages/BusinessManager';
import ProductManager from './pages/ProductManager';
import StorageManager from './pages/StorageManager';
import PurchaseManager from './pages/PurchaseManager';
import EditOrDeleteUserPage from './components/editOrDeleteUser/EditOrDeleteUser';
import AccountManage from './pages/AccountManage'; 
import ProtectedRoute from './components/auth/ProtectedRoute';
// ----------------------------------------------------------------------
// export default function Router() {
//   return (
//     <Routes>
//       <Route path="/login" element={<LoginPage />} />
//       <ProtectedRoute path="/dashboard/app" element={<DashboardAppPage />} />
//       <ProtectedRoute path="/dashboard/user" element={<UserPage />} />
//       <ProtectedRoute path="/dashboard/products" element={<ProductsPage />} />
//       <ProtectedRoute path="/dashboard/blog" element={<BlogPage />} />
//       <ProtectedRoute path="/dashboard/basic" element={<BasicSetUp />} />
//       <ProtectedRoute path="/dashboard/business_management" element={<BusinessManager />} />
//       <ProtectedRoute path="/dashboard/product_management" element={<ProductManager />} />
//       <ProtectedRoute path="/dashboard/storage_management" element={<StorageManager />} />
//       <Route path="*" element={<SimpleLayout />}>
//         <Route index element={<Navigate to="/dashboard/app" />} />
//         <Route path="404" element={<Page404 />} />
//         <Route path="*" element={<Navigate to="/404" />} />
//       </Route>
//     </Routes>
//   );
// }
export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'basic', element: <BasicSetUp /> },
        { path: 'business_management', element: <BusinessManager /> },
        { path: 'product_management', element: <ProductManager /> },
        { path: 'storage_management', element: <StorageManager /> },
        { path: 'purchase_management', element: <PurchaseManager /> },
        { path: 'account_management', element: <AccountManage /> },
        { path: 'EditOrDeleteUserPage', element: <EditOrDeleteUserPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}


import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CalculateIcon from '@mui/icons-material/Calculate';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { apiUrl } from '../../apiUrl/ApiUrl';


const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'makeCom',
    headerName: '廠商',
    width: 150,
    editable: true,
  },
  {
    field: 'money',
    headerName: '金額',
    type: 'number',
    width: 120,
    editable: true,
  },
  {
    field: 'tax',
    headerName: '稅額',
    type: 'number',
    width: 120,
    editable: true,
  },
  {
    field: 'changeMoney',
    headerName: '加減金額',
    type: 'number',
    width: 120,
    editable: true,
  },
  {
    field: 'total',
    headerName: '總計',
    type: 'number',
    width: 120,
    editable: true,
  },
  {
    field: 'memo',
    headerName: 'Memo',
    width: 150,
    editable: true,
  },
];
const columns2 = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'billNo',
      headerName: '發票No',
      width: 150,
      editable: true,
    },
    {
      field: 'date',
      headerName: '日期',
      width: 150,
      editable: true,
    },
    {
      field: 'orderNum',
      headerName: '單號',
      width: 150,
      editable: true,
    },
    {
      field: 'piNum',
      headerName: '批號',
      width: 150,
      editable: true,
    },
    {
      field: 'drawNum',
      headerName: '圖號',
      width: 150,
      editable: true,
    },
    {
        field: 'itemName',
        headerName: '物品名',
        width: 100,
        editable: true,
    },
    {
        field: 'amount',
        headerName: '數量',
        type:'number',
        width: 150,
        editable: true,
    },
    {
        field: 'price',
        headerName: '單價',
        type:'number',
        width: 100,
        editable: true,
    },
    {
        field: 'money',
        headerName: '金額',
        type:'number',
        width: 100,
        editable: true,
    }
  ];

export default function ShouldPay() {
    const [editedRows, setEditedRows] = React.useState([]);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const [value, setValue] = React.useState(dayjs(formattedDate));

    const [shouldPayRows,setShouldPayRows] = React.useState([]);
    const [bills,setBills] = React.useState([]);

    const [orderBy,setOrderBy] = React.useState('單號');
    const [total,setTotal] = React.useState(null);
    const [makeCom, setMakeCompany] = React.useState('');
    const [payableAmount, setPayableAmount] = React.useState(0);
    const [taxAmount, setTaxAmount] = React.useState(0);
    const [adjustmentAmount, setAdjustmentAmount] = React.useState(0);
    const [totalPayable, setTotalPayable] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(50); // 使用狀態來控制每頁顯示的行數

    const handleSearch = async () => {
      const rocYear = dayjs(value).year() - 1911;
      const formattedValue = `${rocYear}/${dayjs(value).format('MM')}`;

      try {
        const response = await axios.get(`${apiUrl}/Bill/GetShouldPay?month=${formattedValue}`);
        console.log(response.data);
        if(response.data.statusCode === 200)  {
          const data = response.data.shouldPayTotalDtos;
            setShouldPayRows(data);

            if (data.length > 0) {
                const lastEntry = data[data.length - 1]; // 获取数组中的最后一个元素
                setPayableAmount(lastEntry.money);
                setTaxAmount(lastEntry.tax);
                setAdjustmentAmount(lastEntry.changeMoney);
                setTotalPayable(lastEntry.total);
            } else {
                console.log('No data available');
                setPayableAmount(0);
                setTaxAmount(0);
                setAdjustmentAmount(0);
                setTotalPayable(0);
            }
        }
        // 在此处处理API返回的数据
      } catch (error) {
        console.error('There was an error!', error);
        // 在此处处理错误
      }
    };

    const handleReCaculate = async () => {
      const rocYear = dayjs(value).year() - 1911;
      const formattedValue = `${rocYear}/${dayjs(value).format('MM')}`;
      try {
        const response = await axios.get(`${apiUrl}/Bill/Recaculate?month=${formattedValue}`);
        console.log(response.data);
        if(response.status === 200)  {
          handleSearch();
        }
        // 在此处处理API返回的数据
      } catch (error) {
        console.error('There was an error!', error);
        // 在此处处理错误
      }
    };
    const processRowUpdate = (newRow, oldRow) => {
      // 透過 newRow 的 id 找到 editedRows 陣列中的索引
      const index = editedRows.findIndex(row => row.id === newRow.id);
    
      // 若找到相同的 id，則先刪除
      if (index > -1) {
        editedRows.splice(index, 1);
      }
    
      // 將 newRow 加入 editedRows 陣列
      setEditedRows([...editedRows, newRow]);
      return newRow;
    };
  
    const handleSave = async () => {
      const response = await axios.post(`${apiUrl}/Bill/EditShouldPay`, editedRows);
      await handleSearch();

    };

    const handleInStockSearch = async (params) => {
      const rocYear = dayjs(value).year() - 1911;
      const formattedValue = `${rocYear}/${dayjs(value).format('MM')}`;
      setMakeCompany (params.row.makeCom);
      try {
        const response = await axios.get(`${apiUrl}/Bill/GetInstock?month=${formattedValue}&company=${makeCom}&orderby=${orderBy}`);
        console.log(response.data);
        if(response.data.statusCode === 200)  {
          setBills(response.data.inStockDtos)
          setTotal(response.data.totalNum)
        }
        // 在此处处理API返回的数据
      } catch (error) {
        console.error('There was an error!', error);
        // 在此处处理错误
      }
    };

    const handleGetInstokMf = async (params) => {
      // 计算 ROC 年和格式化日期
      console.log('handleGetInstokMf 被触发了');
      const rocYear = dayjs(value).year() - 1911;
      const formattedValue = `${rocYear}/${dayjs(value).format('MM')}`;
      try {
          // 发起请求，注意设置 responseType 为 'blob'
          const response = await axios.get(`${apiUrl}/Bill/GetInstockMf?month=${formattedValue}&company=${makeCom}&orderby=${orderBy}`, {
              responseType: 'blob'
          });
  
          // 检查状态码并处理 PDF 文件
          if (response.status === 200) {
              // 创建 Blob URL 并在新标签页打开
              const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
              window.open(fileURL, '_blank');
          } else {
              console.error('Failed to load PDF:', response.status);
          }
      } catch (error) {
          console.error('There was an error fetching the PDF:', error);
      }
    };
  
    const handleshouldPayList = async () => {
    // 计算 ROC 年和格式化日期
    
      const rocYear = dayjs(value).year() - 1911;
      const formattedValue = `${rocYear}/${dayjs(value).format('MM')}`;
       
      try{
      // 发起请求，注意设置 responseType 为 'blob'
      const response = await axios.get(`${apiUrl}/Bill/GetShouldPayList?month=${formattedValue}`, { responseType: 'blob' });


        // 检查状态码并处理 PDF 文件
        if (response.status === 200) {
            // 创建 Blob URL 并在新标签页打开
            const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            window.open(fileURL, '_blank');
        } else {
            console.error('Failed to load PDF:', response.status);
        }
      } catch (error) {
        console.error('There was an error fetching the PDF:', error);
    }
};


    console.log(value.format('YYYY-MM-DD'))
    return (
        <>
        <Box sx={{ height: 400, width: '100%' }}>
            <Box sx={{ '& button': { m: 1 } }}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                            label="月份選擇"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleSearch}>
                        搜尋
                    </Button>
                    <Button variant="contained" endIcon={<CalculateIcon />} onClick={handleReCaculate}>
                        重新計算
                    </Button>
                    <Button variant="contained" onClick={handleSave} endIcon={<SaveIcon />}>
                        保存
                    </Button>
                    <Button variant="contained" onClick={handleshouldPayList}  endIcon={<FactCheckIcon />}>
                        列印總表
                    </Button>
                    <div>應付金額：{payableAmount.toLocaleString('zh-TW', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    <div>稅額：{taxAmount.toLocaleString('zh-TW', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    <div>加減金額：{adjustmentAmount.toLocaleString('zh-TW', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    <div>應付總計：{totalPayable.toLocaleString('zh-TW', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>

                </div>
            </Box>
        <DataGrid
            rows={shouldPayRows}
            columns={columns}
            onCellClick={handleInStockSearch}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 50,
                },
            },
            }}
            pageSize={pageSize} // 綁定到 pageSize state
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} // 動態更新 pageSize
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={error=>alert(error)}
            onRowEditCommit={(rowId, e) => {
              handleSave(rowId,e);
            }}
            pageSizeOptions={[50,75,100]}
            disableRowSelectionOnClick
        />
        </Box>
        
        <Box sx={{ height: 400, width: '100%', marginTop: '16%' }}>
    <FormControl fullWidth>
        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ marginTop: '16px' }}>排序</FormLabel>
        <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
        >
            <FormControlLabel value="單號" control={<Radio />} label="單號" />
            <FormControlLabel value="發票號碼" control={<Radio />} label="發票號碼" />
            <FormControlLabel value="批號" control={<Radio />} label="批號" />
            <FormControlLabel value="圖號" control={<Radio />} label="圖號" />
        </RadioGroup>
        <Button
            variant="contained"
            endIcon={<FactCheckIcon />}
            sx={{ width: '30%', marginTop: '16px' }}
            onClick={handleGetInstokMf}  // 更新了 onClick 事件
        >
            對帳單
        </Button>
        <div style={{ marginTop: '10px' }}>
        <span>合計 : </span><span>{total !== null ? total.total.toFixed(2) : null}</span>
        <span> 營業稅 : </span><span>{total !== null ? total.tax.toFixed(2) : null}</span>
        <span> 總計 : </span><span>{total !== null ? total.totalMoney.toFixed(2) : null}</span>

        </div>
    </FormControl>
    <DataGrid
        rows={bills}
        columns={columns2}
        initialState={{
            pagination: {
                paginationModel: {
                    pageSize: 15,
                },
            },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
    />
</Box>

        </>
    );
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate,useParams  } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import ReplyIcon from '@mui/icons-material/Reply';
import InputLabel from '@mui/material/InputLabel';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SendIcon from '@mui/icons-material/Send';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchIcon from '@mui/icons-material/Search';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import NativeSelect from '@mui/material/NativeSelect';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg, pdf } from '@react-pdf/renderer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Slide from '@mui/material/Slide';
import { DataGrid } from '@mui/x-data-grid';
import { apiUrl } from '../../apiUrl/ApiUrl';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function BuyBill() {

    const columns = [
     
      {   field: 'id', headerName: 'ID', width: 90 },
      {
          field: '單號',
          headerName: '單號',
          width: 100,
          editable: true,
      },
      {
          field: '廠商',
          headerName: '廠商',
          width: 100,
          editable: true,
      },
    ];
    const billcolumns = [
     
        {   field: 'id', headerName: 'ID', width: 90 },
        {
            field: '圖號',
            headerName: '圖號',
            width: 200,
            editable: true,
        },
        {
            field: '物品名',
            headerName: '物品名',
            width: 200,
            editable: true,
        },
        {
            field: '單位',
            headerName: '單位',
            width: 100,
            editable: true,
        },
        {
            field: '數量',
            headerName: '數量',
            width: 100,
            editable: true,
        },
        {
            field: '單價',
            headerName: '單價',
            width: 100,
            editable: true,
        },
        {
            field: '金額',
            headerName: '金額',
            width: 100,
            editable: true,
        },
        {
            field: '單號',
            headerName: '批號/請購單號',
            width: 100,
            editable: true,
        },
        {
            field: "操作",
            headerName: "操作",
            width: 150,
            renderCell: (params) => (
              <IconButton
                onClick={() => handleDeleteClick(params)} // 点击按钮时调用删除处理函数
                color="primary"
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            ),
          },
    
    ];
    const addBillcolumns = [
     
        {   field: 'id', headerName: 'ID', width: 90 },
        {
            field: '圖號',
            headerName: '圖號',
            width: 200,
            editable: true,
        },
        {
            field: '物品名',
            headerName: '物品名',
            width: 200,
            editable: true,
        },
        {
            field: '單位',
            headerName: '單位',
            width: 100,
            editable: true,
        },
        {
            field: '數量',
            headerName: '數量',
            width: 100,
            editable: true,
        },
        {
            field: '單價',
            headerName: '單價',
            width: 100,
            editable: true,
        },
        {
            field: '金額',
            headerName: '金額',
            width: 100,
            editable: true,
        },
        {
            field: '單號',
            headerName: '批號/請購單號',
            width: 100,
            editable: true,
        },
        {
            field: "操作",
            headerName: "操作",
            width: 150,
            renderCell: (params) => (
              <IconButton
                onClick={() => handleAddRowsDelete(params.row.id)} // 点击按钮时调用删除处理函数
                color="primary"
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            ),
          },
    
    ];
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const [totalMoney,setTotalMoney] = useState(0);
    const [rows, setRows] = React.useState([]);
    const [printRows, setPrintRows] = React.useState([]);
    const [billRows, setBillRows] = React.useState([]);

    const [addBillRows, setAddBillRows] = React.useState([]);
    const [addEditBillRows, setAddEditBillRows] = React.useState([]);


    const [options, setOptions] = React.useState([]);
    const [editedRows, setEditedRows] = React.useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectMonth, setSelectMonth] = React.useState(dayjs(formattedDate));
    const [drawNum,setDrawNum] = useState('');   
    const [orderNum,setOrderNum] = useState('');   
    const [other, setOther] = React.useState('');   
    const [request,setRequest] = useState({
        id:0,
        公司:'福蒙',
        廠商:'',
        主管:'',
        核准:'',
        聯絡人:'尚未選擇',
        單號:'new',
        電話:'尚未選擇',
        傳真:'尚未選擇',
        日期:'',
        需求日期:'',
        採購類別:'外購',
        申請人:'',
        備註:'',
        列印:''
    });
    const [open, setOpen] = React.useState(false);
    const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
    const [pdfOpen, setPdfOpen] = React.useState(false);
    const [detailsOpen, setDetailsOpenOpen] = React.useState(false);
    const [detailOptions, setDetailsOptions] = React.useState([]);
    const [item, setItem] = React.useState('');

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleDetailsClickOpen = () => {
        setDetailsOpenOpen(true);
    };
    const handleDetailsClickClose = () => {
        setDetailsOpenOpen(false);
        setAddEditBillRows([]);
        setAddBillRows([]);
    };
  

    const handlePdfClickOpen = () => {
        setPdfOpen(true);
    };
    const handlePdfClickClose = () => {
        setPdfOpen(false);
    };

    const handleAfterPrintPdfClickClose = () => {
        handleOKClick();
        setPdfOpen(false);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleDetailsOptionsClick = async (no) => {
            const vendorParam = encodeURIComponent(request.廠商);
        try {    
            const response = await axios.get(`${apiUrl}/PurchasePrice/ItemOptions?num=${no}&vendor=${vendorParam}`);
            if (response.status === 200) {
                setDetailsOptions(response.data);    
            }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
      };

      const handleOKClick = async () => {
        try {    
            const response = await axios.get(`${apiUrl}/PurchasePrice/OkData?num=${request.單號}`);
            if (response.status === 200) {
                alert('修改採購列印OK');
            }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
    };

    const handleCellClick = async (param) => {
        try {    
            const response = await axios.get(`${apiUrl}/PurchasePrice/BuyBillsByNum?num=${param.row.單號}`);
            setOrderNum(param.row.單號);
            if(response.data.length === 0) {
                alert('找不到資料');
                return;
            }

            if(response.data[0].類別 !== '外購' && response.data[0].類別 !== '合約' && response.data[0].類別 !== '託外') {
                setOther(response.data[0].類別);
            }
            if (response.status === 200) {
                setRequest({
                    ...request,
                    公司:response.data[0].公司,
                    廠商:response.data[0].廠商, 
                    單號:response.data[0].單號,
                    日期:response.data[0].日期.split("T")[0],
                    需求日期:response.data[0].需求日.split("T")[0],
                    採購類別:response.data[0].類別,
                    申請人:response.data[0].申請人,
                    主管:response.data[0].主管,
                    核准:response.data[0].核准,
                    備註:response.data[0].memo === null? '':response.data[0].memo,
                    列印:response.data[0].列印
                })
                const selectedOption = options.find(option => option.manufacturer_AbbreviationName === response.data[0].廠商);
                setSelectedOption(selectedOption)
                setBillRows(response.data)
                setEditedRows([]);
            }
            } catch (error) {
            console.error('Error fetching data:', error);
            }
      };


    const handleSearchClick = async () => {
        try {    
            const response = await axios.get(`${apiUrl}/PurchasePrice/BuyBillsByNum?num=${orderNum}`);
            if(response.data.length === 0) {
                alert('找不到資料');
                return;
            }
            if(response.data[0].類別 !== '外購' && response.data[0].類別 !== '合約' && response.data[0].類別 !== '託外') {
                setOther(response.data[0].類別);
            }
            if (response.status === 200) {
                setRequest({
                    ...request,
                    公司:response.data[0].公司,
                    廠商:response.data[0].廠商, 
                    單號:response.data[0].單號,
                    日期:response.data[0].日期.split("T")[0],
                    需求日期:response.data[0].需求日.split("T")[0],
                    採購類別:response.data[0].類別,
                    申請人:response.data[0].申請人,
                    主管:response.data[0].主管,
                    核准:response.data[0].核准,
                    備註:response.data[0].備註,
                    列印:response.data[0].列印
                })
                const selectedOption = options.find(option => option.manufacturer_AbbreviationName === response.data[0].廠商);
                setSelectedOption(selectedOption)
                setBillRows(response.data)
                setEditedRows([]);
                handleClose();
            }
            } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePrintClick = async () => {
        try {
            const response = await axios.get(`${apiUrl}/Buyer/PrintData?orderNum=${orderNum}`, {
                responseType: 'json' // 确保接收 JSON 格式
            });
    
            console.log('Response Status:', response.status);
            console.log('Response Data:', response.data);
    
            // 确保响应数据存在且结构正确
            if (response.status === 200 && response.data) {
                const { pdfContent, warehouseBool, approvalBool } = response.data;
    
                // 判断是否已经入库
                if (warehouseBool === true) {
                    alert('此單已入庫，無法列印');
                    return;
                }
    
                // 判断是否尚未核准
                if (approvalBool === true) {
                    alert('此單尚未核准，無法列印');
                    return;
                }
    
                // 处理PDF内容
                if (pdfContent) {
                    const byteCharacters = atob(pdfContent);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i += 1) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(pdfBlob);
    
                    // 设置 PDF Blob URL 并打开对话框
                    setPdfBlobUrl(pdfUrl);
                    handlePdfClickOpen();
                } else {
                    alert('PDF内容为空');
                }
            } else {
                alert('响应数据无效');
            }
    
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    

    const handleDeleteClick = async (param) => {
        try {    
            const response = await axios.get(`${apiUrl}/PurchasePrice/Remove?num=${request.單號}&id=${param.row.id}`);
            if(response.data.length === 0) {
                alert('找不到資料');
                return;
            }
            if(response.data[0].類別 !== '外購' && response.data[0].類別 !== '合約' && response.data[0].類別 !== '託外') {
                setOther(response.data[0].類別);
            }
            if (response.status === 200) {
                setRequest({
                    ...request,
                    公司:response.data[0].公司,
                    廠商:response.data[0].廠商, 
                    單號:response.data[0].單號,
                    日期:response.data[0].日期.split("T")[0],
                    需求日期:response.data[0].需求日.split("T")[0],
                    採購類別:response.data[0].類別,
                    申請人:response.data[0].申請人,
                    主管:response.data[0].主管,
                    核准:response.data[0].核准,
                    備註:response.data[0].備註,
                    列印:response.data[0].列印
                })
                const selectedOption = options.find(option => option.manufacturer_AbbreviationName === response.data[0].廠商);
                setSelectedOption(selectedOption)
                setBillRows(response.data)
                setEditedRows([]);
                handleClose();
            }
            } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async () => {
        if(request.日期 === '' || request.需求日期 === '') {
            alert('日期跟需求日期不得為空');
            return;
        }
        const combinedRows = [...addEditBillRows];

        // 遍历 addBillRows，查找任何不在 addEditBillRows 中的元素（基于 id）
        addBillRows.forEach(addRow => {
          // 查找当前 addRow 是否在 addEditBillRows 中存在
          const exists = addEditBillRows.some(editRow => editRow.id === addRow.id);
          
          // 如果不存在，则将其添加到 combinedRows 中
          if (!exists) {
            combinedRows.push(addRow);
          }
        });
        
        // 现在 combinedRows 包含了所有独特的行，你可以将其用作数据发送
        const dataToSend = {
            BaseSet: request, // 展开 'request' 对象中的字段
            purchaseViewModels: combinedRows, // 使用合并后的行数组
        };
    
        try {
            // 发送POST请求，此处替换为您的实际API端点
            const response = await axios.post(`${apiUrl}/PurchasePrice/AddPurchaseBills`, dataToSend);
    
            // 检查响应，如果成功可以做一些操作，例如状态更新或日志记录
            if (response.status === 200) {
                alert('新增採購成功');
                setAddBillRows(response.data);
                setBillRows(response.data);
                setRequest({
                    ...request,
                    公司:response.data[0].公司,
                    廠商:response.data[0].廠商, 
                    單號:response.data[0].單號,
                    日期:response.data[0].日期.split("T")[0],
                    需求日期:response.data[0].需求日.split("T")[0],
                    採購類別:response.data[0].類別,
                    申請人:response.data[0].申請人,
                    主管:response.data[0].主管,
                    核准:response.data[0].核准,
                    備註:response.data[0].備註,
                    列印:response.data[0].列印
                })
                // 可能的状态更新或其他逻辑...
            } else {
                console.error('Server responded with status:', response.status);
                // 处理错误响应，可能需要更新状态或用户通知...
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            alert('新增採購失敗');
        }
    };
    const fetchData = async () => {         
            const com = selectedOption == null? '':selectedOption.manufacturer_AbbreviationName;
            console.log(selectMonth);
            try {    
            const response = await axios.get(`${apiUrl}/PurchasePrice/BuyBills?month=${selectMonth.month() + 1}&com=${com}&year=${selectMonth.year()}`);
            if (response.status === 200) {
                setRows(response.data);
            }
            } catch (error) {
            console.error('Error fetching data:', error);
            }
    };
    const resetData = async () => {
        setRequest({
            id:0,
            公司:'福蒙',
            廠商:'',
            聯絡人:'尚未選擇',
            單號:'new',
            電話:'尚未選擇',
            傳真:'尚未選擇',
            日期:'',
            需求日期:'',
            採購類別:'外購',
            主管:'',
            核准:'',
            申請人:'',
            列印:'',
            備註:''
        })
    };
    // const fetchDrawNumData = async () => {         
    //     try {    
    //         const response = await axios.get(`${apiUrl}/PurchasePrice/ItemDetail?num=${drawNum}`);
    //         if (response.status === 200) {
    //             // 使用展开运算符将新的对象添加到前一个状态数组的末尾
    //             setBillRows(prevBillRows => [...prevBillRows, response.data]);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    // const handleDelete = (id) => {
    //     const newData = billRows.filter((row) => row.id !== id);
    //     setBillRows(newData);
    // };

    const handleAddRowsDelete = (id) => {
        const newData = addBillRows.filter((row) => row.id !== id);
        setAddBillRows(newData);
    };
    
    const fetchOptions = async () => {
        try {    
          const response = await axios.get(`${apiUrl}/Manufacturer/list`);
          if (response.status === 200) {
            setOptions(response.data.manufacturers);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };
    const handleChange = (event, newValue) => {
        setSelectedOption(newValue);
        if(newValue === null || newValue === undefined) return;
        setRequest((prevRequest) => ({   
            ...prevRequest,
            廠商: newValue.manufacturer_AbbreviationName || "",
            聯絡人: newValue.manufacturer_ContactName || "",
            電話: newValue.manufacturer_CompanyPhone || "",
            傳真: newValue.manufacturer_CompanyFax || "",
        })); 
    };

    const handleEdit = async () => {;
        const updatedRows = [...editedRows];
        const editedIds = new Set(editedRows.map(row => row.id));

        billRows.forEach(row => {
            if (!editedIds.has(row.id)) {
                updatedRows.push(row);
            }
        });
        request.Bills =  updatedRows;

        try {
            // 假設 apiUrl 已經包含了基礎URL，這裡只需要添加特定端點
            const response = await axios.post(`${apiUrl}/PurchasePrice/EditBuyBill`, request);
    
            if(response.data.length === 0) {
                alert('找不到資料');
                return;
            }
            if(response.data[0].類別 !== '外購' && response.data[0].類別 !== '合約' && response.data[0].類別 !== '託外') {
                setOther(response.data[0].類別);
            }
            if (response.status === 200) {
                setRequest({
                    ...request,
                    公司:response.data[0].公司,
                    廠商:response.data[0].廠商, 
                    單號:response.data[0].單號,
                    日期:response.data[0].日期.split("T")[0],
                    需求日期:response.data[0].需求日.split("T")[0],
                    採購類別:response.data[0].類別,
                    申請人:response.data[0].申請人,
                    主管:response.data[0].主管,
                    核准:response.data[0].核准,
                    備註:response.data[0].備註,
                    列印:response.data[0].列印
                })
                const selectedOption = options.find(option => option.manufacturer_AbbreviationName === response.data[0].廠商);
                setSelectedOption(selectedOption)
                setBillRows(response.data)
                setEditedRows([]);
                handleClose();
            }
        } catch (error) {
            console.error('Error editing data:', error);
        }
    };


    const handleRequestInputChange = (value, fieldName) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            [fieldName]: value.target ? value.target.value : value, // conditional check for value
        }));   
    };

    const processAddRowUpdate = (newRow, oldRow) => {
        const index = addEditBillRows.findIndex(row => row.id === newRow.id);
      
        if (index > -1) {
            addEditBillRows.splice(index, 1);
        }
      
        if (oldRow && newRow.數量 !== oldRow.數量) {
          const totalAmount = newRow.單價 * newRow.數量; // 假设您有'單價'字段
      
          newRow = {
            ...newRow,
            金額:  Math.round(totalAmount), 
          };  
        }
      
        setAddEditBillRows([...addEditBillRows, newRow]);
        return newRow;
    };


    const processRowUpdate = (newRow, oldRow) => {
        const index = editedRows.findIndex(row => row.id === newRow.id);
      
        if (index > -1) {
          editedRows.splice(index, 1);
        }
      
        if (oldRow && newRow.數量 !== oldRow.數量) {
          const totalAmount = newRow.單價 * newRow.數量; // 假设您有'單價'字段
      
          newRow = {
            ...newRow,
            金額:  Math.round(totalAmount), 
          };  
        }
      
        setEditedRows([...editedRows, newRow]);
        return newRow;
    };

    const calculateTotalAmount = () => {
        if(editedRows.length !== 0) {
            const total = editedRows.reduce((accumulator, currentRow) => {
                return accumulator + (currentRow.金額 || 0); // 如果'金額'不存在，默认为0
              }, 0);
            
              setTotalMoney(total);
        }
        else {
            const total = billRows.reduce((accumulator, currentRow) => {
                return accumulator + (currentRow.金額 || 0); // 如果'金額'不存在，默认为0
              }, 0);                      
              setTotalMoney(Math.round(total));
        }
    };
      

    const handleSave = async () => {
        const modifiedRows = editedRows.map(item => ({
          ...item,
        }));
    };

    const handleAddSave = async () => {
        const modifiedRows = addEditBillRows.map(item => ({
          ...item,
        }));
    };

    const handleDetailChange = (event) => {
        const selectedItem = event.target.value;
        setItem(selectedItem);
        setAddBillRows(prevAddBillRows => [...prevAddBillRows, selectedItem]);
        // setAddEditBillRows(prevAddEditBillRows => [...prevAddEditBillRows, selectedItem]);
    };

    useEffect(() => {
        calculateTotalAmount();
    }, [editedRows]); 


    useEffect(() => {
        fetchOptions();
    }, []); 

    useEffect(() => {
        if(selectedOption !== null && selectedOption !== undefined) {
            setRequest((prevRequest) => ({   
                ...prevRequest,
                廠商: selectedOption.manufacturer_AbbreviationName || "",
                聯絡人: selectedOption.manufacturer_ContactName || "",
                電話: selectedOption.manufacturer_CompanyPhone || "",
                傳真: selectedOption.manufacturer_CompanyFax || "",
            })); 
        }
    }, [selectedOption]);

    return (
        <Box sx={{ width: '100%', height: 1000, overflow: 'hidden' }}>
            <Grid container spacing={2} style={{ height: '100%' }}>
                {/* 第一個 DataGrid */}
                <Grid item xs={3} style={{ height: '100%', display: 'flex' }}>                   
                    <div style={{ flexGrow: 1, height: '100%', width: '100%' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                <DatePicker
                                label="月份選擇"
                                value={selectMonth}
                                onChange={(newValue) => setSelectMonth(newValue)}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={options}
                            sx={{ width: '100%' }}
                            value={selectedOption}
                            onChange={handleChange}
                            getOptionLabel={(option) => option.manufacturer_Seq+ option.manufacturer_AbbreviationName}
                            // 設置renderInput來渲染輸入元件
                            renderInput={(params) => <TextField {...params} label="廠商" />}
                        />
                        <Button variant="contained" style={{marginTop:'20px'}} onClick={fetchData}>搜尋</Button>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[5, 10, 20]}
                            onCellClick={handleCellClick}
                            // 根據您的需要添加其他 props
                        />
                    </div>
                </Grid>

                {/* 第二個 DataGrid */}
                <Grid item xs={9} style={{ height: '100%', display: 'flex' }}>
                    <div style={{ flexGrow: 1, height: '100%', width: '100%' }}>
                        <Box sx={{ flexGrow: 1,marginTop:'30PX' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">採購公司</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={request.公司}
                                        label="Age"
                                        onChange={(e) => handleRequestInputChange(e.target.value, '公司')}
                                        >
                                        <MenuItem value='福蒙'>福蒙</MenuItem>
                                        <MenuItem value='華辰'>華辰</MenuItem>
                                        <MenuItem value='FIRM'>FIRM</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">採購類別</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={request.採購類別}
                                        label="Age"
                                        onChange={(e) => handleRequestInputChange(e.target.value, '採購類別')}
                                        >
                                        <MenuItem value='外購'>外購</MenuItem>
                                        <MenuItem value='合約'>合約</MenuItem>
                                        <MenuItem value='託外'>託外</MenuItem>
                                        <MenuItem value={other}>{other}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={options}
                                        value={selectedOption}
                                        sx={{ width: '100%' }}
                                        onChange={handleChange}
                                        getOptionLabel={(option) => option.manufacturer_Seq+ option.manufacturer_AbbreviationName}
                                        // 設置renderInput來渲染輸入元件
                                        renderInput={(params) => <TextField {...params} label="廠商" />}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="聯絡人" variant="outlined" value={request.聯絡人}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="電話" variant="outlined" value={request.電話}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="傳真" variant="outlined" value={request.傳真}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="單號" variant="outlined" value={request.單號}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="採購日期" variant="outlined" value={request.日期}
                                    onChange={(e) => handleRequestInputChange(e.target.value, '日期')}
                                    placeholder='yyyy-mm-dd'/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="需求日" variant="outlined" value={request.需求日期}
                                    onChange={(e) => handleRequestInputChange(e.target.value, '需求日期')}
                                    placeholder='yyyy-mm-dd'/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="採購擔當" variant="outlined" value={request.申請人}
                                    onChange={(e) => handleRequestInputChange(e.target.value, '申請人')}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="採購主管" variant="outlined" value={request.主管}
                                    onChange={(e) => handleRequestInputChange(e.target.value, '採購主管')}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="核准" variant="outlined" value={request.核准}
                                    onChange={(e) => handleRequestInputChange(e.target.value, '核准')}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="列印" variant="outlined" value={request.列印}
                                    onChange={(e) => handleRequestInputChange(e.target.value, '列印')}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="備註" variant="outlined" value={request.備註}
                                    onChange={(e) => handleRequestInputChange(e.target.value, '備註')}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="standard-basic" label="合計" variant="outlined" value={totalMoney}                                
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button size="small" onClick={resetData}>清空</Button>
                                    <Button size="small" onClick={handleEdit}>修改採購</Button>
                                    <Button size="small" onClick={handleDetailsClickOpen}>新增採購</Button>
                                    <Button size="small" onClick={handleClickOpen}>搜尋</Button>
                                    <Button size="small" onClick={handlePrintClick}>列印</Button>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <TextField id="outlined-basic" label="圖號" variant="outlined" value={drawNum} onChange={(e)=>setDrawNum(e.target.value)}/>
                                </Grid> */}
                                <Dialog                                  
                                    open={detailsOpen}
                                    style={{height:'100%'}}
                                    onClose={handleDetailsClickClose}
                                    TransitionComponent={Transition}
                                >
                                    <DialogTitle>新增採購</DialogTitle>
                                    <DialogContent>
                                    請將料品先選擇、刪除完畢後，再修改數量、批號，不然數量會被清空。<br/>
                                    請注意操作流程。
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        style={{marginBottom :'20px'}}
                                        id="name"
                                        label="圖號"
                                        type="text" // 如果是輸入單號，應該使用 type="text" 而不是 type="email"
                                        value={drawNum}
                                        onChange={(e) => setDrawNum(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleDetailsOptionsClick(drawNum);
                                                e.preventDefault(); // 防止表單提交
                                            }
                                        }}
                                        fullWidth
                                        variant="standard"
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">料品選擇</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={item}
                                            label="Age"
                                            onChange={handleDetailChange}
                                        >
                                        {detailOptions.map((item) => (
                                            <MenuItem key={item.id} value={item}>
                                                圖號:{item.圖號}  物品名:{item.物品名}  單位:{item.單位} 單價:{item.單價}
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    <DataGrid
                                        rows={addBillRows}
                                        columns={addBillcolumns}
                                        pageSize={10}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        processRowUpdate={processAddRowUpdate}
                                        onProcessRowUpdateError={error=>alert(error)}
                                        onRowEditCommit={(rowId, e) => {
                                            handleAddSave(rowId,e);
                                        }}
                                    />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleDetailsClickClose}>取消</Button>
                                        <Button onClick={handleSubmit}>新增採購</Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                     fullScreen
                                     open={pdfOpen}
                                     onClose={handlePdfClickClose}
                                     TransitionComponent={Transition}
                                        >
                                        <AppBar sx={{ position: 'relative' }}>
                                        <Toolbar>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={handlePdfClickClose}
                                            aria-label="close"
                                            >
                                            <CloseIcon />
                                        </IconButton>
                                         <Button autoFocus color="inherit" onClick={handlePdfClickClose}>
                                            列印預覽
                                            </Button>
                                            <Button autoFocus color="inherit" onClick={handleAfterPrintPdfClickClose}>
                                            已列印後按此紐關閉
                                            </Button>
                                        </Toolbar>
                                    </AppBar>
                                    {pdfBlobUrl && <iframe src={pdfBlobUrl} title="PDF Document" style={{ width: '100%', height: '100%' }} />}
                                </Dialog>
                            </Grid>
                        </Box>
                        <DataGrid
                            rows={billRows}
                            columns={billcolumns}
                            pageSize={10}
                            rowsPerPageOptions={[5, 10, 20]}
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={error=>alert(error)}
                            onRowEditCommit={(rowId, e) => {
                                handleSave(rowId,e);
                            }}
                            // 根據您的需要添加其他 props
                        />
                    </div>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>請輸入查詢單號</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="單號"
                    type="email"
                    value={orderNum}
                    onChange={(e) => setOrderNum(e.target.value)}
                    fullWidth
                    variant="standard"
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>取消</Button>
                <Button onClick={handleSearchClick}>搜尋</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

Font.register({
    family: 'TaipeiSansTCBeta-Bold',
    src: 'path/to/TaipeiSansTCBeta-Bold.ttf', // 替换为您的实际字体文件路径
  });
  Font.register({
    family: 'TaipeiSansTCBeta-Light',
    src: 'path/to/TaipeiSansTCBeta-Light.ttf', // 替换为您的实际字体文件路径
  });
  // 页面宽高转换为点（1cm = 28.28点）
  const pageWidth = 24.1 * 72 / 2.54;
  const pageHeight = 14 * 72 / 2.54;
  
  // 表格宽度比例，根据您的表格设计调整
  const cellWidth = pageWidth / 6; // 假设有8列
  

  const BOMstyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 24,
        width: pageWidth,
        height: pageHeight,
    },
    onePage: {
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: 24,
        width: pageWidth,
        height: pageHeight,
    },
    title: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: 'TaipeiSansTCBeta-Bold',
        marginBottom: 10,
    },
    tableContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        fontFamily: 'TaipeiSansTCBeta-Bold', // 新增此行以确保容器内的内容（标题）居中
        width: '100%', // 确保容器宽度充满整个页面或其父容器
    },  
    headerRow: {
      flexDirection: 'row',
      width: '100%', // 确保行宽充满整个页面
      textAlign: 'center', 
      fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
      marginTop: 5, // 根据需要调整，为标题和标头之间提供一些空间
    },
    headerBottomRow: {
        flexDirection: 'row',
        width: '100%', // 确保行宽充满整个页面
        textAlign: 'center', 
        fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
        marginTop: 100, // 根据需要调整，为标题和标头之间提供一些空间
      },
    headerRowDash: {
      flexDirection: 'row',
      width: '100%', // 确保行宽充满整个页面
      textAlign: 'center', 
      fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
      marginTop: 10, // 标题和标头之间的空间
      borderTop: 1, // 添加上边框
      borderBottom: 1, // 添加下边框
      borderColor: 'black', // 边框颜色
      paddingTop: 5, // 标头内容与上边框之间的空间
      paddingBottom: 5, // 标头内容与下边框之间的空间
    },
    headerItem: {
        flex: 1, // 平分容器宽度
        textAlign: 'center', // 文本居中
        fontFamily: 'TaipeiSansTCBeta-Light',
        fontSize: 10,
    },
    header子件料號: {
      width: '15%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header品名: {
      width: '5%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header規格: {
      width: '25%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header配賦量: {
      width: '5%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header單位: {
      width: '10%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header需求量: {
      width: '10%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header實領: {
      width: '10%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
    header備註: {
      width: '20%',
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
    },
});
  const currentDate = new Date();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份需要加1，因为它从0开始
  const currentDay = String(currentDate.getDate()).padStart(2, '0');
  const dateString = `${currentDate.getFullYear()}/${currentMonth}/${currentDay}`;
  // 创建文档组件
  const MyBomDocument = ({ data,com,client,day,orderNum }) => ( 

    <Document>
        <Page size={{ width: pageWidth, height: pageHeight }} style={BOMstyles.page}>
            <View style={BOMstyles.tableContainer}>
                <Text style={BOMstyles.title}>{com} 請採購驗收單</Text>
            </View>
            <View style={BOMstyles.headerRow}>
                <Text style={BOMstyles.headerItem}>{client.manufacturer_AbbreviationName}</Text>
                <Text style={BOMstyles.headerItem}>月份選擇:{day}</Text>
                <Text style={BOMstyles.headerItem}>{orderNum}</Text>
                <Text style={BOMstyles.headerItem}>日期:{dateString}</Text>
            </View>
            {data.map((item, index) => (
                <>
                    <View style={BOMstyles.headerRow} key={index}>
                        <Text style={BOMstyles.headerItem}>{item.圖號}</Text>
                        <Text style={BOMstyles.headerItem}>{item.物品名}</Text>
                        <Text style={BOMstyles.headerItem}>{item.單位}</Text>
                        <Text style={BOMstyles.headerItem}>{item.數量}</Text> 
                        <Text style={BOMstyles.headerItem}>{item.單價}</Text>
                    </View>
                    <View style={BOMstyles.headerRow} key={index}>
                        <Text style={BOMstyles.headerItem}>批號:{item.批號}</Text>
                        <Text style={BOMstyles.headerItem}/>
                        <Text style={BOMstyles.headerItem}/>
                        <Text style={BOMstyles.headerItem}/>
                        <Text style={BOMstyles.headerItem}>{item.金額}</Text>
                    </View>
            </>            
            ))}
            <View style={BOMstyles.headerRow}>
                <Text style={BOMstyles.headerItem}>== 以下空白 ==</Text>
                <Text style={BOMstyles.headerItem}/>
                <Text style={BOMstyles.headerItem}/>
                <Text style={BOMstyles.headerItem}/>
                <Text style={BOMstyles.headerItem}/>
            </View>
            <View style={BOMstyles.headerBottomRow}>
                <Text style={BOMstyles.headerItem}>{data[0].申請人}</Text>
                <Text style={BOMstyles.headerItem}>{data[0].主管}</Text>
                <Text style={BOMstyles.headerItem}>{data[0].核准}</Text>
                <Text style={BOMstyles.headerItem}>劉芳玉</Text>
                <Text style={BOMstyles.headerItem}>黃淑媛{dateString}</Text>
                <Text style={BOMstyles.headerItem}>金額總和:{ data.reduce((sum, item) => sum + Number(item.金額), 0)}</Text>
            </View>
            <View style={BOMstyles.headerRow}>
                <Text style={BOMstyles.headerItem}>*產品需符合ROSH*</Text>
                <Text style={BOMstyles.headerItem}/>
                <Text style={BOMstyles.headerItem}/>
                <Text style={BOMstyles.headerItem}/>
                <Text style={BOMstyles.headerItem}/>
            </View>
        </Page>
    </Document>
  );


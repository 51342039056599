import React, { useState } from 'react';
import { GlobalContext } from '../globalContext/GlobalContext';

export const GlobalProvider = ({ children }) => {
    const [globalVar, setGlobalVar] = useState('');

    return (
        <GlobalContext.Provider value={[globalVar, setGlobalVar]}>
            {children}
        </GlobalContext.Provider>
    );
};

import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { apiUrl } from '../../apiUrl/ApiUrl';



const kindArray = ['空白','盤點','調帳','生產領用','採購','入庫','出貨扣帳']






export default function MatCard({data}) {
    const [newData,setNewData] = useState(null);
    const [selected, setSelected] = useState('');
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('生管資材卡明細表');

    const [month, setMonth] = useState("");
    const [kind, setKind] = useState("");

    const [page,setPage] = useState(1);
    console.log(data)
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
    }

    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                    {/* <ChooseModel setModel={setModel} setNewData={setNewData} setSelected={setSelected}/> */}
                </div>
                <div className='col-5'>
                    {/* {model === '主表新增' ? <InsertMainForm setNewData={setNewData}/> : null}  */}
                    {/* {model === '副表新增' ? <InsertDetailForm setDetailData={setDetailData}/> : null} 
                    {model === '主表搜尋' ? <SelectMainForm setNewData={setNewData} 
                    setDetailData={setDetailData} 
                    page={page} 
                    setPage={setPage}/> : null}  
                    {model === '副表修改' ? <EditDetailForm data={editItem} setDetailData={setDetailData}/> : null} */}
                    {/* {model === '修改模式'?<EditForm model={model} editItem={editItem} setNewData={setNewData}/> :<OperateForm setNewData={setNewData} model={model}/>} */}
                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {model === '生管資材卡明細表'?<h2><span className="badge bg-dark">搜尋條件</span></h2>:null}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {model === '生管資材卡明細表'?<SelectFilter setNewData={setNewData} 
                    setMonth={setMonth} 
                    setKind={setKind} 
                    month={month}
                    kind={kind}/> : null}
                    {/* {model === '訂單輸入'?<CreateForm/> : null} */}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">生管資材卡明細表</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null && model === '生管資材卡明細表'?<ListTable setDetailData={setDetailData} 
                    setNewData={setNewData} data={newData || data} 
                    setModel={setModelandGetDate} page={page} 
                    setPage={setPage}
                    kind={kind}
                    month={month}/> :<div>目前還沒取得資料或取不到資料</div>}
                </div>              
            </div>
        </div>     
    )
}

function ListTable({data,setNewData,setModel,setDetailData,page,setPage,month,kind}) {
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }


    const nextPage = () => {
        if(month !== '' && kind === '')
        {
            axios.get(`${apiUrl}/MatCard/GetMains?month=${month}&page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        }else if(month === '' && kind !== ''){
            axios.get(`${apiUrl}/MatCard/GetMains?kind=${kind}&page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        }else{
            axios.get(`${apiUrl}/MatCard/GetMains?page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        }

    }

    const prePage = () => {
        if(month !== '' && kind === '')
        {
            axios.get(`${apiUrl}/MatCard/GetMains?month=${month}&page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        }else if(month === ''  && kind !== ''){
            axios.get(`${apiUrl}/MatCard/GetMains?kind=${kind}&page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        }else{
            axios.get(`${apiUrl}/MatCard/GetMains?page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        }
    }


    // const GetDetails = (toShipNum) => {
    //     axios.get(`${apiUrl}/ToShip/GetMainAndDetails?toShipNum=${toShipNum}`)
    //     .then(response => {
    //         if(response.data.statusCode === 200) {
    //             setDetailData(response.data)
    //         }else{
    //             alert('找不到資料');
    //         }            
    //     })
    //     .catch(error =>{
    //         // handle error
    //         console.log(error);
    //     })
    // }

    return(
        <>
            <div style={{overflowX: "auto", height: "70vh"}}>
                <table className="table custom-table table-striped" style={{width: "3000px"}}>
                    <thead className="sticky-header">
                    <tr>
                        <th scope="col" style={thStyle.centered}>#</th>
                        <th scope="col" style={thStyle.centered}>圖號</th>
                        <th scope="col" style={thStyle.centered}>申請日期</th>
                        <th scope="col" style={thStyle.centered}>日期</th>
                        <th scope="col" style={thStyle.centered}>類別</th>
                        <th scope="col" style={thStyle.centered}>單號</th>
                        <th scope="col" style={thStyle.centered}>批號</th>
                        <th scope="col" style={thStyle.centered}>入量</th>
                        <th scope="col" style={thStyle.centered}>出量</th>
                        <th scope="col" style={thStyle.centered}>庫存量</th>
                        <th scope="col" style={thStyle.centered}>核准</th>
                        <th scope="col" style={thStyle.centered}>領用</th>
                    </tr>
                </thead>
                <tbody>
                    {data.matCards.map((item, index) => (
                        <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>{index + 1}</th>
                            <td style={thStyle.centered}>{item.drawNum}</td>
                            <td style={thStyle.centered}>{item.applyDate}</td>
                            <td style={thStyle.centered}>{item.date}</td>  
                            <td style={thStyle.centered}>{item.kind}</td>  
                            <td style={thStyle.centered}>{item.orderNum}</td>  
                            <td style={thStyle.centered}>{item.piNum}</td>  
                            <td style={thStyle.centered}>{item.inAmount}</td>  
                            <td style={thStyle.centered}>{item.outAmount}</td>  
                            <td style={thStyle.centered}>{item.stock}</td>  
                            <td style={thStyle.centered}>{item.approve}</td>  
                            <td style={thStyle.centered}>{item.receive}</td>           
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <div style={{textAlign: 'center'}} className='mt-3'>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                    <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
            </div>
        </>
    )
}

function SelectFilter({setNewData,month,kind,setMonth,setKind}) {

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Replace with your actual endpoint
        const page = 1;
        const _apiUrl = `${apiUrl}/MatCard/GetMains`;
        const params = {
            month,
            kind,
            page
        };
        console.log(params)
        const response = await axios.get(_apiUrl, { params });
        setNewData(response.data)
    };
    return(
        <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row mb-3'>
                <div className='col-6'>
                    <label htmlFor="inputText1">日期
                    <input type="text" placeholder='yyyy/mm/dd' className="form-control" id="inputText1" value={month} onChange={(e) => setMonth(e.target.value)}/>
                    </label>
                </div>
                <div className='col-3'>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">類別</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={kind}
                        label="Age"
                        onChange={(e)=>setKind(e.target.value)}
                    >
                        {kindArray.map((item, index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                  ))}
                    </Select>
                </FormControl>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='col-12'>
                    <button type="submit" className="btn btn-dark">提交</button>
                </div>
            </div>
        </div>      
  </form>
    )
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../components/apiUrl/ApiUrl';
import Tabs from '../components/erp/tabs/Tab'
import WorkPlace from '../components/erp/workPlace/WorkPlace';
import Machine from '../components/erp/machine/Machine';
import Engineer from '../components/erp/engineer/Engineer';
import MatCoefficient from '../components/erp/matCoefficient/MatCoefficient';
import PurchasePrice from '../components/erp/purchasePrice/PurchasePrice';
import SellPrice from '../components/erp/sellPrice/SellPrice';
import Bom from '../components/erp/bom/Bom';
import MatSet from '../components/erp/matSet/MatSet';
import ComapnyBasicSet from '../components/erp/comapnyBasicSet/ComapnyBasicSet';

export default function BasicSetUp () {
    const tabArray = ['料品資料設定','BOM表','售價一覽表','進價一覽表','材質系數','工程名稱','機台設定','職場資料設定','廠商基本資料']
    const [activeTab, setActiveTab] = useState(0);
    const [dataMachine, setMachineData] = useState(null);
    const [dataWorkPlace, setWorkPlaceData] = useState(null);
    const [dataEngineer, setdataEngineerData] = useState(null);
    const [dataMatCoefficient,setMatCoefficientData] = useState(null);
    const [dataPurchasePriceData,setPurchasePriceData] = useState(null);
    const [dataSellPrice,setSellPriceData] = useState(null);
    const [dataBom,setDataBom] = useState(null);
    const [dataMatSet,setDataMatSet] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      const token = sessionStorage.getItem('jwtData');
      if (!token) {
        navigate('/login');
      }
    }, [navigate]);
      
    useEffect(() => {
        const fetchData = async () => {
            let url;
            switch (activeTab) {
                case 0:
                    url = `${apiUrl}/MatSet/GetMains`;
                    break;
                case 1:
                    url = `${apiUrl}/Bom/GetMain`;
                    break;
                case 2:
                    url = `${apiUrl}/SellPrice/list`;
                    break;
                case 3:
                    url = `${apiUrl}/PurchasePrice/MainList`;
                    break;
                case 4:
                    url = `${apiUrl}/MatCoefficient/list`;
                    break;
                case 5:
                    url = `${apiUrl}/Engin/list`;
                    break;
                case 6:
                    url = `${apiUrl}/Machine/list`;   
                    break;
                case 7:
                    url = `${apiUrl}/WorkPlace/list`;
                    break;
                default:
                    setMachineData(null);
                    setWorkPlaceData(null);
                    setdataEngineerData(null);
                    setMatCoefficientData(null);
                    setPurchasePriceData(null);
                    setSellPriceData(null);
                    setDataBom(null);
                    setDataMatSet(null);
                    return;
            }
            try {
                const result = await axios.get(url);
                if (url === `${apiUrl}/Machine/list`)
                {
                    setMachineData(result.data);
                }else if (url === `${apiUrl}/WorkPlace/list`)
                {
                    setWorkPlaceData(result.data);
                }else if (url === `${apiUrl}/Engin/list`)
                {
                    setdataEngineerData(result.data);
                }else if (url === `${apiUrl}/MatCoefficient/list`)
                {
                    setMatCoefficientData(result.data);
                }else if (url === `${apiUrl}/PurchasePrice/MainList`)
                {
                    setPurchasePriceData(result.data);
                }else if (url === `${apiUrl}/SellPrice/list`)
                {
                    setSellPriceData(result.data);
                }else if (url === `${apiUrl}/Bom/GetMain`)
                {
                    setDataBom(result.data);
                }else if (url === `${apiUrl}/MatSet/GetMains`)
                {
                    setDataMatSet(result.data);
                }
                
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [activeTab]);  // ${apiUrl}/PurchasePrice/MainList
    const renderTabContent = (tabIndex) => {
            switch (tabIndex) {
                case 0:
                    return <MatSet data={dataMatSet}/>;
                case 1:
                    return <Bom data={dataBom}/>;
                case 2:
                    return <SellPrice data={dataSellPrice}/>
                case 3:
                    return <PurchasePrice data={dataPurchasePriceData}/>;
                case 4:
                    return  <MatCoefficient data={dataMatCoefficient}/>;
                case 5:
                    return <Engineer data={dataEngineer}/>;
                case 6:
                    return <Machine data={dataMachine}/>;
                case 7:
                    return <WorkPlace data={dataWorkPlace}/>;
                case 8:
                    return <ComapnyBasicSet/>;
                default:
                    return null;
            }
    };
    return(
        <div>
            <Tabs tabsNames={tabArray} activeTab={activeTab} setActiveTab={setActiveTab}/>
            {renderTabContent(activeTab)}
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Tabs from '../components/erp/tabs/Tab'
import Buyer from '../components/erp/buyer/Buyer';
import BuyerFilter from '../components/erp/buyerFilter/BuyerFilter'
import BuyWithNull from '../components/erp/buyWithNull/BuyWithNull';
import BuyWithToday from '../components/erp/buyWithToday/BuyWithToday';
import BuyWithInStock from '../components/erp/buyWithInStock/BuyWithInStock';
import BuyBill from '../components/erp/buyBill/BuyBill';
import { apiUrl } from '../components/apiUrl/ApiUrl';


export default function PurchaseManager () {
    const tabArray = ['請採購簽核','簽核查詢','未簽核查詢','列印請採購','請採購單','請採購預定入庫表'] // '發動生產','補印製令','生產排程'
    const [activeTab, setActiveTab] = useState(0);


    const [dataBuyer,setBuyerData] = useState(null);
    const [dataBuyerFilter,setBuyerFilterData] = useState(null);
    const [dataBuyNull,setBuyNullData] = useState(null);
    const [dataBuyToday,setBuyTodayData] = useState(null);
    const [dataBuyInStock,setBuyInStockData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      const token = sessionStorage.getItem('jwtData');
      if (!token) {
        navigate('/login');
      }
    }, [navigate]);
    useEffect(() => {
        const fetchData = async () => {
            let url;
            switch (activeTab) {
                case 0:
                    url = `${apiUrl}/Buyer/GetMains?page=1`;
                    break;
                case 1:
                    url = `${apiUrl}/Buyer/GetMainsByFilter?page=1`;
                    break;
                case 2:
                    url = `${apiUrl}/Buyer/GetMainsWithNull?page=1`;
                    break;
                case 3:
                    url = `${apiUrl}/Buyer/GetMainsWithToday`;
                    break;
                case 4:
                    url = `${apiUrl}/Buyer/GetMainsWithNull?page=1`;
                    break;
                case 5:
                    url = `${apiUrl}/Buyer/GetMainsWithInStock`;
                    break;
                default:
                    return;
            }
            try {
                const result = await axios.get(url);
                if (url === `${apiUrl}/Buyer/GetMains?page=1`)
                {
                    setBuyerData(result.data);
                }else if (url === `${apiUrl}/Buyer/GetMainsByFilter?page=1`)
                {
                    setBuyerFilterData(result.data)
                }else if (url === `${apiUrl}/Buyer/GetMainsWithNull?page=1`)
                {
                    setBuyNullData(result.data);
                }
                else if (url === `${apiUrl}/Buyer/GetMainsWithToday`)
                {
                    setBuyTodayData(result.data);
                }
                else if (url === `${apiUrl}/Buyer/GetMainsWithInStock`)
                {
                    setBuyInStockData(result.data);
                }
                
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [activeTab]);  // ${apiUrl}/PurchasePrice/MainList
    const renderTabContent = (tabIndex) => {
            switch (tabIndex) {
                case 0:
                    return <Buyer data={dataBuyer}/>;
                case 1:
                    return <BuyerFilter data={dataBuyerFilter}/>;
                case 2:
                    return <BuyWithNull data={dataBuyNull}/>
                case 3:
                    return <BuyWithToday data={dataBuyToday}/>;
                case 4:
                    return <BuyBill/>;
                case 5:
                    return <BuyWithInStock data={dataBuyInStock}/>;
                default:
                    return null;
            }
    };
    return(
        <div>
            <Tabs tabsNames={tabArray} activeTab={activeTab} setActiveTab={setActiveTab}/>
            {renderTabContent(activeTab)}
        </div>
    )
}
// axiosConfig.js
import axios from 'axios';

// 設置全局的header配置
axios.interceptors.request.use(
  (config) => {
    // 從localStorage獲取Id
    const id = sessionStorage.getItem('Id');
    if (id) {
      // 將Id添加到header中
      config.headers.Id = id;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
    (response) => {
      // 如果回應成功，直接返回
      return response;
    },
    (error) => {
      // 如果回應失敗，且狀態碼為401，彈出提示
      if (error.response && error.response.status === 401) {
        alert('權限不足');
      }
      return Promise.reject(error);
    }
  );
  
export default axios;

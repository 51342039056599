import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { apiUrl } from '../../apiUrl/ApiUrl';


export default function MatCoefficient({data}) {
    const [newData,setNewData] = useState(null);
    const [model,setModel] = useState('搜尋模式');
    const [editItem,setEditItem] = useState('')
    const workMargin = {
        marginTop: '5%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
            axios.get(`${apiUrl}/MatCoefficient/list`)
            .then(response => {
                if(response.data.statusCode === 200) {    
                    setNewData(response.data);  
                }else {
                    alert('失敗，請檢查網路問題')
                }
            })
            .catch(error => {
                // handle error
                alert(error);
            });
    }

    return(
        <div className='container-fluid'>
            <div className='row' style={workMargin}>
                <div className='col-1'>
                    <ChooseModel setModel={setModelandGetDate}/>
                </div>
                <div className='col-2'/>
                <div className='col-7'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                    <OperateForm setNewData={setNewData} model={model}/>
                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null?<ListTable setNewData={setNewData} data={newData || data} setModel={setModelandGetDate}/> :null}
                </div>              
            </div>
        </div>
        
    )
}

function ListTable({data, setNewData, setModel}) {
    console.log(data)
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 50;
    const pagesVisited = pageNumber * usersPerPage;

    const handleDelete = (id) => {
        axios.get(`${apiUrl}/MatCoefficient/DeleteMain?id=${id}`)
        .then(response => {
            // handle success
            if(response.data === 200) {
                axios.get(`${apiUrl}/MatCoefficient/list`)
                    .then(response => {
                        // Update the data
                        setNewData(response.data);
                        alert('刪除成功')
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    });
            }else {
                alert('刪除失敗，找不到可以刪除的機台名稱')
            }
        })
        .catch(error => {
            // handle error
            alert(error);
        });
    }

    const displayList = data.matCoefficientsList
    .slice(pagesVisited, pagesVisited + usersPerPage)
    .map((matCoefficient, index) => (
        <tr key={uuidv4()}>
            <th scope="row">{index + 1}</th>
            <td>{matCoefficient.mat}</td>
            <td>{matCoefficient.thickness}</td>
            <td>{matCoefficient.coefficient}</td>
            <td>
                <button type="button" className="btn btn-danger" onClick={() => handleDelete(matCoefficient.id)}>刪除</button>
            </td>
        </tr>
    ));
    const searchItem = data.matCoefficientsList
    .map((matCoefficient, index) => (
        <tr key={uuidv4()}>
            <th scope="row">{index + 1}</th>
            <td>{matCoefficient.mat}</td>
            <td>{matCoefficient.thickness}</td>
            <td>{matCoefficient.coefficient}</td>
            <td>
                <button type="button" className="btn btn-danger" onClick={() => handleDelete(matCoefficient.id)}>刪除</button>
            </td>
        </tr>
    ));

    const pageCount = Math.ceil(data.matCoefficientsList.length / usersPerPage);

    return(
            <div style={{overflowX: "auto", height: "70vh"}}>
                <table className="table" style={{width: "100%"}}>
                    <thead className="sticky-header">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">材質</th>
                        <th scope="col">板厚</th>
                        <th scope="col">系數</th>
                        <th scope="col">操作</th>
                    </tr>
                </thead>
                <tbody>
                    {data.matCoefficientsList.length < 10? searchItem : displayList}
                </tbody>
            </table>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button 
                    type='button'
                    onClick={() => setPageNumber(pageNumber > 0 ? pageNumber - 1 : pageNumber)}
                    disabled={pageNumber === 0}>
                    上一页
                </button>
                <span style={{ margin: '0 10px' }}> Page {pageNumber + 1} of {pageCount}</span>
                <button 
                    type='button'
                    onClick={() => setPageNumber(pageNumber < pageCount - 1 ? pageNumber + 1 : pageNumber)}
                    disabled={pageNumber === pageCount - 1}>
                    下一页
                </button>
            </div>
        </div>
    );
}


function OperateForm({setNewData,model}) {
    const [errorMessage, setErrorMessage] = useState('');
    const [errorNumMessage, setErrorNumMessage] = useState('');

    const handleSubmit = event => {

        let mat = event.target.elements.mat.value;
        mat = mat.trim();
        let thickness = event.target.elements.thickness.value;
        thickness = thickness.trim();
        if(mat !== ''&& thickness !== '')
        {
            event.preventDefault();           
        /* ${apiUrl}/MatCoefficient/CreateMain */
            if (model === '新增模式') {
                let coefficient = event.target.elements.coefficient.value;
                coefficient = coefficient.trim();
                const data = {
                    mat,
                    thickness,
                    coefficient
                };
                axios
                    .post(`${apiUrl}/MatCoefficient/CreateMain`, data)
                    .then(response => {
                        // handle success
                        if (response.data === 200) {
                            axios
                                .get(`${apiUrl}/MatCoefficient/list`)
                                .then(response => {
                                    // Update the data
                                    setNewData(response.data);
                                    alert('新增成功');
                                })
                                .catch(error => {
                                    // handle error
                                    console.log(error);
                                });
                        } else {
                            alert('新增失敗，已有相同的材質名稱');
                        }
                    })
                    .catch(error => {
                        // handle error
                        alert('發生未預期錯誤 請洽工程師');
                    });
        } else if (model === '搜尋模式') {
                console.log(mat)
                console.log(thickness)
                console.log('thickness')
            axios
                .get(`${apiUrl}/MatCoefficient/Get?matName=${mat}&thickness=${thickness}`) // ${engName}
                .then(response => {
                   console.log(response)
                    if (response.data.statusCode === 200) {
                        response.data.matCoefficientsList = [];
                        response.data.matCoefficientsList.push(response.data.matCoefficient)
                        setNewData(response.data);
                        alert('搜尋成功');
                    } else {
                        alert('搜尋失敗，找不到可以搜尋的工程名稱');
                    }
                })
                .catch(error => {
                    // handle error
                    alert(error);
                });
        }}
        else 
        {
            event.preventDefault();
            alert('請符合資料格式')
        }
    };
    
    const checkValue = event => {
        if (!event.target.value.trim()) {
            setErrorMessage('欄位不得為空白')
        }else{
            setErrorMessage('')
        }
    };

    const checkNumValue = event => {
        const value = event.target.value.trim();
        if (value === '') {
            setErrorNumMessage('欄位不得為空白');
        } else if (!/^\d+(\.\d+)?$/.test(value)) {
            setErrorNumMessage('請輸入有效的小數或是整數');
        } else {
            setErrorNumMessage('');
        }
    };

    const renderForm = () => {
        if (model === '新增模式') {
          return (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="mat" className="form-label">材質
                  <input type="text" className="form-control" id="mat" onBlur={checkValue} />
                  <div id="emailHelp" className="form-text">{errorMessage}</div>
                </label>
              </div>
              <div className="mb-3">
                <label htmlFor="thickness" className="form-label">板厚
                  <input type="text" className="form-control" id="thickness" onBlur={checkNumValue} />
                  <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                </label>
              </div>
              <div className="mb-3">
                <label htmlFor="coefficient" className="form-label">系數
                  <input type="text" className="form-control" id="coefficient" onBlur={checkNumValue} />
                  <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                </label>
              </div>
              <button type="submit" className="btn btn-dark">提交</button>
            </form>
          );
        }
    
        if (model === '搜尋模式') {
            return (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="mat" className="form-label">材質
                        <input type="text" className="form-control" id="mat" onBlur={checkValue} />
                        <div id="emailHelp" className="form-text">{errorMessage}</div>
                        </label>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="thickness" className="form-label">板厚
                        <input type="text" className="form-control" id="thickness" onBlur={checkNumValue} />
                        <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                        </label>
                    </div>
                  <button type="submit" className="btn btn-dark">提交</button>
                </form>
              );
        }
        return null;
      };

    return(
        <>
        {renderForm()}
        </>      
    )
}

function ChooseModel({setModel}) {
    const buttonStyle = {
        display: 'block',
        width: '100%',
        border: 'none',
        backgroundColor: '#6c757d',
        color: 'white', // set the text color
        textAlign: 'center',
        padding: '10px 0'
    };

    return(
        <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
                <button type="button" style={buttonStyle} onClick={() => setModel('新增模式')}>新增模式</button>
            </div>
            <div className="accordion-item">
                <button type="button" style={buttonStyle} onClick={() => setModel('搜尋模式')}>搜尋模式</button>
            </div>
        </div>
    )
}
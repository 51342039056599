import React, { useEffect, useState } from 'react';

import { Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Grid } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg } from '@react-pdf/renderer';
import { useGridApiRef,DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import axios from 'axios';
import JsBarcode from 'jsbarcode';
import chFont from '../../../css/font/TaipeiSansTCBeta-Bold.ttf'
import chLight from '../../../css/font/TaipeiSansTCBeta-Light.ttf'
import { apiUrl } from '../../apiUrl/ApiUrl';




const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


export default function Inspection({data}) {

    const [rows,setRows] = React.useState([]);
    const [print,setPrint] = React.useState(false);
    const [checkPrint,setCheckPrint] = React.useState(false);
    const [expectedrows,setExpectedrows] = React.useState([]);
    const [filter,setFilter] = React.useState('');
    const [filterValue,setFilterValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [sendRow,setSendRow] = React.useState(null);
    const [orderNum,setOrderNum] = React.useState('');
    const [maxAmount,setMaxAmount] = React.useState(0);
    const [printItem,setPrintItem] = React.useState(null);
    const [printItems,setPrintItems] = React.useState([]);
    const [baseOrder64,setBaseOrder64] = React.useState('');
    const [basePinum64,setBasePinum64] = React.useState('');
    const [piNumCounts, setPinumCounts] = React.useState([]);
    
    React.useEffect(() => {
        console.log('Component mounted, clearing data');
        // 进入页面时清空数据
        setExpectedrows([]);
        setPrintItem(null);
        setPrintItems([]);
    
        console.log('Expected Rows:', expectedrows); // 这可能不会显示更新后的值，因为setState是异步的
        console.log('Print Item:', printItem);
        console.log('Print Items:', printItems);
    }, []);
    
    React.useEffect(() => {
        console.log('Expected Rows:', expectedrows);
        console.log('Print Item:', printItem);
        console.log('Print Items:', printItems);
    }, [expectedrows, printItem, printItems]);

    React.useEffect(() => {
        return () => {
            // 在组件卸载时重置 expectedrows 和其他与 detailColumns 相关的状态
            setExpectedrows([]);
            setPrintItem(null);
            setPrintItems([]);
        };
    }, []); // 空数组表示仅在组件卸载时运行
    


    const countPiNUms = (items) => {
        // 使用 reduce 函數來計算每個 ID 的出現次數
        const flatItems = items.flat();

        // 計算每個 id 的出現次數
        const countBypiNum = flatItems.reduce((acc, item) => {
            acc[item.piNum] = (acc[item.piNum] || 0) + 1;
            return acc;
        }, {});
        
        // 將物件轉換成陣列形式
        const resultArray = Object.entries(countBypiNum).map(([piNum, count]) => ({
            piNum, // 將鍵值轉換回數字類型，因為物件的鍵在 JavaScript 中是字串形式
            count
        }));
        
        return resultArray;
    }

    const generateBarcodes = (value, setValue) => {
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, value, {
            format: 'CODE128',
            displayValue: false,
        });
        setValue(canvas.toDataURL('image/png'));
    };

    const handleDeleteReOrder = async(row) => {
        try {
            const response= await axios.post(`${apiUrl}/Inspection/DeleteReserveOrder`, row);
        
            if (response.data.statusCode === 200) {
              alert('成功');
              setExpectedrows(response.data.reservationOrders)
              setRows(response.data.orders)
              // 可以在这里添加其他成功的处理逻辑
              const updatedPrintItems = printItems.map(subArray =>
                subArray.filter(item => item.id !== row.id)
            );

            setPrintItems(updatedPrintItems);

            // 其他逻辑
            setExpectedrows(response.data.reservationOrders);
            setRows(response.data.orders);

            } else {
              alert('失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('失败');
          }
    };

    const SaveAll = async(rows) => {
        try {
            const response= await axios.post(`${apiUrl}/Inspection/SetInspection`, rows);
        
            if (response.data) {
              alert('成功');
              // 可以在这里添加其他成功的处理逻辑
            } else {
              alert('失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('失败');
          }
    };

    const handleClickOpen = (row) => {
        // console.log(row)
        // console.log(printItems)
        setPrintItem(row);
        if (printItems.length === 0 || !Array.isArray(printItems[printItems.length - 1])) {
            row.order64 = baseOrder64;
            row.piNum64 = basePinum64;
            setPrintItems([...printItems, [row]]);
            ;
        } else if (printItems[printItems.length - 1].length === 6) {
            row.order64 = baseOrder64;
            row.piNum64 = basePinum64;
            setPrintItems([...printItems, [row]]);
        } else {
            // 否则，在最后一个数组中添加row
            const newPrintItems = [...printItems];
            row.order64 = baseOrder64;
            row.piNum64 = basePinum64;
            newPrintItems[newPrintItems.length - 1] = [...newPrintItems[newPrintItems.length - 1], row];
            setPrintItems(newPrintItems);
        }
    };
    
    const handlePrintClickOpen = (row) => {   
        console.log(printItems); 
        setPrint(true);
    };
    
      const handleClose = () => {
        setPrint(false);
        setCheckPrint(true)
    };
    const handleDialogOpen = (params,event,details) => {
        setOrderNum(params.row.圖號);
        setOpen(true);
        setMaxAmount(params.row.訂單數量);
        setSendRow(params.row)
    };


    const sendOrder =async() => {
        const data = sendRow;
        data.expectedAmount = maxAmount;
        setOpen(false);       
        try {
            const response= await axios.post(`${apiUrl}/Inspection/ReserveOrders`, data);
        
            if (response.data.statusCode === 200) {
              alert('添加成功');
              setExpectedrows(response.data.reservationOrders)
              serchOrder();
              // 可以在这里添加其他成功的处理逻辑
            } else {
              alert('添加失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('添加失败');
          }
    };
    const serchOrder =async() => {
        let url = '';
        switch (filter) {
            case '批號':
              url = `${apiUrl}/Inspection/GetNewOrders?piNum=${filterValue}`;
              break;
            case '圖號':
                url =  `${apiUrl}/Inspection/GetNewOrders?drawNum=${filterValue}`;
              break;
            case '訂單號碼':
                url =  `${apiUrl}/Inspection/GetNewOrders?orderNum=${filterValue}`;
              break;
            default:
                url =  `${apiUrl}/Inspection/GetNewOrders`;   
            }  

        try {
            const response= await axios.get(url);
            if (response.status === 200) {
              setRows(response.data)
            } else {
              alert('查詢失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('查詢失败');
          }
    };

    const handleSetPrintQuantity = async () => {
        try {
            // 使用 Promise.all 来处理 expectedrows 中的每一行
            const updatedRows = await Promise.all(expectedrows.map(async (row) => {
                let order64;
                let piNum64;
    
                // 生成 order64 条码
                await new Promise((resolve) => {
                    generateBarcodes(row.orderNum + row.orderSerial, (barcode) => {
                        order64 = barcode;
                        resolve();
                    });
                });
    
                // 生成 piNum64 条码
                await new Promise((resolve) => {
                    generateBarcodes(row.piNum, (barcode) => {
                        piNum64 = barcode;
                        resolve();
                    });
                });
    
                // 返回新的 row 对象，包含新增的 order64 和 piNum64 字段
                return {
                    ...row,
                    order64,
                    piNum64
                };
            }));
    
            // 根据 printquantity 次数处理每一笔数据
            const newPrintItems = [...printItems]; // 创建 printItems 的副本
    
            updatedRows.forEach((row) => {
                const rowCopies = Array(row.printQuantity).fill(row); // 根据 printquantity 创建 row 的副本数组
    
                rowCopies.forEach((copy) => {
                    if (newPrintItems.length === 0 || !Array.isArray(newPrintItems[newPrintItems.length - 1])) {
                        newPrintItems.push([copy]); // 在数组中新增一个子数组
                    } else if (newPrintItems[newPrintItems.length - 1].length === 6) {
                        newPrintItems.push([copy]); // 如果最后一个子数组已有6个元素，则新增一个子数组
                    } else {
                        newPrintItems[newPrintItems.length - 1].push(copy); // 否则，将copy添加到最后一个子数组中
                    }
                });
            });
    
            // 设置 printItem 为平铺的 rowCopies 数组
            setPrintItem(newPrintItems.flat());
    
            // 更新 printItems
            setPrintItems(newPrintItems);
    
            console.log('Updated Print Items:', newPrintItems);
    
        } catch (error) {
            console.error('Error in handleSetPrintQuantity:', error);
        }
    };
    

    const handleCheckPrint = async(trueOrfalse) => {
        if(trueOrfalse) {
            try {
                const response= await axios.post(`${apiUrl}/Inspection/ResetReserveOrder`, printItem);
            
                if (response.data.statusCode === 200) {
                  alert('成功');
                  setExpectedrows(response.data.reservationOrders)
                  setRows(response.data.orders)
                  // 可以在这里添加其他成功的处理逻辑
                } else {
                  alert('失败');
                }
              } catch (error) {
                console.error('Failed to add data:', error);
                alert('失败');
              }
            setCheckPrint(false);
            setPrintItem(null);
        } else {
            setCheckPrint(false);
            setPrintItem(null);
        }
        
    }
    React.useEffect(() => {
        if (data && data.orders) {
            setRows(data.orders);
        }
        }, [data]);

    React.useEffect(() => {
        setPinumCounts(countPiNUms(printItems));
        }, [printItems]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/Inspection/GetReserveOrders`);
                if (response.data.reservationOrders != null) {
                   
                        const ordersWithPrintQuantity = response.data.reservationOrders.map(order => ({
                            ...order,
                            printQuantity: 0  // 添加 printQuantity 属性，并初始化为 0
                        }));
                        setExpectedrows(ordersWithPrintQuantity);
                } else {
                    alert('数据加载失败');
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
                alert('数据加载失败');
            }
        };
    
        // 调用刚定义的异步函数，以获取数据
        fetchData();
    }, []);
    const columns = [
    
    {
        field: '客戶',
        headerName: '客戶',
        width: 100,
        editable: false,
    },
    {
        field: '批號',
        headerName: '批號',
        width: 150,
        editable: false,
    },
    {
        field: '訂單號碼',
        headerName: '訂單號碼',
        width: 150,
        editable: false,
    },
    {
        field: '流水號',
        headerName: '流水號',
        sortable: false,
        width: 60,
        editable: false,
    },
    {
        field: '訂購單價',
        headerName: '訂購單價',
        type:'number',
        sortable: true,
        width: 160,
        editable: false,
    },
    {
        field: '訂單數量',
        headerName: '訂單數量',
        type:'number',
        sortable: false,
        width: 100,
        editable: false,
    },
    {
        field: '圖號',
        headerName: '圖號',
        sortable: false,
        width: 160,
        editable: false,
    },
    {
        field: '項次',
        headerName: '項次',
        sortable: false,
        width: 60,
        editable: false,
    },
    {
        field: '物品名',
        headerName: '物品名',
        sortable: false,
        width: 160,
        editable: false,
    },
    {
        field: '承製公司',
        headerName: '承製公司',
        sortable: false,
        width: 160,
        editable: false,
    },
    {
        field: '客戶納期',
        headerName: '客戶納期',
        sortable: false,
        width: 160,
        editable: false,
    },
    {
        field: '未印',
        headerName: '未印',
        sortable: false,
        type:'number',
        width: 80,
        editable: false,
    },
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: '檢票OK',
        headerName: '檢票OK',
        width: 160,
        editable: true,
        renderCell: (params) => (
            <Checkbox
            checked={params.value}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={() => {
                const newRows = rows.map(row => {
                if (row.id === params.id) {
                    return { ...row, inspectionOK: !params.value };
                }
                return row;
                });
                setRows(newRows);
            }}
            />
        ),
    },

    ];
    const detailColumns = [
        
    
        {
            field: 'delete',
            headerName: '刪除',
            width: 100,
            sortable: false,
            editable: false,
            renderCell: (params) => (
              <Button startIcon={<DeleteOutlinedIcon />}
                onClick={() => handleDeleteReOrder(params.row)}
              >
               刪除
              </Button>
            ),
        },
        {
            field: 'piNum',
            headerName: '批號',
            width: 100,
            editable: false,
        },
        {
            field: 'orderNum',
            headerName: '訂單號碼',
            width: 100,
            editable: false,
        },
        {
            field: 'model',
            headerName: '機種',
            width: 100,
            editable: false,
        },
        {
            field: 'drawNum',
            headerName: '圖號',
            sortable: false,
            width: 105,
            editable: false,
        },
        {
            field: 'itemName',
            headerName: '品名',
            sortable: true,
            width: 120,
            editable: false,
        },
        
        {
            field: 'orderAmount',
            headerName: '訂單量',
            sortable: false,
            width: 70,
            editable: false,
        },
        {
            field: 'expectedAmount',
            headerName: '預交貨量',
            sortable: false,
            width: 70,
            editable: false,
        },
        {
            field: 'printQuantity',
            headerName: '列印数量',
            width: 110,
            editable: true,
            type: 'number',
            renderCell: (params) => (
                <TextField
                    type="number"
                    size="small"
                    fullWidth
                    value={params.value || 0}
                    onChange={(event) => {
                        const value = parseInt(event.target.value, 10);
                        // 使用 setState 更新 expectedrows
                        const updatedRows = expectedrows.map(row => {
                            if (row.id === params.row.id) {
                                return { ...row, printQuantity: value };
                            }
                            return row;
                        });
                        setExpectedrows(updatedRows);
                    }}
                    inputProps={{ min: 0 }}  // 确保用户只能输入非负数
                />
            )
        },
        {
            field: 'expectedTradeDate',
            headerName: '交貨日期',
            sortable: false,
            width: 100,
            editable: false,
        },
        
        { field: 'id', headerName: 'ID', width: 90 }
        
    ];
    return (
        <Box m={2}>
            <Grid container alignItems="center" spacing={0}>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel id="demo-row-radio-buttons-group-label">過濾條件</FormLabel>                
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e)=>setFilter(e.target.value)}
                        >
                            <FormControlLabel value="批號" control={<Radio />} 
                            label="批號" />
                            <FormControlLabel value="圖號" control={<Radio />} 
                            label="圖號" />
                            <FormControlLabel value="訂單號碼" control={<Radio />} 
                            label="訂單號碼" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        defaultValue=""
                        size="small"
                        onChange={(e) =>setFilterValue(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={serchOrder}>
                        搜尋
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    {printItem && (
                        <>
                            <img src={baseOrder64} alt="Order Barcode" />
                            <img src={basePinum64} alt="PiNum Barcode" />
                        </>
                    )}
                </Grid>
            </Grid>
            <Box sx={{ height: 600, width: '90%'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 10,
                    },
                },
                }}
                onRowDoubleClick={(params,event,details)=>handleDialogOpen(params,event,details)}
                pageSizeOptions={[10,20,30]}
                disableRowSelectionOnClick
            />
            <Typography variant="h3">預交貨訂單</Typography>
            <Tooltip TransitionComponent={Zoom} title="Save">
                <Button onClick={()=>SaveAll(expectedrows)}>儲存</Button>
                <Button onClick={handlePrintClickOpen}>列印現品票</Button>
                <Button onClick={()=>setPrintItems([])}>重設列印資料</Button>
                <Button onClick={handleSetPrintQuantity}>設定列印數量</Button>
            </Tooltip>
            <table>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'left', backgroundColor: '#f4f4f4' }}>批號</th>
                        <th style={{ textAlign: 'left', backgroundColor: '#f4f4f4' }}>次數</th>
                    </tr>
                </thead>
                <tbody>
                    {piNumCounts.map((item, index) => (
                    <tr key={index}>
                        <td style={{ padding: '8px 20px', border: '1px solid #ddd' }}>{item.piNum}</td>
                        <td style={{ padding: '8px 20px', border: '1px solid #ddd' }}>{item.count}</td>
                    </tr>
                    ))}
                </tbody>
            </table>
            <DataGrid
                rows={expectedrows}
                columns={detailColumns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 10,
                    },
                },
                }}
                onRowDoubleClick={(params,event,details)=>handleDialogOpen(params,event,details)}
                pageSizeOptions={[10,20,30]}
                disableRowSelectionOnClick
            />
            <Dialog open={open} onClose={()=>setOpen(false)}>
                <DialogTitle>預交貨訂單數量確認</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    訂單號碼{orderNum}預交貨數量
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="數量"
                    type="email"
                    fullWidth
                    variant="standard"
                    defaultValue={maxAmount}
                    onChange={(e) => setMaxAmount(e.target.value)}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>setOpen(false)}>取消</Button>
                <Button onClick={sendOrder}>送出</Button>
                </DialogActions>
            </Dialog> 
            <Dialog
                fullScreen
                open={print}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    列印預覽
                    </Typography>
                </Toolbar>             
                </AppBar>         
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <MyDocument data={printItem} datas={printItems} />
                </PDFViewer>
            </Dialog> 



                <Dialog
                    open={checkPrint}
                    onClose={() =>handleCheckPrint(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"請問您剛剛是否有列印?"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        若有列印 請選擇是，會將該筆預交貨訂單刪除 並更改資料庫<br/>
                        若無列印 請選擇否，資料庫保持原樣
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() =>handleCheckPrint(true)}>已列印</Button>
                    <Button onClick={() =>handleCheckPrint(false)} autoFocus>
                        未列印
                    </Button>
                    </DialogActions>
                </Dialog>  
            </Box>
        </Box>
    );
}


Font.register({
    family: 'TaipeiSansTCBeta-Bold',
    src: chFont, // 使用您的实际路径
  });
Font.register({
family: 'TaipeiSansTCBeta-Light',
src: chLight, // 使用您的实际路径
});
  
const styles = StyleSheet.create({
    page: {
    flexDirection: 'row', // 横向布局
    flexWrap: 'wrap', // 允许内容换行
    padding: 10,
      backgroundColor: 'white',
    },
    gridContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    gridTitle: {
        flexGrow: 0,
        fontFamily: 'TaipeiSansTCBeta-Bold',
        marginLeft:'30%',
        fontSize: 14,
        marginBottom: 10
    },
    gridItem1: {
      flexGrow: 1,
      fontSize: 12,
      padding: 0,
      marginBottom: 10,
      fontFamily: 'TaipeiSansTCBeta-Bold',
    },
    gridItem2: {
        flexGrow: 1,
        fontSize: 12,
        padding: 0,
        marginBottom: 10,
        fontFamily: 'TaipeiSansTCBeta-Light',
    },
    gridImg: {
        flexGrow: 1,
        width: '50%', 
        height: '50%', 
        marginTop:5,
        marginBottom:5
    },
    section: {
        width: '50%', // 占据50%宽度，一行两个
        height: '33.33%', // 占据33.33%高度，一列三个，共6个占满一页
        padding: 10,
        // flexGrow: 1,   
        fontFamily: 'TaipeiSansTCBeta-Bold',
    },
  });
  
  
  
  const MyDocument = ({datas}) => {
    console.log(datas); 
    if (!datas || datas.length === 0) {
        return <Text>没有数据可显示</Text>;  // 如果数据为空，显示替代内容
      }

    const today = new Date();
    const formatDate = (date) => {
        const year = date.getFullYear();
        // getMonth() 返回的月份是从 0 开始的，所以需要加 1
        const month = String(date.getMonth() + 1).padStart(2, '0');
        // getDate() 用于获取月份中的日
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    };
    

    return(
    <>  
            <Document>       
                {datas.map((details, pageIndex) => (
                    <Page size="A4" style={styles.page} key={pageIndex}>
                        {details.map((data, index) => (
                        <View style={styles.section} key={index}>
                        <View style={styles.gridTitle}>
                            <Text>台灣日立公司交貨現品票</Text>
                        </View>
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>廠商名稱:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>華辰企業股份公司</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>交貨日期:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{formatDate(today)}</Text>
                            </View>
                        </View> 
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>料件:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.drawNum}</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>物品名:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.itemName}</Text>
                            </View>
                        </View>
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>訂單數量:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.orderAmount}</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>交貨數量:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.expectedAmount}</Text>
                            </View>
                        </View>
                        {/* <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>客戶名稱:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.client}</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>客戶訂單:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>{data.client}</Text>
                            </View>
                        </View> */}
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>英文品名:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>BASE</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>倉庫:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>36525B</Text>
                            </View>
                        </View>
                        {/* <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>規格:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>2BG654814</Text>
                            </View>
                        </View> */}
                        <View style={styles.gridContainer}>
                            {/* <View style={styles.gridItem1}>
                                <Text>存放位置:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>1C1</Text>
                            </View> */}
                            <View style={styles.gridItem1}>
                                <Text>收貨中心:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>Q1</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>現場位置:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>1C1</Text>
                            </View>
                        </View>
                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>訂單號碼:</Text>
                            </View>
                            <View style={styles.gridImg}>
                                <Image src={data.order64} />
                                <Text style={styles.barcodeText}>{data.orderNum}</Text>     
                            </View>
                        </View>


                        <View style={styles.gridContainer}>
                            {/* <View style={styles.gridItem1}>
                                <Text>存放位置:</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>1C1</Text>
                            </View> */}
                            <View style={styles.gridItem1}>
                                <Text>收貨中心:</Text>
                            </View>
                        </View>

                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>使用工單:</Text>
                            </View>
                            <View style={styles.gridImg}>                            
                                <Image src={data.piNum64} />
                                <Text style={styles.barcodeText}>{data.piNum}</Text>      
                            </View>
                        </View>

                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>存放位置:</Text>
                            </View>
                            <View style={styles.gridItem1}>
                                <Text>1C1</Text>
                            </View>
                        </View>

                        <View style={styles.gridContainer}>
                            <View style={styles.gridItem1}>
                                <Text>使用專案:</Text>
                            </View>
                            <View style={styles.gridItem2}>
                                <Text>免檢</Text>
                            </View>
                        </View>
                    </View>
                    ))}
                </Page>
                ))}            
            </Document>
        
  </>
  )};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Tabs from '../components/erp/tabs/Tab'
import MatCard from '../components/erp/matCard/MatCard';
import MatItemCard from '../components/erp/matItemCard/MatItemCard';
import MatCardStatistics from '../components/erp/matCardStatistics/MatCardStatistics'
import NewMatCardStatistics from '../components/erp/matCardStatistics/NewMatCardStatistics';
import LaunchProduct from '../components/erp/launchProduct/LaunchProduct';
import MakeCmd from '../components/erp/makeCmd/MackCmd';
import { apiUrl } from '../components/apiUrl/ApiUrl';


export default function ProductManager () {
    const tabArray = ['生管資材卡','生管資材卡統計表','生管資材卡明細表','補印製令','發動生產'] // '發動生產','生產排程'
    const [activeTab, setActiveTab] = useState(0);
    const [dataMatCard,setMatCardData] =  useState(null);
    const [dataItems,setItemsData] = useState(null);
    const [DataMatCardStatistics,setDataMatCardStatistics] = useState(null);




    const navigate = useNavigate();

    useEffect(() => {
      const token = sessionStorage.getItem('jwtData');
      if (!token) {
        navigate('/login');
      }
    }, [navigate]);


    useEffect(() => {
    
        const fetchData = async () => {
            let url;
            switch (activeTab) {
                case 0:
                    url = `${apiUrl}/MatCard/GetItems?page=1`;
                    break;
                case 1:
                    url = `${apiUrl}/MatCard/GetStatisticsMains`;
                    break;
                case 2:
                    url = `${apiUrl}/MatCard/GetMains?page=1`;
                    break;
                default:
                    setMatCardData(null);
                    setDataMatCardStatistics(null);
                    setItemsData(null);
                    return;
            }
            try {
                const result = await axios.get(url);
                if (url === `${apiUrl}/MatCard/GetStatisticsMains`)
                {
                    // setDataMatCardStatistics(result.data);
                }else if (url === `${apiUrl}/MatCard/GetMains?page=1`)
                {
                    setMatCardData(result.data);
                }
                else if (url === `${apiUrl}/MatCard/GetItems?page=1`)
                {
                    setItemsData(result.data);
                }
                
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [activeTab]);  // ${apiUrl}/PurchasePrice/MainList
    
    const renderTabContent = (tabIndex) => {
        
            switch (tabIndex) {
                case 0:
                    return <MatItemCard data={dataItems}/>;
                case 1:
                    return <NewMatCardStatistics />;
                case 2:
                    return <MatCard data={dataMatCard}/>
                case 3:
                    return <MakeCmd data={dataMatCard}/>
                case 4:
                    return <LaunchProduct />
                default:
                    return null;
            }
    };
    return(
        <div>
            <Tabs tabsNames={tabArray} activeTab={activeTab} setActiveTab={setActiveTab}/>
            {renderTabContent(activeTab)}
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import axios from 'axios';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { apiUrl } from '../../apiUrl/ApiUrl';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
const columns = [
  { field: 'id', headerName: 'ID', width: 60 },
  {
    field: 'company',
    headerName: '廠商',
    width: 100,
    editable: true,
  },
  {
    field: 'date',
    headerName: '日期',
    width: 120,
    editable: true,
  },
  {
    field: 'orderNum',
    headerName: '單號',
    width: 130,
    editable: true,
  },
  {
    field: 'drawNum',
    headerName: '圖號',
    sortable: false,
    width: 130,
  },
  {
    field: 'itemName',
    headerName: '物品名',
    sortable: false,
    width: 150,
  },
  {
    field: 'unit',
    headerName: '單位',
    sortable: false,
    width: 60,
  },
  {
    field: 'piNum',
    headerName: '批號',
    sortable: false,
    width: 120,
  },
  {
    field: 'amount',
    headerName: '數量',
    type:'number',
    sortable: false,
    width: 100,
  },
  {
    field: 'price',
    headerName: '單價',
    type:'number',
    sortable: false,
    width: 100,
  },
  {
    field: 'money',
    headerName: '金額',
    type:'number',
    sortable: false,
    width: 100,
  },
];


export default function AcceptanceInStockDetail() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const [start, setStart] = React.useState(dayjs(formattedDate));
    const [end, setEnd] = React.useState(dayjs(formattedDate));
    const [com,setComs] = React.useState([]);
    const [chooseCom,setChooseCom] = React.useState('');
    const [status,setStatus] = React.useState(null);
    const [rows,setRows] = React.useState([]);
    const [total,setTotal] = React.useState(null);
    const [drawNum,setDrawNum] = React.useState('');
    const [selectedOption, setSelectedOption] = useState({
      id: 753,
      label: null,
      manufacturer_Seq: "4500001",
      manufacturer_AbbreviationName: "台日",
      manufacturer_FullName: "台灣日立江森自控股份有限公司",
      manufacturer_UniformNum: "11915305",
      manufacturer_CityCode: "33810",
      manufacturer_FaxNum: "03-3245321",
      manufacturer_BossName: null,
      manufacturer_ContactName: null,
      manufacturer_CompanyAddress: "台北市南京東路三段63號",
      manufacturer_CompanyPhone: "03-3244711",
      manufacturer_CompanyFax: "03-3245321",
      manufacturer_ShipAddress: "桃園縣蘆竹鄉內厝村內溪路２９號",
      manufacturer_ShipAddress2: null,
      manufacturer_ShipAddress3: null,
      manufacturer_FactoryNum: null,
      manufacturer_FactoryFax: null,
      manufacturer_AccCode: "6116",
      manufacturer_PayMent: null
  });
    
    React.useEffect(() => {
      const fetchData = async () => {
        try {
          
          const response = await axios.get(`${apiUrl}/OrderManage/GetOptions`);
          // 檢查響應的結果，並設置到 state
            setComs(response.data.companyOptions);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, []);

    const handleRadioChange = (event) => {
      const value = event.target.value;
  
      // 如果value是一个数字字符串，转换为数字
      // eslint-disable-next-line no-restricted-globals
      setStatus(isNaN(Number(value)) ? value : Number(value));
    };
    const handleChange = (event, newValue) => {
      setSelectedOption(newValue);
      if(newValue === null || newValue === undefined) return;
      setChooseCom(newValue.comSimpleName);
  };

    const handleSend = async () => {
      const formattedStart = start.format("YYYY-MM-DD");
      const formattedEnd = end.format("YYYY-MM-DD");
      try {
          const response = await axios.get(`${apiUrl}/Bill/GetAcceptanceInStock?start=${formattedStart}&end=${formattedEnd}&status=${status}&client=${chooseCom}&drawNum=${drawNum}`);
          setRows(response.data.inStockDtos)
          setTotal(response.data.totalNum)
      } catch (error) {
          alert('搜尋失敗')
      }
  };
  return (
    <>
        <Box sx={{ height: 'auto', width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>                  
                    <Grid item xs={2}>
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Item style={{background:'transparent'}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                    <DatePicker
                                    label="從"
                                    value={start}
                                    onChange={(newValue) => setStart(newValue)}
                                    />
                                </DemoContainer>
                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                    <DatePicker
                                    label="到"
                                    value={end}
                                    onChange={(newValue) => setEnd(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Item>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Item style={{background:'transparent'}}>
                          <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={com}
                              sx={{ width: '100%' }}
                              value={selectedOption}
                              onChange={handleChange}
                              getOptionLabel={(option) => option.comNum+ option.comSimpleName}
                              // 設置renderInput來渲染輸入元件
                              renderInput={(params) => <TextField {...params} label="廠商" />}
                          />
                            <TextField id="outlined-basic" label="圖號" variant="outlined" onChange={(e)=>setDrawNum(e.target.value)}/>
                        </Item>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                       <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Item style={{background:'transparent'}}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">帳款狀況</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={status}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel value="0" control={<Radio />} label="未請款" />
                                    <FormControlLabel value="1" control={<Radio />} label="已請款" />
                                    <FormControlLabel value="2" control={<Radio />} label="全部" />
                                </RadioGroup>
                            </FormControl>
                        </Item>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                       <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Item style={{background:'transparent'}}>
                                <Button variant="outlined" size="small" style={{width:'50%',marginTop:'25%'}} onClick={handleSend}>搜尋</Button>
                                <Button variant="outlined" size="small" style={{width:'50%',marginTop:'25%'}}>列印</Button>
                            </Item>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box sx={{ height: 600, width: '100%' }}>
            <div style={{marginTop:'10px'}}>
                  <span>數量總計 :    </span><span>{total!== null?total.total:null}</span><span>  金額總計 :    </span><span>{total!== null?total.totalMoney:null}</span>          
            </div>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 100,
                    },
                },
                }}
                pageSizeOptions={[100,75,50]}
                disableRowSelectionOnClick
            />
        </Box>
    </>
  );
}

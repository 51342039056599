import { useLocation,useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';
import axios from 'axios';
import { FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';

import { apiUrl } from '../apiUrl/ApiUrl';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function EditOrDeleteUserPage () {
  const location = useLocation();
  const [data , setUserData] = useState(null);
  const [id, setId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [bom1, setBom1] = useState(false);
  const [bom2, setBom2] = useState(false);
  const [bom3, setBom3] = useState(false);
  const [engineering1, setEngineering1] = useState(false);
  const [engineering2, setEngineering2] = useState(false);
  const [engineering3, setEngineering3] = useState(false);
  const [shipOrderTop1, setShipOrderTop1] = useState(false);
  const [shipOrderTop3, setShipOrderTop3] = useState(false);
  const [shipOrderBottom1, setShipOrderBottom1] = useState(false);
  const [shipDetail3, setShipDetail3] = useState(false);
  const [taiCheckBill3, setTaiCheckBill3] = useState(false);
  const [taiCheckBill1, setTaiCheckBill1] = useState(false);
  const [signSelect3, setSignSelect3] = useState(false);
  const [matCardDetail3, setMatCardDetail3] = useState(false);
  const [matCardStatistics3, setMatCardStatistics3] = useState(false);
  const [matCardStatistics2, setMatCardStatistics2] = useState(false);
  const [matCard3, setMatCard3] = useState(false);
  const [matCard2, setMatCard2] = useState(false);
  const [printBuy3, setPrintBuy3] = useState(false);
  const [materialParam1, setMaterialParam1] = useState(false);
  const [materialParam2, setMaterialParam2] = useState(false);
  const [materialParam3, setMaterialParam3] = useState(false);
  const [orderByHand1, setOrderByHand1] = useState(false);
  const [orderByExcel1, setOrderByExcel1] = useState(false);
  const [stockChange1, setStockChange1] = useState(false);
  const [stockChange3, setStockChange3] = useState(false);
  const [stockChange4, setStockChange4] = useState(false);
  const [itemSet1, setItemSet1] = useState(false);
  const [sellPrice1, setSellPrice1] = useState(false);
  const [sellPrice2, setSellPrice2] = useState(false);
  const [sellPrice3, setSellPrice3] = useState(false);
  const [purchaseInStockWork1, setPurchaseInStockWork1] = useState(false);
  const [purchaseInStockWork3, setPurchaseInStockWork3] = useState(false);
  const [purchaseInStockDetail2, setPurchaseInStockDetail2] = useState(false);
  const [purchaseInStockDetail3, setPurchaseInStockDetail3] = useState(false);
  const [purchaseInStockStatistics2, setPurchaseInStockStatistics2] = useState(false);
  const [purchaseInStockStatistics3, setPurchaseInStockStatistics3] = useState(false);
  const [launchProduction1, setLaunchProduction1] = useState(false);
  const [billTotalView3, setBillTotalView3] = useState(false);
  const [purchasePrice1, setPurchasePrice1] = useState(false);
  const [purchasePrice2, setPurchasePrice2] = useState(false);
  const [purchasePrice3, setPurchasePrice3] = useState(false);
  const [makeCmd2, setMakeCmd2] = useState(false);
  const [manufacturer1, setManufacturer1] = useState(false);
  const [manufacturer2, setManufacturer2] = useState(false);
  const [manufacturer3, setManufacturer3] = useState(false);
  const [purchaseWillInStock3, setPurchaseWillInStock3] = useState(false);
  const [purchaseBill3, setPurchaseBill3] = useState(false);
  const [purchaseBill1, setPurchaseBill1] = useState(false);
  const [purchaseSignIn4, setPurchaseSignIn4] = useState(false);
  const [machineSet1, setMachineSet1] = useState(false);
  const [machineSet2, setMachineSet2] = useState(false);
  const [machineSet3, setMachineSet3] = useState(false);
  const [shouldPay1, setShouldPay1] = useState(false);
  const [shouldPay2, setShouldPay2] = useState(false);
  const [shouldPay3, setShouldPay3] = useState(false);
  const [shouldGet1, setShouldGet1] = useState(false);
  const [shouldGet3, setShouldGet3] = useState(false);
  const [shouldGetTotal1, setShouldGetTotal1] = useState(false);
  const [shouldGetTotal3, setShouldGetTotal3] = useState(false);
  const [shouldGetBillTop1, setShouldGetBillTop1] = useState(false);
  const [shouldGetBillBottom1, setShouldGetBillBottom1] = useState(false);
  const [shouldGetBillBottom2, setShouldGetBillBottom2] = useState(false);
  const [shouldGetBillBottom3, setShouldGetBillBottom3] = useState(false);
  const [workPlace1, setWorkPlace1] = useState(false);
  const [workPlace2, setWorkPlace2] = useState(false);
  const [workPlace3, setWorkPlace3] = useState(false);
  const [signInSelect3, setSignInSelect3] = useState(false);
  const [checkStockInDetail3, setCheckStockInDetail3] = useState(false);
  const [canCruduser, setCanCruduser] = useState(false);
  const [itemSet2, setItemSet2] = useState(false);
  const [loading, setLoading] = useState(false);
  const userName = location.state.userName;
  const beUpdateId = location.state.id ;
  const [formData, setFormData] = useState({
    company: '',
    uid: '',
    pwd: '',
    role: '',
    id:'0'
  });
  const navigate = useNavigate();


  useEffect(() => {
    // 如果 userName 是 'new'，則直接返回，不調用 API
    if (userName === 'new') {
      // Set formData to its initial state
      setFormData({
        company: '',
        uid: '',
        pwd: '',
        role: '',
        id: '0'
      });
      return;
    }
  
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/User/GetMain?user=${beUpdateId}`);
        const authresponse = await axios.get(`${apiUrl}/User/Auth?userId=${parseInt(beUpdateId,10)}`);
        const authData = authresponse.data
        setId(authData.id);
        setUserId(authData.userId);
        setBom1(authData.bom1);
        setBom2(authData.bom2);
        setBom3(authData.bom3);
        setEngineering1(authData.engineering1);
        setEngineering2(authData.engineering2);
        setEngineering3(authData.engineering3);
        setShipOrderTop1(authData.shipOrderTop1);
        setShipOrderTop3(authData.shipOrderTop3);
        setShipOrderBottom1(authData.shipOrderBottom1);
        setShipDetail3(authData.shipDetail3);
        setTaiCheckBill3(authData.taiCheckBill3);
        setTaiCheckBill1(authData.taiCheckBill1);
        setSignSelect3(authData.signSelect3);
        setMatCardDetail3(authData.matCardDetail3);
        setMatCardStatistics3(authData.matCardStatistics3);
        setMatCardStatistics2(authData.matCardStatistics2);
        setMatCard3(authData.matCard3);
        setMatCard2(authData.matCard2);
        setPrintBuy3(authData.printBuy3);
        setMaterialParam1(authData.materialParam1);
        setMaterialParam2(authData.materialParam2);
        setMaterialParam3(authData.materialParam3);
        setOrderByHand1(authData.orderByHand1);
        setOrderByExcel1(authData.orderByExcel1);
        setStockChange1(authData.stockChange1);
        setStockChange3(authData.stockChange3);
        setStockChange4(authData.stockChange4);
        setItemSet1(authData.itemSet1);
        setSellPrice1(authData.sellPrice1);
        setSellPrice2(authData.sellPrice2);
        setSellPrice3(authData.sellPrice3);
        setPurchaseInStockWork1(authData.purchaseInStockWork1);
        setPurchaseInStockWork3(authData.purchaseInStockWork3);
        setPurchaseInStockDetail2(authData.purchaseInStockDetail2);
        setPurchaseInStockDetail3(authData.purchaseInStockDetail3);
        setPurchaseInStockStatistics2(authData.purchaseInStockStatistics2);
        setPurchaseInStockStatistics3(authData.purchaseInStockStatistics3);
        setLaunchProduction1(authData.launchProduction1);
        setBillTotalView3(authData.billTotalView3);
        setPurchasePrice1(authData.purchasePrice1);
        setPurchasePrice2(authData.purchasePrice2);
        setPurchasePrice3(authData.purchasePrice3);
        setMakeCmd2(authData.makeCmd2);
        setManufacturer1(authData.manufacturer1);
        setManufacturer2(authData.manufacturer2);
        setManufacturer3(authData.manufacturer3);
        setPurchaseWillInStock3(authData.purchaseWillInStock3);
        setPurchaseBill3(authData.purchaseBill3);
        setPurchaseBill1(authData.purchaseBill1);
        setPurchaseSignIn4(authData.purchaseSignIn4);
        setMachineSet1(authData.machineSet1);
        setMachineSet2(authData.machineSet2);
        setMachineSet3(authData.machineSet3);
        setShouldPay1(authData.shouldPay1);
        setShouldPay2(authData.shouldPay2);
        setShouldPay3(authData.shouldPay3);
        setShouldGet1(authData.shouldGet1);
        setShouldGet3(authData.shouldGet3);
        setShouldGetTotal1(authData.shouldGetTotal1);
        setShouldGetTotal3(authData.shouldGetTotal3);
        setShouldGetBillTop1(authData.shouldGetBillTop1);
        setShouldGetBillBottom1(authData.shouldGetBillBottom1);
        setShouldGetBillBottom2(authData.shouldGetBillBottom2);
        setShouldGetBillBottom3(authData.shouldGetBillBottom3);
        setWorkPlace1(authData.workPlace1);
        setWorkPlace2(authData.workPlace2);
        setWorkPlace3(authData.workPlace3);
        setSignInSelect3(authData.signInSelect3);
        setCheckStockInDetail3(authData.checkStockInDetail3);
        setCanCruduser(authData.canCruduser);
        setItemSet2(authData.itemSet2);

        setUserData(response.data.user);
        // 设置formData的状态
        setFormData({
          company: response.data.user ? response.data.user.company : '',
          uid: response.data.user ? response.data.user.uid : '',
          pwd: response.data.user ? response.data.user.pwd : '',
          role: response.data.user ? response.data.user.role : '',
          id: response.data.user ? response.data.user.id.toString() : '0',
        });
        setLoading(false);
  
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userName]);
  const handleCheckboxChange = (setter) => (event) => {
    setter(event.target.checked);
  };
  console.log(formData)
  // 發送修改後的數據到後端
  const handleSave = async () => {
    try {
      const updateData = {
        Id: id,
        UserId: userId,
        Bom1: bom1,
        Bom2: bom2,
        Bom3: bom3,
        Engineering1: engineering1,
        Engineering2: engineering2,
        Engineering3: engineering3,
        ShipOrderTop1: shipOrderTop1,
        ShipOrderTop3: shipOrderTop3,
        ShipOrderBottom1: shipOrderBottom1,
        ShipDetail3: shipDetail3,
        TaiCheckBill3: taiCheckBill3,
        TaiCheckBill1: taiCheckBill1,
        SignSelect3: signSelect3,
        MatCardDetail3: matCardDetail3,
        MatCardStatistics3: matCardStatistics3,
        MatCardStatistics2: matCardStatistics2,
        MatCard3: matCard3,
        MatCard2: matCard2,
        PrintBuy3: printBuy3,
        MaterialParam1: materialParam1,
        MaterialParam2: materialParam2,
        MaterialParam3: materialParam3,
        OrderByHand1: orderByHand1,
        OrderByExcel1: orderByExcel1,
        StockChange1: stockChange1,
        StockChange3: stockChange3,
        StockChange4: stockChange4,
        ItemSet1: itemSet1,
        SellPrice1: sellPrice1,
        SellPrice2: sellPrice2,
        SellPrice3: sellPrice3,
        PurchaseInStockWork1: purchaseInStockWork1,
        PurchaseInStockWork3: purchaseInStockWork3,
        PurchaseInStockDetail2: purchaseInStockDetail2,
        PurchaseInStockDetail3: purchaseInStockDetail3,
        PurchaseInStockStatistics2: purchaseInStockStatistics2,
        PurchaseInStockStatistics3: purchaseInStockStatistics3,
        LaunchProduction1: launchProduction1,
        BillTotalView3: billTotalView3,
        PurchasePrice1: purchasePrice1,
        PurchasePrice2: purchasePrice2,
        PurchasePrice3: purchasePrice3,
        MakeCmd2: makeCmd2,
        Manufacturer1: manufacturer1,
        Manufacturer2: manufacturer2,
        Manufacturer3: manufacturer3,
        PurchaseWillInStock3: purchaseWillInStock3,
        PurchaseBill3: purchaseBill3,
        PurchaseBill1: purchaseBill1,
        PurchaseSignIn4: purchaseSignIn4,
        MachineSet1: machineSet1,
        MachineSet2: machineSet2,
        MachineSet3: machineSet3,
        ShouldPay1: shouldPay1,
        ShouldPay2: shouldPay2,
        ShouldPay3: shouldPay3,
        ShouldGet1: shouldGet1,
        ShouldGet3: shouldGet3,
        ShouldGetTotal1: shouldGetTotal1,
        ShouldGetTotal3: shouldGetTotal3,
        ShouldGetBillTop1: shouldGetBillTop1,
        ShouldGetBillBottom1: shouldGetBillBottom1,
        ShouldGetBillBottom2: shouldGetBillBottom2,
        ShouldGetBillBottom3: shouldGetBillBottom3,
        WorkPlace1: workPlace1,
        WorkPlace2: workPlace2,
        WorkPlace3: workPlace3,
        SignInSelect3: signInSelect3,
        CheckStockInDetail3: checkStockInDetail3,
        CanCruduser: canCruduser,
        ItemSet2: itemSet2
      };
      await axios.put(`${apiUrl}/User/Auth`, updateData);
      alert('數據已成功保存');
    } catch (error) {
      console.error('Error updating data:', error);
      alert('保存數據時出錯');
    }
  };

  const handleSubmit = async (event) => {
    if(userName !== 'new') {
        try {
            event.preventDefault(); // 防止表單預設提交行為
        
            // 使用一個區域變數存儲當前的 formData 值
            const currentFormData = { ...formData };
        
            // 使用你的API URL
            const response = await axios.post(`${apiUrl}/User/EditMain`, currentFormData);
        
            if (response.data.statusCode === 200) {
              // 处理响应
              console.log('Data successfully submitted');
              alert('修改成功')
              setUserData(response.data.user)
            } else {
              alert('修改失敗 請確認欄位值');
            }
          } catch (error) {
            console.error('There was an error!', error);
          }
    }else {
        try {
            event.preventDefault(); // 防止表單預設提交行為
        
            // 使用一個區域變數存儲當前的 formData 值
            const currentFormData = { ...formData };
            console.log(currentFormData)
        
            // 使用你的API URL
            const response = await axios.post(`${apiUrl}/User/CreateMain`, currentFormData);
        
            if (response.data.statusCode === 200) {
              // 处理响应
              console.log('Data successfully submitted');
              alert('新增成功')
              setUserData(response.data.user)
            } else {
              alert('新增失敗 請確認欄位值');
            }
          } catch (error) {
            console.error('There was an error!', error);
          }
    }
    
  };
  

const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData(prevState => ({
        ...prevState,
        [id]: value
    }));       
};
const handleBack =() => {
    navigate('/dashboard/user', { 
        replace: true 
      });
}
return(
  <>
    {loading ? (
      <div className='container-fluid'>
        {/* You can replace this div with a loading spinner or something else */}
        <div>Loading...</div>
      </div>
    ) : (
      <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row mb-3'>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">編輯使用者</span></h2>
                </div>
                <div className='col-5'/>
            </div>           
            <div className='row mb-3'>
                <div className='col-3'/>
                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                    <span>Uid Account</span>
                </div>
                <div className='col-2 ps-0'>
                    <input type="text" id="uid" className="form-control" aria-describedby="passwordHelpInline"
                    value = {formData.uid|| ""}
                    onChange={handleInputChange}/>
                </div>
                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                    <span>PassWord</span>
                </div>
                <div className='col-2  ps-0'>
                    <input type="text" id="pwd" className="form-control" aria-describedby="passwordHelpInline"
                    value = {formData.pwd|| ""}
                    onChange={handleInputChange}/>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-3'/>
                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                    <span>Company</span>
                </div>
                <div className='col-2 ps-0'>
                    <input type="text" id="company" className="form-control" aria-describedby="passwordHelpInline"
                        value = {formData.company|| ""}
                        onChange={handleInputChange}/>
                </div>
                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                    <span>UserName</span>
                </div>
                <div className='col-2  ps-0'>
                <input type="text" id="role" className="form-control" aria-describedby="passwordHelpInline"
                        value = {formData.role|| ""}
                        onChange={handleInputChange}/>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 d-flex justify-content-center'>
                    <button type="submit" className="btn btn-dark">提交</button>
                    <span style={{ marginLeft: '10px' }}/>
                    <button type="button" className="btn btn-dark" onClick={handleBack}>返回</button>
                </div>
            </div>
        </div>
      </form>
    )}    
    {userName === 'new' ? null : 
    <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h1>權限控管</h1>
        </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <FormGroup>
        <FormControlLabel
            control={<Checkbox checked={bom1} onChange={handleCheckboxChange(setBom1)} />}
            label="Bom1"
          />
          <FormControlLabel
            control={<Checkbox checked={bom2} onChange={handleCheckboxChange(setBom2)} />}
            label="Bom2"
          />
          <FormControlLabel
            control={<Checkbox checked={bom3} onChange={handleCheckboxChange(setBom3)} />}
            label="Bom3"
          />
          <FormControlLabel
            control={<Checkbox checked={engineering1} onChange={handleCheckboxChange(setEngineering1)} />}
            label="工程名稱1"
          />
          <FormControlLabel
            control={<Checkbox checked={engineering2} onChange={handleCheckboxChange(setEngineering2)} />}
            label="工程名稱2"
          />
          <FormControlLabel
            control={<Checkbox checked={engineering3} onChange={handleCheckboxChange(setEngineering3)} />}
            label="工程名稱3"
          />
          <FormControlLabel
            control={<Checkbox checked={shipOrderTop1} onChange={handleCheckboxChange(setShipOrderTop1)} />}
            label="出貨交運單上1"
          />
          <FormControlLabel
            control={<Checkbox checked={shipOrderTop3} onChange={handleCheckboxChange(setShipOrderTop3)} />}
            label="出貨交運單上3"
          />
          <FormControlLabel
            control={<Checkbox checked={shipOrderBottom1} onChange={handleCheckboxChange(setShipOrderBottom1)} />}
            label="出貨交運單下1"
          />
          <FormControlLabel
            control={<Checkbox checked={shipDetail3} onChange={handleCheckboxChange(setShipDetail3)} />}
            label="出貨明細3"
          />
          <FormControlLabel
            control={<Checkbox checked={taiCheckBill3} onChange={handleCheckboxChange(setTaiCheckBill3)} />}
            label="台日檢收票3"
          />
          <FormControlLabel
            control={<Checkbox checked={taiCheckBill1} onChange={handleCheckboxChange(setTaiCheckBill1)} />}
            label="台日檢收票1"
          />
          <FormControlLabel
            control={<Checkbox checked={signSelect3} onChange={handleCheckboxChange(setSignSelect3)} />}
            label="未簽核查詢3"
          />
          <FormControlLabel
            control={<Checkbox checked={matCardDetail3} onChange={handleCheckboxChange(setMatCardDetail3)} />}
            label="生管資材卡名細表3"
          />
          <FormControlLabel
            control={<Checkbox checked={matCardStatistics3} onChange={handleCheckboxChange(setMatCardStatistics3)} />}
            label="生管資材卡統計表3"
          />
          <FormControlLabel
            control={<Checkbox checked={matCardStatistics2} onChange={handleCheckboxChange(setMatCardStatistics2)} />}
            label="生管資材卡統計表2"
          />
          <FormControlLabel
            control={<Checkbox checked={matCard3} onChange={handleCheckboxChange(setMatCard3)} />}
            label="生管資材卡3"
          />
          <FormControlLabel
            control={<Checkbox checked={matCard2} onChange={handleCheckboxChange(setMatCard2)} />}
            label="生管資材卡2"
          />
          <FormControlLabel
            control={<Checkbox checked={printBuy3} onChange={handleCheckboxChange(setPrintBuy3)} />}
            label="列印請採購3"
          />
          <FormControlLabel
            control={<Checkbox checked={materialParam1} onChange={handleCheckboxChange(setMaterialParam1)} />}
            label="材料系數1"
          />
          <FormControlLabel
            control={<Checkbox checked={materialParam2} onChange={handleCheckboxChange(setMaterialParam2)} />}
            label="材料系數2"
          />
          <FormControlLabel
            control={<Checkbox checked={materialParam3} onChange={handleCheckboxChange(setMaterialParam3)} />}
            label="材料系數3"
          />
          <FormControlLabel
            control={<Checkbox checked={orderByHand1} onChange={handleCheckboxChange(setOrderByHand1)} />}
            label="訂單手輸1"
          />
          <FormControlLabel
            control={<Checkbox checked={orderByExcel1} onChange={handleCheckboxChange(setOrderByExcel1)} />}
            label="訂單輸入1"
          />
          <FormControlLabel
            control={<Checkbox checked={stockChange1} onChange={handleCheckboxChange(setStockChange1)} />}
            label="庫存異動調整1"
          />
          <FormControlLabel
            control={<Checkbox checked={stockChange3} onChange={handleCheckboxChange(setStockChange3)} />}
            label="庫存異動調整3"
          />
          <FormControlLabel
            control={<Checkbox checked={stockChange4} onChange={handleCheckboxChange(setStockChange4)} />}
            label="庫存異動調整4"
          />
          <FormControlLabel
            control={<Checkbox checked={itemSet1} onChange={handleCheckboxChange(setItemSet1)} />}
            label="料品資料1"
          />
          <FormControlLabel
            control={<Checkbox checked={itemSet2} onChange={handleCheckboxChange(setItemSet2)} />}
            label="料品資料2"
          />
          <FormControlLabel
            control={<Checkbox checked={sellPrice1} onChange={handleCheckboxChange(setSellPrice1)} />}
            label="售價一覽表1"
          />
          <FormControlLabel
            control={<Checkbox checked={sellPrice2} onChange={handleCheckboxChange(setSellPrice2)} />}
            label="售價一覽表2"
          />
          <FormControlLabel
            control={<Checkbox checked={sellPrice3} onChange={handleCheckboxChange(setSellPrice3)} />}
            label="售價一覽表3"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseInStockWork1} onChange={handleCheckboxChange(setPurchaseInStockWork1)} />}
            label="採購入庫作業1"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseInStockWork3} onChange={handleCheckboxChange(setPurchaseInStockWork3)} />}
            label="採購入庫作業3"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseInStockDetail2} onChange={handleCheckboxChange(setPurchaseInStockDetail2)} />}
            label="採購入庫明細表2"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseInStockDetail3} onChange={handleCheckboxChange(setPurchaseInStockDetail3)} />}
            label="採購入庫明細表3"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseInStockStatistics2} onChange={handleCheckboxChange(setPurchaseInStockStatistics2)} />}
            label="採購入庫統計表2"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseInStockStatistics3} onChange={handleCheckboxChange(setPurchaseInStockStatistics3)} />}
            label="採購入庫統計表3"
          />
          <FormControlLabel
            control={<Checkbox checked={launchProduction1} onChange={handleCheckboxChange(setLaunchProduction1)} />}
            label="發動生產"
          />
          <FormControlLabel
            control={<Checkbox checked={billTotalView3} onChange={handleCheckboxChange(setBillTotalView3)} />}
            label="發票總覽"
          />
          <FormControlLabel
            control={<Checkbox checked={purchasePrice1} onChange={handleCheckboxChange(setPurchasePrice1)} />}
            label="進價一覽表1"
          />
          <FormControlLabel
            control={<Checkbox checked={purchasePrice2} onChange={handleCheckboxChange(setPurchasePrice2)} />}
            label="進價一覽表2"
          />
          <FormControlLabel
            control={<Checkbox checked={purchasePrice3} onChange={handleCheckboxChange(setPurchasePrice3)} />}
            label="進價一覽表3"
          />
          <FormControlLabel
            control={<Checkbox checked={makeCmd2} onChange={handleCheckboxChange(setMakeCmd2)} />}
            label="補印製令2"
          />
          <FormControlLabel
            control={<Checkbox checked={manufacturer1} onChange={handleCheckboxChange(setManufacturer1)} />}
            label="廠商資料1"
          />
          <FormControlLabel
            control={<Checkbox checked={manufacturer2} onChange={handleCheckboxChange(setManufacturer2)} />}
            label="廠商資料2"
          />
          <FormControlLabel
            control={<Checkbox checked={manufacturer3} onChange={handleCheckboxChange(setManufacturer3)} />}
            label="廠商資料3"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseWillInStock3} onChange={handleCheckboxChange(setPurchaseWillInStock3)} />}
            label="請採購預定入庫3"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseBill3} onChange={handleCheckboxChange(setPurchaseBill3)} />}
            label="請採購單3"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseBill1} onChange={handleCheckboxChange(setPurchaseBill1)} />}
            label="請採購單1"
          />
          <FormControlLabel
            control={<Checkbox checked={purchaseSignIn4} onChange={handleCheckboxChange(setPurchaseSignIn4)} />}
            label="請採購簽核4"
          />
          <FormControlLabel
            control={<Checkbox checked={machineSet1} onChange={handleCheckboxChange(setMachineSet1)} />}
            label="機台設定1"
          />
          <FormControlLabel
            control={<Checkbox checked={machineSet2} onChange={handleCheckboxChange(setMachineSet2)} />}
            label="機台設定2"
          />
          <FormControlLabel
            control={<Checkbox checked={machineSet3} onChange={handleCheckboxChange(setMachineSet3)} />}
            label="機台設定3"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldPay1} onChange={handleCheckboxChange(setShouldPay1)} />}
            label="應付帳款1"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldPay2} onChange={handleCheckboxChange(setShouldPay2)} />}
            label="應付帳款2"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldPay3} onChange={handleCheckboxChange(setShouldPay3)} />}
            label="應付帳款3"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldGet1} onChange={handleCheckboxChange(setShouldGet1)} />}
            label="應收帳款1"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldGet3} onChange={handleCheckboxChange(setShouldGet3)} />}
            label="應收帳款3"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldGetTotal1} onChange={handleCheckboxChange(setShouldGetTotal1)} />}
            label="應收帳款總表1"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldGetTotal3} onChange={handleCheckboxChange(setShouldGetTotal3)} />}
            label="應收帳款總表3"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldGetBillTop1} onChange={handleCheckboxChange(setShouldGetBillTop1)} />}
            label="應收對帳單上1"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldGetBillBottom1} onChange={handleCheckboxChange(setShouldGetBillBottom1)} />}
            label="應收對帳單下1"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldGetBillBottom2} onChange={handleCheckboxChange(setShouldGetBillBottom2)} />}
            label="應收對帳單下2"
          />
          <FormControlLabel
            control={<Checkbox checked={shouldGetBillBottom3} onChange={handleCheckboxChange(setShouldGetBillBottom3)} />}
            label="應收對帳單下3"
          />
          <FormControlLabel
            control={<Checkbox checked={workPlace1} onChange={handleCheckboxChange(setWorkPlace1)} />}
            label="職場設定1"
          />
          <FormControlLabel
            control={<Checkbox checked={workPlace2} onChange={handleCheckboxChange(setWorkPlace2)} />}
            label="職場設定2"
          />
          <FormControlLabel
            control={<Checkbox checked={workPlace3} onChange={handleCheckboxChange(setWorkPlace3)} />}
            label="職場設定3"
          />
          <FormControlLabel
            control={<Checkbox checked={signInSelect3} onChange={handleCheckboxChange(setSignInSelect3)} />}
            label="簽核查詢3"
          />
          <FormControlLabel
            control={<Checkbox checked={checkStockInDetail3} onChange={handleCheckboxChange(setCheckStockInDetail3)} />}
            label="驗收入庫明細3"
          />
          <FormControlLabel
            control={<Checkbox checked={canCruduser} onChange={handleCheckboxChange(setCanCruduser)} />}
            label="可否更動帳號USER"
          />
        </FormGroup>
      </div>
      <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Button variant="contained" onClick={handleSave}>保存修改</Button>
      </div>
    </>
    }
  </>
)
};

import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import '../../../css/matset.css'
import { apiUrl } from '../../apiUrl/ApiUrl';

export default function BuyWithInStock({data}) {
    const [newData,setNewData] = useState(null);
    const [detailData,setDetailData] = useState(null);
    const [selected, setSelected] = useState('');
    const [model,setModel] = useState('讀取模式');
    const [selectObject,setSelectObject] = useState(null);

    const [piNum,setPiNum] = useState('');
    const [needDate,setNeedDate] = useState('');
    const [buyDate,setBuyDate] = useState('');
    const [orderNum,setOrderNum] = useState('');
    const [approveDate,setApproveDate] = useState('');

    const [page,setPage] = useState(1);
    // console.log(data)
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
    }

    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">請採購預定入庫表</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                    <ChooseModel setModel={setModel} setNewData={setNewData} setSelected={setSelected}/>
                </div>
                <div className='col-5'>
                    {model === '搜尋模式'?                    <SelectFilter setNewData={setNewData}                    
                    setBuyDate={setBuyDate} 
                    setPiNum={setPiNum}
                    setNeedDate={setNeedDate}
                    setApproveDate={setApproveDate}
                    setOrderNum={setOrderNum}
                    buyDate={buyDate}
                    needDate={needDate}
                    piNum={piNum}
                    orderNum={orderNum}
                    approveDate={approveDate} 
                    setSelectObject={setSelectObject}
                    />:null}

                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                {/* <div className='col-12'>
                    <h2><span className="badge bg-dark">生管資材卡</span></h2>
                </div>               */}
            </div>
            <div className='row mb-3'>
                <div className='col-12'>
                    {/* {detailData != null ? <DetailListTable data={detailData} 
                    setDetailData={setDetailData} 
                    setModel={setModel}
                    setEditData={setEditData}/> : <div>目前沒有副表資料</div>} */}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">採購資料</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                {data !== null?
               <ListTable setDetailData={setDetailData} 
                    setNewData={setNewData} data={newData || data} 
                    setModel={setModelandGetDate} page={page} 
                    setPage={setPage}
                    buyDate={buyDate}
                    needDate={needDate}
                    piNum={piNum} 
                    selectObject={selectObject}/> : null}
                </div>              
            </div>
        </div>     
    )
}

function ListTable({data,setNewData,needDate,setDetailData,page,setPage,piNum,buyDate,selectObject}) {
    console.log(data,6666)
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }


    const nextPage = () => {
            axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${''}&page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }

    const prePage = () => {
            axios.get(`${apiUrl}/Buyer/GetMains?needDate=${needDate}&piNum=${piNum}&buyDate=${buyDate}&orderNum=${''}&page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }


    const GetDetails = (drawNum) => {
        axios.get(`${apiUrl}/Buyer/GetDetails?drawNum=${drawNum}`)
        .then(response => {
            if(response.data.statusCode === 200) {
                setDetailData(response.data)
                console.log(response.data)
            }else{
                alert('找不到資料');
            }            
        })
        .catch(error =>{
            // handle error
            console.log(error);
        })
    }

    const dataToExcel = () => {
        if(selectObject != null) {
            axios.get(`${apiUrl}/Buyer/DataToExcel`, { 
                responseType: 'blob',
                params: selectObject
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '請採購資料.xlsx'); // or any other filename you want
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => console.log(error));
        }else {
            alert("缺少搜尋條件")
        }

    }
    

    return(
        <>
        <div style={{overflowX: "auto", height: "80vh"}}>        
            <button type="button" style={btnPageMargin} className="btn btn-secondary" onClick={() => dataToExcel()}>列印Excel</button>     
            <table className="table">
                <thead className="sticky-header">
                    <tr>
                        <th scope="col" style={thStyle.centered}>#</th>
                        <th scope="col" style={thStyle.centered}>採購日期</th>
                        <th scope="col" style={thStyle.centered}>廠商</th>
                        <th scope="col" style={thStyle.centered}>需求日</th>
                        <th scope="col" style={thStyle.centered}>圖號</th>
                        <th scope="col" style={thStyle.centered}>物品名</th>
                        <th scope="col" style={thStyle.centered}>單位</th>
                        <th scope="col" style={thStyle.centered}>數量</th>
                        <th scope="col" style={thStyle.centered}>單價</th>
                        <th scope="col" style={thStyle.centered}>金額</th>
                        <th scope="col" style={thStyle.centered}>單號</th>
                        <th scope="col" style={thStyle.centered}>批號</th>
                        <th scope="col" style={thStyle.centered}>核准</th>
                    </tr>
                </thead>
                {data.buyerDtos.length > 0 ?<tbody>
                    {data.buyerDtos.map((item, index) => (
                        <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>{index + 1}</th>
                            <td style={thStyle.centered}>{item.date}</td>
                            <td style={thStyle.centered}>{item.manufacturer}</td>
                            <td style={thStyle.centered}>{item.needDate}</td>  
                            <td style={thStyle.centered}>{item.drawNum}</td> 
                            <td style={thStyle.centered}>{item.itemName}</td> 
                            <td style={thStyle.centered}>{item.unit}</td> 
                            <td style={thStyle.centered}>{item.amount}</td> 
                            <td style={thStyle.centered}>{item.unitPrice}</td> 
                            <td style={thStyle.centered}>{item.moneyAmount}</td> 
                            <td style={thStyle.centered}>{item.orderNum}</td> 
                            <td style={thStyle.centered}>{item.piNum}</td> 
                            <td style={thStyle.centered}>{item.approve}</td>             
                        </tr>
                    ))}
                </tbody>:null}
            </table>
        </div>
        {/* <div style={{textAlign: 'center'}} className='mt-3'>
            <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
            <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
        </div> */}
        </>
    )
}
function DetailListTable({ data,setDetailData,setModel,setEditData }) {
    const btnMargin = {
        marginLeft: '2%',
    };
    const thStyle = {
        centered: {
        textAlign: 'center',
        },
    };

    const handleEdit = (detail) => {
        setModel('採購入庫修改');
        setEditData(detail);
        console.log(detail)
    };

    const handleDelete = (detail) => {
        // 建立要傳送的物件
        const Data = {
        drawNum: detail.drawNum,
        company: detail.company,
        date: detail.date,
        pk: detail.pk,
        price: detail.price,
        pricePerson: detail.pricePerson,
        };

        // 發送 POST 請求到後端 API
        axios
        .post(`${apiUrl}/PurchasePrice/delete/DeleteDetail`, Data)
        .then((response) => {
            // 處理刪除成功的回應console.log(response.data)
            if (response.data === 200) {
                axios
                    .get(`${apiUrl}/PurchasePrice/DetailList?drawNum=${Data.drawNum}`)
                    .then(response => {
                        // Update the data
                        setDetailData(response.data);
                        alert('刪除成功');
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    });
            } else {
                alert('刪除失敗，找不到可刪除物件');
            }
        })
        .catch((error) => {
            // 處理刪除失敗的回應
            alert('刪除失敗，找不到可刪除物件')
            console.error(error);
        });
    };

    return (
        <div style={{overflowX: "auto", height: "50vh"}}>              
            <table className="table">
                <thead className="sticky-header">
                    <tr>
                    <th scope="col" style={thStyle.centered}>
                        #
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        日期
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        類別
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        單號
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        批號
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        入量
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        出量
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        庫存量
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        核准
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        領用
                    </th>
                    <th scope="col" style={thStyle.centered}>
                        操作
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {data.purchaseMatCardDtos.map((detail, index) => (
                    <tr key={uuidv4()}>
                        <th scope="row" style={thStyle.centered}>
                        {index + 1}
                        </th>
                        <td style={thStyle.centered}>{detail.date}</td>
                        <td style={thStyle.centered}>{detail.kind}</td>
                        <td style={thStyle.centered}>{detail.orderNum}</td>
                        <td style={thStyle.centered}>{detail.piNum}</td>
                        <td style={thStyle.centered}>{detail.intAmount}</td>
                        <td style={thStyle.centered}>{detail.outAmount}</td>
                        <td style={thStyle.centered}>{detail.stockAmount}</td>
                        <td style={thStyle.centered}>{detail.approve}</td>
                        <td style={thStyle.centered}>{detail.receive}</td>
                        <td style={thStyle.centered}>
                        <button type="button" className="btn btn-warning" onClick={() => handleEdit(detail)}
                        >修改</button>  
                        </td> 
                    </tr>
                    ))}
                    {/* disabled={!!detail.invoiceNum} */}
                </tbody>
            </table>
        </div>
    );
}
function ChooseModel({ setModel,selected,setSelected}) { 
    const handleChange = (event) => {
        setSelected(event.target.id);
    };
    const selectModel = (event) => {
        if (event.target.id === "btnradio2") {
            setModel('搜尋模式')
        }
        else if (event.target.id === "btnradio4") {
            setModel('讀取模式')
        }
    }
        
    return (
        <div className="btn-group-vertical">
            <label className={selected === 'btnradio2' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio2">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio2"
                    checked={selected === 'btnradio2'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>搜尋模式</strong>
            </label>
            <label className={selected === 'btnradio4' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio4">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio4"
                    checked={selected === 'btnradio4'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>讀取模式</strong>
            </label>
        </div>
    );
}

function CreateForm({editData}) {

    const [formData, setFormData] = useState({
        manufacturers: '祥達'
        // 更多的字段...
    });
    const [options, setOptions] = useState([]);
    const [selectValue, setSelectValue] = useState("");

    useEffect(() => {
        setFormData(editData || {
            manufacturers: '祥達'
            // 更多的字段...
        });
    }, [editData]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/Storage/GetOptions`);
                setOptions(response.data);
            } catch (error) {
                console.error('Failed to fetch data', error);
            }
        };

        fetchData();
    }, []);  
    console.log(editData)

    const handleInsertSubmit = async (event) => {
        event.preventDefault();
        if(formData.manufacturers === '') {
            formData.manufacturers='祥達'
        }else{
            formData.manufacturers = selectValue;
        }
        
        if(editData === null) {
            try {
                // 使用你的API URL
                const response = await axios.post(`${apiUrl}/Storage/CreateMain`, formData);
                    if (response.data.statusCode === 200) {
                        // 处理响应
                        console.log('Data successfully submitted');
                    }else{
                        alert('新增失敗 請確認欄位值')
                    }
                } 
                catch (error) {
                console.error('There was an error!', error);
                }
        }else {
            try {
                // 使用你的API URL
                const response = await axios.post(`${apiUrl}/Storage/EditMain`, formData);
                    if (response.data.statusCode === 200) {
                        // 处理响应
                        console.log('Data successfully submitted');
                    }else{
                        alert('修改失敗 請確認欄位值')
                    }
                } 
                catch (error) {
                console.error('There was an error!', error);
                }
        }

        

    };


    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));       
    };
    const handleSelectChange = (event) => {
        setSelectValue(event.target.value);
    }
    return(
        <form onSubmit={handleInsertSubmit}>
                        <div className='container-fluid'>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>入庫日期</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="date" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.date : ''}
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>採購單號</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="orderNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.orderNum : ''}
                                    onChange={handleInputChange}/>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>廠商</span>
                                </div>
                                <div className='col-2 ps-0'>
                                <select className="form-select" id="manufacturers" value={editData?editData.manufacturers:selectValue} onChange={handleSelectChange}>
                                {options.companyOptions != null?options.companyOptions.map((item, index) => (
                                    <option key={index} value={item.comSimpleName}>
                                        {`${item.comNum}  ${item.comSimpleName}  ${item.comFullName}`}
                                    </option>
                                )):null}                                
                                </select>
                                </div>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>對帳月份</span>
                                </div>
                                <div className='col-2  ps-0'>
                                    <input type="text" id="reconciliationMonth" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.reconciliationMonth : ''}
                                    onChange={handleInputChange}/>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>入庫類別</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="kind" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.kind : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>發票號碼</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="invoiceNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.invoiceNum : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>序號</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="order" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.order : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>圖號</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="drawNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.drawNum : ''}
                                    onChange={handleInputChange}/>
                                </div>                             
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>物品名</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="itemName" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.itemName : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>單位</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="unit" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.unit : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>數量</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="amount" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.amount : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>單價</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="unitPrice" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.unitPrice : ''}
                                    onChange={handleInputChange}/>
                                </div>                             
                            </div>
                            <div className='row mb-3'>
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>金額</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="price" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.price : ''}
                                    onChange={handleInputChange}/>
                                </div>    
                                <div className='col-1 px-0 d-flex align-items-center' style={{textAlign: 'center'}}>
                                    <span>批號</span>
                                </div>
                                <div className='col-2 ps-0'>
                                    <input type="text" id="piNum" className="form-control" aria-describedby="passwordHelpInline"
                                    value = {editData ? editData.piNum : ''}
                                    onChange={handleInputChange}/>
                                </div>                              
                            </div>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <button type="submit" className="btn btn-dark">提交</button>
                                </div>
                            </div>
                        </div>
                    </form>
    )
}
function SelectFilter({setNewData,setSelectObject}) {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({
        id: 753,
        label: null,
        manufacturer_Seq: "4500001",
        manufacturer_AbbreviationName: "台日",
        manufacturer_FullName: "台灣日立江森自控股份有限公司",
        manufacturer_UniformNum: "11915305",
        manufacturer_CityCode: "33810",
        manufacturer_FaxNum: "03-3245321",
        manufacturer_BossName: null,
        manufacturer_ContactName: null,
        manufacturer_CompanyAddress: "台北市南京東路三段63號",
        manufacturer_CompanyPhone: "03-3244711",
        manufacturer_CompanyFax: "03-3245321",
        manufacturer_ShipAddress: "桃園縣蘆竹鄉內厝村內溪路２９號",
        manufacturer_ShipAddress2: null,
        manufacturer_ShipAddress3: null,
        manufacturer_FactoryNum: null,
        manufacturer_FactoryFax: null,
        manufacturer_AccCode: "6116",
        manufacturer_PayMent: null
    });
    const [piNum,setPiNum] = useState('');
    const [sortOrder, setSortOrder] = useState("需求日");
    const [stockStatus, setStockStatus] = useState("未入庫");
    const [needStartDate, setNeedStartDate] = useState(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
    });
    const [pageSize,setPageSize] = useState(50);
    const [pageNum,setPageNum] = useState(1);
    const [drawNum,setDrawNum] = useState('');
    const [needEndDate, setNeedEndDate] = useState(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
    });
      
      const handleStockStatusChange = (event) => {
        setStockStatus(event.target.value);
      }
      const handleSubmit = async (event) => {
        event.preventDefault();
        // 建立一個物件來存放參數
        const params = {
            piNum, // 把 JavaScript 的驼峰命名转换成下划线命名
            orderWay: sortOrder,
            Status: stockStatus,
            needStartDate,
            needEndDate,
            drawNum,
            manufacturer: selectedOption? selectedOption.manufacturer_AbbreviationName : ""
        };
    
        // 用 params 選項將參數傳遞給後端
        const response = await axios.get(`${apiUrl}/Buyer/GetMainsWithInStock`, { params });
        console.log(response.data.buyerDtos.length > 0)
        if(response.data.buyerDtos.length > 0) {
            setNewData(response.data)
            setSelectObject(params)
        }else {
            alert('找不到資料')
        }    
        console.log(response.data)
    };

    const fetchOptions = async () => {
        try {    
          const response = await axios.get(`${apiUrl}/Manufacturer/list`);
          if (response.status === 200) {
            setOptions(response.data.manufacturers);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };


    useEffect(() => {
        fetchOptions();
    }, []);  

    const handleChange = (event, newValue) => {
        setSelectedOption(newValue);
        // if(newValue === null || newValue === undefined) return;
        // setRequest((prevRequest) => ({   
        //     ...prevRequest,
        //     Manufacturer: newValue.manufacturer_AbbreviationName,
        // })); 
    };
    const clearSubmit = async (event) => {

        // Replace with your actual endpoint
        const response = await axios.get(`${apiUrl}/Buyer/GetMainsWithNull?page=1`);
            setNewData(response.data)
            setPiNum('');
            setDrawNum('');

        console.log(response.data)
    };
    return(
        <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-6'>
                    <label htmlFor="piNum">批號
                    <input type="text"  className="form-control" id="piNum" value={piNum} onChange={(e) => setPiNum(e.target.value)}/>
                    </label>
                </div>
                <div className='col-6'>
                    <label htmlFor="piNum">圖號
                    <input type="text"  className="form-control" id="piNum" value={drawNum} onChange={(e) => setDrawNum(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-4'>
                    <label htmlFor="needStartDate">需求日起
                    <input type="text" placeholder='yyyy-mm-dd'  className="form-control" id="needStartDate" value={needStartDate} onChange={(e) => setNeedStartDate(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <label htmlFor="needEndDate">需求日迄
                    <input type="text" placeholder='yyyy-mm-dd'  className="form-control" id="needEndDate" value={needEndDate} onChange={(e) => setNeedEndDate(e.target.value)}/>
                    </label>
                </div>
                <div className='col-4'>
                    <InputLabel id="demo-simple-select-label">廠商</InputLabel>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={options}
                        sx={{ width: '100%' }}
                        value={selectedOption}
                        onChange={handleChange}
                        getOptionLabel={(option) => option.manufacturer_Seq + option.manufacturer_AbbreviationName}
                        // 设置renderInput来渲染输入元件，并确保输入框的id与label的htmlFor相匹配
                        renderInput={(params) => <TextField {...params} />}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <label htmlFor="piNum">第幾頁
                    <input type="text"  className="form-control" id="piNum" value={pageNum} onChange={(e) => setPageNum(e.target.value)}/>
                    </label>
                </div>
                <div className='col-6'>
                    <label htmlFor="piNum">一頁幾筆
                    <input type="text"  className="form-control" id="piNum" value={pageSize} onChange={(e) => setPageSize(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row mb-3'>
                排序
                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <label className={sortOrder === "需求日"? "btn btn-outline-dark active" : "btn btn-outline-dark"} htmlFor="btnradio1">需求日
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off"
                    value="需求日" checked={sortOrder === "需求日"} onChange={() => setSortOrder('需求日')}/>
                    </label>
                    <label className={sortOrder === "批號"? "btn btn-outline-dark active" : "btn btn-outline-dark"} htmlFor="btnradio2">批號
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off"
                    value="批號" checked={sortOrder === "批號"} onChange={() => setSortOrder('批號')}/>
                    </label>
                    <label className={sortOrder === "廠商"? "btn btn-outline-dark active" : "btn btn-outline-dark"} htmlFor="btnradio3">廠商
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off"
                    value="廠商" checked={sortOrder === "廠商"} onChange={() => setSortOrder('廠商')}/>
                    </label>
                    <label className={sortOrder === "圖號"? "btn btn-outline-dark active" : "btn btn-outline-dark"} htmlFor="btnradio4">圖號
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off"
                    value="圖號" checked={sortOrder === "圖號"} onChange={() => setSortOrder('圖號')}/>
                    </label>
                </div>
            </div>
            <div className='row mb-3'>
                狀況
                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <label className={stockStatus === "未入庫"? "btn btn-outline-dark active" : "btn btn-outline-dark"} htmlFor="btnradio5">未入庫
                    <input type="radio" className="btn-check" name="btnradio5" id="btnradio5" autoComplete="off"
                    value="未入庫" checked={stockStatus === "未入庫"} onChange={handleStockStatusChange}/>
                    </label>
                    <label className={stockStatus === "已入庫"? "btn btn-outline-dark active" : "btn btn-outline-dark"} htmlFor="btnradio6">已入庫
                    <input type="radio" className="btn-check" name="btnradio6" id="btnradio6" autoComplete="off"
                    value="已入庫" checked={stockStatus === "已入庫"} onChange={handleStockStatusChange}/>
                    </label>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='col-12'>
                    <button type="submit" className="btn btn-dark" style={{margin:'20px'}}>搜尋</button>
                    <button type="button" className="btn btn-dark" onClick={()=>clearSubmit()}>清除搜尋條件</button>
                </div>
            </div>
        </div>      
  </form>
    )
}


import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../../../css/matset.css'
import { apiUrl } from '../../apiUrl/ApiUrl';

export default function Item({data}) {
    const [newData,setNewData] = useState(null);
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('多重搜尋');
    const [editItemData,setEditItemData] = useState(null)
    const [drawNum, setDrawNum] = React.useState('');
    const [page,setPage] = useState(1);

    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 

    const setModelandGetDate = (newModel,drawNum) =>{
        setModel(newModel);
        if(drawNum == null)
        {
            axios.get(`${apiUrl}/Machine/list`)
            .then(response => {
                if(response.data.statusCode === 200) {
    
                    setNewData(response.data);
    
                }else {
                    alert('失敗，請檢查網路問題')
                }
            })
            .catch(error => {
                // handle error
                alert(error);
            });
        }

    }

    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                    <ChooseModel setModel={setModel} setNewData={setNewData}/>
                </div>
                <div className='col-9'>
                    {model === '多重搜尋' ? 
                    <>                 
                    <SelectMainsForm setNewData={setNewData} setDetailData={setDetailData} page={page} setPage={setPage} setDrawNum={setDrawNum} drawNum={drawNum}/>
                    {/* <SelectMainForm setNewData={setNewData} setDetailData={setDetailData} page={page} setPage={setPage} setDrawNum={setDrawNum} drawNum={drawNum}/> */}
                    </> : null}  
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">調價紀錄</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {detailData != null ? <DetailListTable data={detailData} setDetailData={setDetailData}/> : <div>目前沒有副表資料</div>}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">料品表單</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null?<ListTable setDetailData={setDetailData} setNewData={setNewData} data={newData || data} setModel={setModelandGetDate} page={page} setPage={setPage} 
                    setEditItemData={setEditItemData} setDrawNum={setDrawNum} drawNum={drawNum}/> :<div>取得資料中</div>}
                </div>              
            </div>
        </div>     
    )
}
function ListTable({data,setNewData,setModel,setDetailData,page,setPage,setEditItemData,setDrawNum,drawNum}) {
    const [updateData,setUpdateData]= useState(null)
    const [open, setOpen] = React.useState(false);
    const [request,setRequest] = React.useState({
        id:0,
        itemName:'',
        client:'',
        price:0,
        drawNum:''
    });

    const handlePrint = () => {
        axios.get(`${apiUrl}/SellPrice/CreateExcel?drawNum=${drawNum}`, {
            responseType: 'blob' // Tell axios to expect a Blob
        })
        .then(response => {
            if (response.data) {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
                const day = String(today.getDate()).padStart(2, '0');
                const filename = `售價調價紀錄${year}${month}${day}.xlsx`;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', filename); // or any other filename you want
                document.body.appendChild(link);
                link.click();
                alert('列印成功');
            } else {
                alert('列印失敗');
            }
        })
        .catch(error => {
            console.log(error);
            alert('發生錯誤');
        });
    };
    
    const handleInputChange = (event, propertyName) => {
        const value = event.target.value;
    
        // 当 propertyName 是 'price' 时，确保输入是数字
        if (propertyName === 'price') {
            // 使用正则表达式来验证输入是否为数字
            // 如果不是数字，就不更新状态
            if (!/^\d*\.?\d*$/.test(value)) return;
        }
        if(propertyName === 'drawNum') {
            setDrawNum(value);
        }
        setRequest((prevData) => ({
            ...prevData,
            [propertyName]: value,
            id:updateData.id
        }));
    };
    

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnMargin = {
        marginLeft: '2%'
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }


    const nextPage = () => {

        axios.get(`${apiUrl}/SellPrice/list?page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
    }

    const prePage = () => {

        axios.get(`${apiUrl}/SellPrice/list?page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
    }
    const handleSalarySubmit = async () => {   
        try {
            request.user = sessionStorage.getItem('userName');
            const response = await axios.post(`${apiUrl}/SellPrice/EditItem`, request)
            if (response.status === 200) {
                alert('成功')
                handleClose();
            }
        } catch (error) {
            console.error("Error logging in:", error);
            if (error.response) {         
              console.error('Server Response', error.response);
              const serverMessage = error.response.data;
            }
        }
    }

    const getDetail = (drawNum) => { // THCD-2742-A
        setDrawNum(drawNum);
        axios.get(`${apiUrl}/SellPrice/detailList?drawNum=${drawNum}`)           
        .then(response => {
            console.log(response.data)
            if(response.data.statusCode === 200) {               
                setDetailData(response.data)
                setPage(page-1)
            }else
            {
                alert('找不到該物品的調價紀錄')
            }           
        })
        .catch(error =>{
            // handle error
            alert('找不到該物品的調價紀錄')
            console.log(error);
        })
    }
    const handleDelete = (main) => {
        // 建立要傳送的物件
        const Data = {
          drawNum: main.drawNum,
        };
    
  
        axios.get(`${apiUrl}/SellPrice/DeleteSellItem?drawNum=${Data.drawNum}`)    
          .then((response) => {
            // 處理刪除成功的回應console.log(response.data)
              if (response.data === 200) {
                  axios
                      .get(`${apiUrl}/SellPrice/list?page=${1}`)
                      .then(response => {
                          // Update the data
                          setNewData(response.data); 
                          alert('刪除成功');
                          setPage(1)
                      })
                      .catch(error => {
                          // handle error
                          console.log(error);
                      });
              } else {
                  alert('刪除失敗，找不到可刪除物件');
              }
          })
          .catch((error) => {
            // 處理刪除失敗的回應
            alert('刪除失敗，找不到可刪除物件')
            console.error(error);
          });
      };  
    const handleEdit = (main) => {
        setUpdateData(main);
        handleClickOpen();
    };  

    useEffect(() => {
        if(updateData) {
            console.log(updateData)
            setRequest({
                id:updateData.id,
                price:updateData.price,
                drawNum:updateData.drawNum,
                itemName:updateData.itemName,
                client:updateData.client
            })
        }
      }, [updateData]);

    return(
        <>
            <button type="button" style={btnMargin} className="btn btn-warning" onClick={() => handlePrint()}>Excel調價紀錄</button>
            <div style={{overflowX: "auto", height: "70vh"}}>
                <table className="table" style={{width: "100%"}}>
                    <thead className="sticky-header">
                    <tr>
                        <th scope="col" style={thStyle.centered}>#</th>
                        <th scope="col" style={thStyle.centered}>物品名</th>
                        <th scope="col" style={thStyle.centered}>客戶</th>
                        <th scope="col" style={thStyle.centered}>售價</th>
                        <th scope="col" style={thStyle.centered}>圖號</th>
                        <th scope="col" style={thStyle.centered}>操作</th>
                    </tr>
                </thead>
                <tbody>
                    {data.items === null?
                    <tr key={uuidv4()}> 
                        <th scope="row" style={thStyle.centered}>{1}</th>
                        <td style={thStyle.centered}>{data.item.itemName}</td>  
                        <td style={thStyle.centered}>{data.item.client}</td>
                        <td style={thStyle.centered}>{data.item.price}</td>
                        <td style={thStyle.centered}>{data.item.drawNum}</td>
                        <td style={thStyle.centered}>
                                <button type="button" className="btn btn-warning" onClick={() => handleEdit(data.item)}>編輯</button>
                                <button type="button" style={btnMargin} className="btn btn-danger" onClick={() => handleDelete(data.item)}>刪除</button>
                                <button type="button" style={btnMargin} className="btn btn-warning" onClick={() => getDetail(data.item.drawNum)}>調價紀錄</button>
                            </td>
                    </tr>: (data.items.map((item, index) => (
                        <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>{index + 1}</th>
                            <td style={thStyle.centered}>{item.itemName}</td>   
                            <td style={thStyle.centered}>{item.client}</td>
                            <td style={thStyle.centered}>{item.price}</td>
                            <td style={thStyle.centered}>{item.drawNum}</td>
                            <td style={thStyle.centered}>
                                <button type="button" className="btn btn-danger" onClick={() => handleDelete(item)}>刪除</button>
                                <button type="button"  style={btnMargin}className="btn btn-warning" onClick={() => handleEdit(item)}>編輯</button>
                                <button type="button" style={btnMargin} className="btn btn-warning" onClick={() => getDetail(item.drawNum)}>調價紀錄</button>
                            </td>
                        </tr>
                        )))}
                    </tbody>
                </table>
            </div>
            <div colSpan="6" style={{textAlign: 'center'}}>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>修改資料</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    style={{margin:'1%'}}
                    label="物品名"
                    variant="outlined"
                    value={request.itemName}
                    onChange={(e) => handleInputChange(e, 'itemName')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    style={{margin:'1%'}}
                    label="客戶"
                    variant="outlined"
                    value={request.client}
                    onChange={(e) => handleInputChange(e, 'client')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    style={{margin:'1%'}}
                    label="售價"
                    variant="outlined"
                    value={request.price}
                    onChange={(e) => handleInputChange(e, 'price')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    style={{margin:'1%'}}
                    label="圖號"
                    variant="outlined"
                    value={request.drawNum}
                    onChange={(e) => handleInputChange(e, 'drawNum')}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>取消</Button>
                <Button onClick={handleSalarySubmit}>送出</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

function DetailListTable({ data,setDetailData }) {
    const btnMargin = {
      marginLeft: '2%',
    };
    const thStyle = {
      centered: {
        textAlign: 'center',
      },
    };
    console.log(data)
    const handleDelete = (detail) => {
      // 建立要傳送的物件
      const Data = {
        drawNum: detail.drawNum,
        pricePerson: detail.pricePerson,
        date: detail.date,
        price: detail.price,
      };
      console.log(detail.drawNum)
      // 發送 POST 請求到後端 API
      axios
        .get(`${apiUrl}/SellPrice/DeleteSellPrice?drawNum=${detail.drawNum}&date=${detail.date}&&price=${detail.price}`)
        .then((response) => {
            console.log(response.data)
          // 處理刪除成功的回應console.log(response.data)
            if (response.data=== 200) {
                axios.get(`${apiUrl}/SellPrice/detailList?drawNum=${detail.drawNum}`) 
                    .then(response => {
                        // Update the data
                        setDetailData(response.data);
                        alert('刪除成功');
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                    });
            } else {
                alert('刪除失敗，找不到可刪除物件');
            }
        })
        .catch((error) => {
          // 處理刪除失敗的回應
          alert('刪除失敗，找不到可刪除物件')
          console.error(error);
        });
    };
  
    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={thStyle.centered}>
              #
            </th>
            <th scope="col" style={thStyle.centered}>
              日期
            </th>
            <th scope="col" style={thStyle.centered}>
              圖號
            </th>
            <th scope="col" style={thStyle.centered}>
              價格
            </th>
            <th scope="col" style={thStyle.centered}>
              調價人員
            </th>
            <th scope="col" style={thStyle.centered}>
              操作
            </th>
          </tr>
        </thead>
        <tbody>
        {data.sellPriceList != null ? (
            data.sellPriceList.map((detail, index) => (
                <tr key={uuidv4()}>
                <th scope="row" style={thStyle.centered}>
                    {index + 1}
                </th>
                <td style={thStyle.centered}>{detail.date}</td>
                <td style={thStyle.centered}>{detail.drawNum}</td>
                <td style={thStyle.centered}>{detail.price}</td>
                <td style={thStyle.centered}>{detail.pricePerson}</td>
                <td style={thStyle.centered}>
                    <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleDelete(detail)}
                    >
                    刪除
                    </button>
                </td>
                </tr>
            ))
            ) : (
            <tr>
                <td colSpan="6" style={thStyle.centered}>目前沒有副表資料</td>
            </tr>
            )}

        </tbody>
      </table>
    );
  }
// onClick={()=>setModel('主表新增')}
function ChooseModel({ setModel,setNewData }) {
    const [selected, setSelected] = useState('');

    const handleChange = (event) => {
        setSelected(event.target.id);
    };
    const selectModel = (event) => {
        if (event.target.id === "btnradio4") {
            setModel('多重搜尋')
        }
    }
        
    return (
        <div className="btn-group-vertical">
            <label className={selected === 'btnradio4' ? "btn btn-outline-secondary active" : "btn btn-outline-secondary"} htmlFor="btnradio4">
                <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio4"
                    checked={selected === 'btnradio4'}
                    onChange={handleChange}
                    autoComplete="off"
                    onClick={selectModel}
                />
                <strong>多重搜尋</strong>
            </label>
        </div>
    );
}

function InsertMainForm({setNewData}) {
    const [errorMessage1, setErrorMessage1] = useState('');
    const [errorMessage2, setErrorMessage2] = useState('');
    const [errorMessage3, setErrorMessage3] = useState('');
    const [errorNumMessage, setErrorNumMessage] = useState('');
    const handleSubmit = event => {
        let drawNum;
        let company;
        let price;
        let engineering;
        let choose;
        
        if(event.target.elements.drawNum.value.trim() !== null) {
            drawNum = event.target.elements.drawNum.value;
        }else {
            drawNum = null;
        }

        if(event.target.elements.company.value.trim() !== null) {
            company = event.target.elements.company.value;
        }else {
            company = null;
        }

        if(event.target.elements.price.value.trim() !== null) {
            price = event.target.elements.price.value;
        }else {
            price = null;
        }

        if(event.target.elements.engineering.value.trim() !== null) {
            engineering = event.target.elements.engineering.value;
        }else {
            engineering = null;
        }
        if( drawNum !== null && company !== null && price !== null && engineering !== null && !Number.isNaN(price)) {
            const data = {
                drawNum,
                company,
                price,
                engineering,
                choose,
            };
            axios
                .post(`${apiUrl}/PurchasePrice/createMain`, data)
                .then(response => {
                    // handle success
                    if (response.data === 200) {
                        axios
                            .get(`${apiUrl}/PurchasePrice/MainList`)
                            .then(response => {
                                // Update the data
                                setNewData(response.data);
                                alert('新增成功');
                            })
                            .catch(error => {
                                // handle error
                                console.log(error);
                            });
                    } else {
                        alert('新增失敗，已有相同的廠商報價');
                    }
                })
                .catch(error => {
                    // handle error
                    alert('新增失敗，已有相同的廠商報價');
                });
        }

    }

    const checkValue1 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage1('欄位不得為空白')
        }else{
            setErrorMessage1('')
        }
    };
    const checkValue2 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage2('欄位不得為空白')
        }else{
            setErrorMessage2('')
        }
    };
    const checkValue3 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage3('欄位不得為空白')
        }else{
            setErrorMessage3('')
        }
    };

    const checkNumValue = event => {
        const value = event.target.value.trim();
        if (value === '') {
            setErrorNumMessage('欄位不得為空白');
        } else if (!/^\d+(\.\d+)?$/.test(value)) {
            setErrorNumMessage('請輸入有效的小數或是整數');
        } else {
            setErrorNumMessage('');
        }
    };
    return(
        <form onSubmit={handleSubmit}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="drawNum" className="form-label">圖號
                                <input type="text" className="form-control" id="drawNum" onBlur={checkValue1} />
                                <div id="emailHelp" className="form-text">{errorMessage1}</div>
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="company" className="form-label">廠商
                                <input type="text" className="form-control" id="company" onBlur={checkValue2} />
                                <div id="emailHelp" className="form-text">{errorMessage2}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="price" className="form-label">進價
                                <input type="text" className="form-control" id="price" onBlur={checkNumValue} />
                                <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                            </label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="engineering" className="form-label">工程內容
                                <input type="text" className="form-control" id="engineering" onBlur={checkValue3} />
                                <div id="emailHelp" className="form-text">{errorMessage3}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-3'/>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="choose" className="form-label">選
                                <input type="text" className="form-control" id="choose" />
                                <div id="emailHelp" className="form-text"/>
                            </label>
                        </div>
                    </div>  
                    <div className='col-3'/>
                </div>
                <div className='row'>
                    <div className='col-5'/>
                    <div className='col-2'>
                        <button type="submit" className="btn btn-dark">提交</button>
                    </div>
                    <div className='col-5'/>
                </div>
            </div>
        
      </form>
    )
}


function SelectMainForm({setNewData,setDetailData,page,setPage,setDrawNum,drawNum}) {
    const [errorMessage1, setErrorMessage1] = useState('');
    const handleSubmit = event => {
        let drawNum;

        if(event.target.elements.drawNum.value.trim() !== null) {
            drawNum = event.target.elements.drawNum.value;
        }else {
            drawNum = null;
        }
        if( drawNum !== null) {
            axios
                .get(`${apiUrl}/SellPrice/main?drawNum=${drawNum}`)
                
                .then(response => {
                    console.log(response.data)
                    // handle success
                    if(response.data.statusCode === 200)
                    {
                        setNewData(response.data);
                        setDetailData(null);
                        setPage(1);
                        alert('搜尋成功');
                    }
                })
                .catch(error => {
                    // handle error
                    alert('搜尋失敗，找不到物件');
                });
        }

    }

    const checkValue1 = event => {
        if (!event.target.value.trim()) {
            setErrorMessage1('欄位不得為空白')
        }else{
            setErrorMessage1('')
        }
    };

    return(
        <form onSubmit={handleSubmit}>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-3'/>
                    <div className='col-6'>
                        <div className="mb-3">
                            <label htmlFor="drawNum"  className="form-label">圖號
                                <input type="text" className="form-control" id="drawNum" onBlur={checkValue1} value={drawNum} onChange={(e)=> setDrawNum(e.target.value)}/>
                                <div id="emailHelp" className="form-text">{errorMessage1}</div>
                            </label>
                        </div>
                    </div>
                    <div className='col-3'/>
                </div>
                <div className='row' >
                    <div className='col-3'/>
                    <div className='col-6'>
                        <button type="submit" className="btn btn-dark">提交</button>
                    </div>
                </div>
            </div>      
      </form>
    )
}

function SelectMainsForm({setNewData,setDetailData,page,setPage}) {
    const [itemName,setItemName] = useState('')
    const [client,setClient] = useState('')
    const [drawNum,setDrawNum] = useState('')
    const [price,setPrice] = useState(0)

    const handlePrint = () => {
        axios.get(`${apiUrl}/SellPrice/CreateExcel2?itemName=${itemName}&client=${client}&price=${price}&drawNum=${drawNum}`, {
            responseType: 'blob' // Tell axios to expect a Blob
        })
        .then(response => {
            if (response.data) {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
                const day = String(today.getDate()).padStart(2, '0');
                const filename = `售價一覽表${year}${month}${day}.xlsx`;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', filename); // or any other filename you want
                document.body.appendChild(link);
                link.click();
                alert('列印成功');
            } else {
                alert('列印失敗');
            }
        })
        .catch(error => {
            console.log(error);
            alert('發生錯誤');
        });
    };
    const handleSubmit = (event) => {
            event.preventDefault();  // 阻止表单默认提交行为
            if (!itemName && !client && !price && !drawNum) {
                alert('搜尋條件不得全部為空');
                return; 
            }
            axios
                .get(`${apiUrl}/SellPrice/mains?itemName=${itemName}&client=${client}&price=${price}&drawNum=${drawNum}`)
                
                .then(response => {
                    console.log(response.data)
                    // handle success
                    if(response.data.statusCode === 200)
                    {
                        setNewData(response.data);
                        setDetailData(null);
                        setPage(1);
                        alert('搜尋成功');
                    }
                })
                .catch(error => {
                    // handle error
                    alert('搜尋失敗，找不到物件');
                });


    }

    return(
        <form onSubmit={handleSubmit}>
            <div className='container-fluid'>
                <div className='row'>
                <div className='col-4'>
                        <div className="mb-3">
                            <label htmlFor="drawNum"  className="form-label">物品名
                                <input type="text" className="form-control" id="itemName" value={itemName} onChange={(e)=>setItemName(e.target.value)} />
                            </label>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="mb-3">
                            <label htmlFor="drawNum"  className="form-label">客戶
                                <input type="text" className="form-control" id="client" value={client} onChange={(e)=>setClient(e.target.value)} />
                            </label>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="mb-3">
                            <label htmlFor="drawNum"  className="form-label">售價
                                <input type="text" className="form-control" id="price" value={price} onChange={(e)=>setPrice(e.target.value)} />
                            </label>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className="mb-3">
                            <label htmlFor="drawNum"  className="form-label">圖號
                                <input type="text" className="form-control" id="price" value={drawNum} onChange={(e)=>setDrawNum(e.target.value)} />
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row' >
                    <div className='col-3'/>
                    <div className='col-6'>
                        <button type="submit" className="btn btn-dark">提交</button>
                        <button type="button" onClick={()=>handlePrint()} className="btn btn-dark">售價一覽表excel</button>
                    </div>
                </div>
            </div>      
      </form>
    )
}

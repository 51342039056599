import * as React from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ClearIcon from '@mui/icons-material/Clear';
import CalculateIcon from '@mui/icons-material/Calculate';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Dialog from '@mui/material/Dialog';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg, pdf } from '@react-pdf/renderer';
import { apiUrl } from '../../apiUrl/ApiUrl';


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const columns = [
  
  {
    field: '承製公司',
    headerName: '承製公司',
    width: 100,
    editable: true,
  },
  {
    field: '客戶',
    headerName: '客戶',
    width: 80,
    editable: true,
  },
  {
    field: '本月出貨',
    headerName: '本月出貨',
    type: 'number',
    width: 140,
    editable: true,
  },
  {
    field: '已請款',
    headerName: '已請款',
    type: 'number',
    width: 140,
    editable: true,
  },
  {
    field: '本月請款',
    headerName: '本月請款',
    type: 'number',
    width: 140,
    editable: true,
  },
  {
    field: '本月未請款',
    headerName: '本月未請款',
    type: 'number',
    width: 150,
    editable: true,
  },
  {
    field: '前月之前累計未請款',
    headerName: '前月之前累計未請款',
    type: 'number',
    width: 120,
    editable: true,
  },
  {
    field: '前月於本月請款',
    headerName: '前月於本月請款',
    type: 'number',
    width: 120,
    editable: true,
  },
  {
    field: '前月累計未請款',
    headerName: '前月累計未請款',
    type: 'number',
    width: 120,
    editable: true,
  },
  {
    field: '預收貨款',
    headerName: '預收貨款',
    type: 'number',
    width: 120,
    editable: true,
  },
  {
    field: '本月發票金額',
    headerName: '本月發票金額',
    type: 'number',
    width: 150,
    editable: true,
  },
  {
    field: '銷項稅額',
    headerName: '銷項稅額',
    type: 'number',
    width: 120,
    editable: true,
  },
  {
    field: '合計',
    headerName: '合計',
    type: 'number',
    width: 160,
    editable: true,
  },
  {
    field: 'memo',
    headerName: '備註',
    width: 110,
    editable: true,
  },
  { field: 'id', headerName: 'ID', width: 90 },
];


export default function ReceviableAccountTotal() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const [value, setValue] = React.useState(dayjs(formattedDate));
    const [editedRows, setEditedRows] = React.useState([]);
    const[rows,setRows] = React.useState([]);
    const[bills,setBills] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [detailOpen, setDetailOpen] = React.useState(false);
    const [前月之前累計未請款,set前月之前累計未請款] = React.useState(0);
    const [前月於本月請款,set前月於本月請款] = React.useState(0);
    const [前月累計未請款,set前月累計未請款] = React.useState(0);
    const [預收貨款,set預收貨款] = React.useState(0);
    const [本月發票金額,set本月發票金額] = React.useState(0);
    const [銷項稅額,set銷項稅額] = React.useState(0);
    const [本月出貨,set本月出貨] = React.useState(0);
    const [已請款,set已請款] = React.useState(0);
    const [本月請款,set本月請款] = React.useState(0);
    const [本月未請款,set本月未請款] = React.useState(0);
    const [合計,set合計] = React.useState(0);
    const [com,setCom] = React.useState('');
    const [cus,setCus] = React.useState('');
    const [mon,setMon] = React.useState('');
    const [total,setTotal] = React.useState(0);
    const [moneyTotal,setMoneyTotal] = React.useState(0);
    const [billData,setBillDate] =React.useState([]);
    const [billTotal,setBillTotal] =React.useState(0);
    const handleClose = () => {
      setOpen(false);
      setDetailOpen(false);
    };

    const handleSave = async () => {   
      try {
        const response = await axios.post(`${apiUrl}/ReceviableAccount/EditTotalMoneyBill`, editedRows);
        if(response.status === 200) {     
          handleSearch();
          setEditedRows([]);
        }else {
          alert('修改失敗');
        }
  
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        alert('修改失敗');
      }
    };
    const handleDetails = async () => {
      if (!com || !cus || !mon) {
        alert('尚未選擇任何資料');
        return;
      }
      try {
        const response = await axios.get(`${apiUrl}/ReceviableAccount/BillDetails`, {
          responseType: 'blob',
          params: {
            com,
            client: cus,
            month: value.format('YYYY/MM')
          }
        });
    
        if (response.status === 200) {
          const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfUrl);
        } else {
          alert('搜尋失敗');
        }
      } catch (error) {
        console.error('Failed to fetch PDF:', error);
        alert('搜尋失敗');
      }
    };
    
    const handleSearch = async () => {
      try {
        const response = await axios.get(`${apiUrl}/ReceviableAccount/GetTotalMoneyBill?month=${value.format('YYYY/MM')}`);
        if (response.status === 200) {
          console.log(response.data);
    
          const total前月之前累計未請款 = response.data.reduce((acc, item) => acc + parseFloat(item.前月之前累計未請款 || 0), 0);
          const total前月於本月請款 = response.data.reduce((acc, item) => acc + parseFloat(item.前月於本月請款 || 0), 0);
          const total前月累計未請款 = response.data.reduce((acc, item) => acc + parseFloat(item.前月累計未請款 || 0), 0);
          const total預收貨款 = response.data.reduce((acc, item) => acc + parseFloat(item.預收貨款 || 0), 0);
          const total本月發票金額 = response.data.reduce((acc, item) => acc + parseFloat(item.本月發票金額 || 0), 0);
          const total銷項稅額 = response.data.reduce((acc, item) => acc + parseFloat(item.銷項稅額 || 0), 0);
          const total本月出貨 = response.data.reduce((acc, item) => acc + parseFloat(item.本月出貨 || 0), 0);
          const total已請款 = response.data.reduce((acc, item) => acc + parseFloat(item.已請款 || 0), 0);
          const total本月請款 = response.data.reduce((acc, item) => acc + parseFloat(item.本月請款 || 0), 0);
          const total本月未請款 = response.data.reduce((acc, item) => acc + parseFloat(item.本月未請款 || 0), 0);
          const total合計 = response.data.reduce((acc, item) => acc + parseFloat(item.合計 || 0), 0);

          const formatter = new Intl.NumberFormat('zh-TW', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    
          // 添加空白行和總計行
          const totalRow = {
            id:"",
            memo:"",
            承製公司: "合計",
            客戶:"",
            前月之前累計未請款: formatter.format(total前月之前累計未請款),
            前月於本月請款: formatter.format(total前月於本月請款),
            前月累計未請款: formatter.format(total前月累計未請款),
            預收貨款: formatter.format(total預收貨款),
            本月發票金額: formatter.format(total本月發票金額),
            銷項稅額: formatter.format(total銷項稅額),
            本月出貨: formatter.format(total本月出貨),
            已請款: formatter.format(total已請款),
            本月請款: formatter.format(total本月請款),
            本月未請款: formatter.format(total本月未請款),
            合計: formatter.format(total合計),
             
          };
    
          const emptyRow = {}; // 空白行
    
          // 將資料與空白行和總計行組合
          const updatedRows = [...response.data, totalRow];
    
          setRows(updatedRows);
    
          alert('搜尋成功');
        } else {
          alert('搜尋失敗');
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        alert('搜尋失敗');
      }
    };
    

    const handleBillPrint = async () => {
      try {
        const response = await axios.get(`${apiUrl}/ReceviableAccount/GetTotalMoneyBillPrint?month=${value.format('YYYY/MM')}`, {
          responseType: 'blob' // 确保接收 PDF 文件
        });
    
        if (response.status === 200) {
          // 创建 Blob URL 并在新标签页打开
          const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          
          // 打开新标签页
          const newWindow = window.open(pdfUrl, '_blank');
          
          // 检查新窗口是否成功打开
          if (newWindow) {
            newWindow.onload = () => {
              alert('列印成功');
            };
          } else {
            alert('新頁被阻止，请允许弹出窗口。');
          }
        } else {
          alert('列印失敗');
        }
      } catch (error) {
        console.error('Failed to fetch PDF:', error);
        alert('列印失敗');
      }
    };
    
    

    const handleReCaculate = async () => {
      try {
        const response = await axios.get(`${apiUrl}/ReceviableAccount/ReCalculate?month=${value.format('YYYY/MM')}`);
        if(response.status === 200) {
          console.log(response.data )
          setRows(response.data);
          const total前月之前累計未請款 = response.data.reduce((acc, item) => acc + parseFloat(item.前月之前累計未請款 || 0), 0);
          const total前月於本月請款 = response.data.reduce((acc, item) => acc + parseFloat(item.前月於本月請款 || 0), 0);
          const total前月累計未請款 = response.data.reduce((acc, item) => acc + parseFloat(item.前月累計未請款 || 0), 0);
          const total預收貨款 = response.data.reduce((acc, item) => acc + parseFloat(item.預收貨款 || 0), 0);
          const total本月發票金額 = response.data.reduce((acc, item) => acc + parseFloat(item.本月發票金額 || 0), 0);
          const total銷項稅額 = response.data.reduce((acc, item) => acc + parseFloat(item.銷項稅額 || 0), 0);
          const total本月出貨 = response.data.reduce((acc, item) => acc + parseFloat(item.本月出貨 || 0), 0);
          const total已請款 = response.data.reduce((acc, item) => acc + parseFloat(item.已請款 || 0), 0);
          const total本月請款 = response.data.reduce((acc, item) => acc + parseFloat(item.本月請款 || 0), 0);
          const total本月未請款 = response.data.reduce((acc, item) => acc + parseFloat(item.本月未請款 || 0), 0);
          const total合計 = response.data.reduce((acc, item) => acc + parseFloat(item.合計 || 0), 0);
          set前月之前累計未請款(total前月之前累計未請款);
          set前月於本月請款(total前月於本月請款);
          set前月累計未請款(total前月累計未請款);
          set預收貨款(total預收貨款);
          set本月發票金額(total本月發票金額);
          set銷項稅額(total銷項稅額);
          set本月出貨(total本月出貨);
          set已請款(total已請款);
          set本月請款(total本月請款);
          set本月未請款(total本月未請款);
          set合計(total合計);
          alert('計算成功');
        }else {
          alert('計算失敗');
        }
  
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        alert('搜尋失敗');
      }
    };

    const processRowUpdate = (newRow, oldRow) => {
      // 透過 newRow 的 id 找到 editedRows 陣列中的索引
      const index = editedRows.findIndex(row => row.id === newRow.id);
    
      // 若找到相同的 id，則先刪除
      if (index > -1) {
        editedRows.splice(index, 1);
      }
    
      // 將 newRow 加入 editedRows 陣列
      setEditedRows([...editedRows, newRow]);
      return newRow;
    };



    const isDisabled = editedRows.length === 0;
    React.useEffect(() => {
      if(open) {
        const invoiceAmountsByCompany = rows.reduce((acc, row) => {
          const company = row["承製公司"];
          // 只處理承製公司不為 null 的情況
          if (company) {
              if (!acc[company]) {
                  acc[company] = 0;
              }
              acc[company] += row["本月發票金額"];
          }
          return acc;
        }, {});
      
      // 將結果轉換為物件陣列
        const result = Object.keys(invoiceAmountsByCompany).map(company => ({
            承製公司: company,
            發票金額總額: invoiceAmountsByCompany[company]
        }));
        const totalAmount = result.reduce((acc, item) => acc + item.發票金額總額, 0);
        console.log(totalAmount)
        console.log(result)
        setBillTotal(totalAmount);
        setBillDate(result);
      }else {
        setBillTotal(0);
        setBillDate([]);
      }
  }, [open]); 
    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Box sx={{ '& button': { m: 1 } }}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                            <DatePicker
                            label="月份選擇"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleSearch}>
                        搜尋
                    </Button>
                    <Button variant="contained" endIcon={<CalculateIcon/>}  onClick={handleReCaculate}>
                        重新計算
                    </Button>
                    <Button variant="outlined" disabled={isDisabled} onClick={()=>setEditedRows([])}  startIcon={<ClearIcon />}> 
                      取消更新
                    </Button>
                    <Button variant="contained" endIcon={<SaveIcon />} disabled={isDisabled} onClick={handleSave}>
                        保存
                    </Button>
                    <Button variant="contained" endIcon={<FactCheckIcon />} onClick={handleBillPrint}>
                        總表
                    </Button>
                    <Button variant="contained" endIcon={<ReceiptLongIcon />} onClick={handleDetails}>
                        明細
                    </Button>
                    <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>
                            <Button autoFocus color="inherit" onClick={handleClose}>
                            列印預覽
                            </Button>
                        </Toolbar>
                        </AppBar>
                        <PDFViewer style={{ width: '100%', height: '100%' }}>
                            <MyDocument data={rows} day={value.format('YYYY/MM/DD')} totalAmount={billTotal} a ={前月之前累計未請款} b={前月於本月請款} 
                            c={前月累計未請款} d={預收貨款} e={本月發票金額} f={銷項稅額} g={本月出貨} h={已請款} i={本月請款} j={本月未請款} k={合計} bill={billData}/>
                        </PDFViewer> 
                    </Dialog>

                    <Dialog
                        fullScreen
                        open={detailOpen}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>
                            <Button autoFocus color="inherit" onClick={handleClose}>
                            列印預覽
                            </Button>
                        </Toolbar>
                        </AppBar>
                        <PDFViewer style={{ width: '100%', height: '100%' }}>
                            <MyBillDocument data={bills} day={value.format('YYYY/MM/DD')} cus={cus} total={total} money={moneyTotal}/>
                        </PDFViewer> 
                    </Dialog>
                </div>
            </Box>
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 15,
                },
            },
            }}
            pageSizeOptions={[15,25,35]}
            disableRowSelectionOnClick
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={error=>alert(error)}
            onRowEditCommit={(rowId, e) => {
              handleSave(rowId,e);
            }}
            onCellClick={(params, event) => {
              setCom(params.row.承製公司);
              setCus(params.row.客戶);
              setMon(value.format('YYYY/MM'));
            }}
        />
        </Box>
    );
}



Font.register({
  family: 'TaipeiSansTCBeta-Bold',
  src: 'path/to/TaipeiSansTCBeta-Bold.ttf', // 替换为您的实际字体文件路径
});
Font.register({
  family: 'TaipeiSansTCBeta-Light',
  src: 'path/to/TaipeiSansTCBeta-Light.ttf', // 替换为您的实际字体文件路径
});
// 页面宽高转换为点（1cm = 28.28点）
const pageWidth = 24.1 * 72 / 2.54;
const pageHeight = 14 * 72 / 2.54;

// 表格宽度比例，根据您的表格设计调整
const cellWidth = pageWidth / 6; // 假设有8列

const styles = StyleSheet.create({
  page: {
      flexDirection: 'column',
      backgroundColor: 'white',
      padding: 24,
      width: pageWidth,
      height: pageHeight,
  },
  onePage: {
      flexDirection: 'row',
      backgroundColor: 'white',
      padding: 24,
      width: pageWidth,
      height: pageHeight,
  },
  title: {
      fontSize: 20,
      textAlign: 'center',
      fontFamily: 'TaipeiSansTCBeta-Bold',
      marginBottom: 10,
  },
  smallTitle: {
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'TaipeiSansTCBeta-Light',
    marginBottom: 10,
  },
  tableContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      fontFamily: 'TaipeiSansTCBeta-Bold', // 新增此行以确保容器内的内容（标题）居中
      width: '100%', // 确保容器宽度充满整个页面或其父容器
  },  
  headerRow: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 5, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerDashRow: {
    flexDirection: 'row',
    // borderBottomWidth: 1,
    // borderTopWidth: 1,
    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    // borderColor: 'black',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 5, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerBottomRow: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 120, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerRowDash: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 10, // 标题和标头之间的空间
    // borderTop: 1, // 添加上边框
    // borderBottom: 1, // 添加下边框
    // borderColor: 'black', // 边框颜色
    // paddingTop: 5, // 标头内容与上边框之间的空间
    // paddingBottom: 5, // 标头内容与下边框之间的空间
  },
  headerDashItem: {
    flex: 1, // 平分容器宽度
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
    // borderWidth: 1,
    // borderColor: 'black',
},
  headerItem: {
      flex: 1, // 平分容器宽度
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
  },
  header物品名: {
    width: '20%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  },
  // header子件料號: {
  //   width: '15%',
  //   textAlign: 'center', // 文本居中
  //   fontFamily: 'TaipeiSansTCBeta-Light',
  //   fontSize: 10,
  // },
  // header品名: {
  //   width: '5%',
  //   textAlign: 'center', // 文本居中
  //   fontFamily: 'TaipeiSansTCBeta-Light',
  //   fontSize: 10,
  // },
  // header規格: {
  //   width: '25%',
  //   textAlign: 'center', // 文本居中
  //   fontFamily: 'TaipeiSansTCBeta-Light',
  //   fontSize: 10,
  // },
  // header配賦量: {
  //   width: '5%',
  //   textAlign: 'center', // 文本居中
  //   fontFamily: 'TaipeiSansTCBeta-Light',
  //   fontSize: 10,
  // },
  // header單位: {
  //   width: '10%',
  //   textAlign: 'center', // 文本居中
  //   fontFamily: 'TaipeiSansTCBeta-Light',
  //   fontSize: 10,
  // },
  // header需求量: {
  //   width: '10%',
  //   textAlign: 'center', // 文本居中
  //   fontFamily: 'TaipeiSansTCBeta-Light',
  //   fontSize: 10,
  // },
  // header實領: {
  //   width: '10%',
  //   textAlign: 'center', // 文本居中
  //   fontFamily: 'TaipeiSansTCBeta-Light',
  //   fontSize: 10,
  // },
  // header備註: {
  //   width: '20%',
  //   textAlign: 'center', // 文本居中
  //   fontFamily: 'TaipeiSansTCBeta-Light',
  //   fontSize: 10,
  // },
});

const billStyles = StyleSheet.create({
  page: {
      flexDirection: 'column',
      backgroundColor: 'white',
      padding: 24,
      width: pageWidth,
      height: pageHeight,
  },
  onePage: {
      flexDirection: 'row',
      backgroundColor: 'white',
      padding: 24,
      width: pageWidth,
      height: pageHeight,
  },
  title: {
      fontSize: 20,
      textAlign: 'flex-start',
      fontFamily: 'TaipeiSansTCBeta-Bold',
      marginBottom: 10,
  },
  centerTitle: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'TaipeiSansTCBeta-Bold',
    marginBottom: 10,
  },
  smallTitle: {
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'TaipeiSansTCBeta-Light',
    marginBottom: 10,
  },
  tableContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      fontFamily: 'TaipeiSansTCBeta-Bold', // 新增此行以确保容器内的内容（标题）居中
      width: '100%', // 确保容器宽度充满整个页面或其父容器
  },  
  headerRow: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 5, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerDashRow: {
    flexDirection: 'row',
    // borderBottomWidth: 1,
    // borderTopWidth: 1,
    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    // borderColor: 'black',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 5, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerBottomRow: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 120, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerRowDash: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 10, // 标题和标头之间的空间
    // borderTop: 1, // 添加上边框
    // borderBottom: 1, // 添加下边框
    // borderColor: 'black', // 边框颜色
    // paddingTop: 5, // 标头内容与上边框之间的空间
    // paddingBottom: 5, // 标头内容与下边框之间的空间
  },
  headerDashItem: {
    flex: 1, // 平分容器宽度
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
    // borderWidth: 1,
    // borderColor: 'black',
},
  headerItem: {
      flex: 1, // 平分容器宽度
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
  },
});
const currentDate = new Date();
const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份需要加1，因为它从0开始
const currentDay = String(currentDate.getDate()).padStart(2, '0');
const currentYear = String(currentDate.getFullYear());
const dateString = `${currentYear}/${currentMonth}/${currentDay}`;
// 创建文档组件
const MyDocument = ({totalAmount, data,day,a,b,c,d,e,f,g,h,i,j,k,bill }) => ( 

  <Document>
      <Page size={{ width: 842, height: 595 }} style={styles.page}>
        <View style={styles.tableContainer}>
          <Text style={styles.title}>福蒙精密工業股份有限公司</Text> 
        </View>
        <View style={styles.tableContainer}>
          <Text style={styles.title}>應收帳款總表</Text>
        </View>
        <View style={styles.tableContainer}>
          <Text style={styles.smallTitle}>列印日期{dateString}</Text>
        </View>
        <View style={styles.headerRow}>
            <Text style={styles.headerItem}>月份:{day}</Text>
        </View>
        <View style={styles.headerDashRow}>
            <Text style={styles.headerDashItem}>承製公司</Text>
            <Text style={styles.headerDashItem}>客戶</Text>
            <Text style={styles.headerDashItem}>本月出貨</Text>
            <Text style={styles.headerDashItem}>已請款</Text>
            <Text style={styles.headerDashItem}>本月請款</Text>
            <Text style={styles.headerDashItem}>本月未請款</Text>
            <Text style={styles.headerDashItem}>前月之前<br/>累計未請款</Text>
            <Text style={styles.headerDashItem}>前月於<br/>本月請款</Text>
            <Text style={styles.headerDashItem}>前月累計<br/>未請款</Text>
            <Text style={styles.headerDashItem}>預收貨款</Text>
            <Text style={styles.headerDashItem}>本月發票金額</Text>
            <Text style={styles.headerDashItem}>銷項稅額</Text>
            <Text style={styles.headerDashItem}>合計</Text>
        </View>
      {data.map((item, index) => (
      <>
        <View style={styles.headerDashRow}>
            <Text style={styles.headerDashItem}>{item.承製公司}</Text>
            <Text style={styles.headerDashItem}>{item.客戶}</Text>
            <Text style={styles.headerDashItem}>{item.本月出貨}</Text>
            <Text style={styles.headerDashItem}>{item.已請款}</Text>
            <Text style={styles.headerDashItem}>{item.本月請款}</Text>
            <Text style={styles.headerDashItem}>{item.本月未請款}</Text>
            <Text style={styles.headerDashItem}>{item.前月之前累計未請款}</Text>
            <Text style={styles.headerDashItem}>{item.前月於本月請款}</Text>
            <Text style={styles.headerDashItem}>{item.前月累計未請款}</Text>
            <Text style={styles.headerDashItem}>{item.預收貨款}</Text>
            <Text style={styles.headerDashItem}>{item.本月發票金額}</Text>
            <Text style={styles.headerDashItem}>{item.銷項稅額}</Text>
            <Text style={styles.headerDashItem}>{item.合計}</Text>
        </View>
        </>     
        ))}
        <View style={styles.headerDashRow}>
            <Text style={styles.headerDashItem}>總計</Text>
            <Text style={styles.headerDashItem}/>
            <Text style={styles.headerDashItem}>{g}</Text>
            <Text style={styles.headerDashItem}>{h}</Text>
            <Text style={styles.headerDashItem}>{i}</Text>
            <Text style={styles.headerDashItem}>{j}</Text>
            <Text style={styles.headerDashItem}>{a}</Text>
            <Text style={styles.headerDashItem}>{b}</Text>
            <Text style={styles.headerDashItem}>{c}</Text>
            <Text style={styles.headerDashItem}>{d}</Text>
            <Text style={styles.headerDashItem}>{e}</Text>
            <Text style={styles.headerDashItem}>{f}</Text>
            <Text style={styles.headerDashItem}>{k}</Text>
        </View>
        <View style={styles.headerBottomRow}>
            <Text style={styles.headerItem}>承製公司</Text>
            <Text style={styles.headerItem}>發票金額</Text>
            <Text style={styles.headerItem}>發票稅額</Text>
            <Text style={styles.headerItem}>發票統計</Text>
        </View>
        {bill.map((item, index) => (
          <>
            <View style={styles.headerRow}>
                <Text style={styles.headerItem}>{item.承製公司}</Text>
                <Text style={styles.headerItem}>${item.發票金額總額}</Text>
                <Text style={styles.headerItem}>${Math.ceil(item.發票金額總額*0.05)}</Text>
                <Text style={styles.headerItem}>${Math.ceil(item.發票金額總額*1.05)}</Text>
            </View>
          </>     
        ))}
        <View style={styles.headerRow}>
            <Text style={styles.headerItem}>合計</Text>
            <Text style={styles.headerItem}>${totalAmount}</Text>
            <Text style={styles.headerItem}>${Math.ceil(totalAmount*0.05)}</Text>
            <Text style={styles.headerItem}>${Math.ceil(totalAmount*1.05)}</Text>
        </View>
      </Page>            
  </Document>
);

const MyBillDocument = ({ data,day,cus,total,money}) => ( 

  <Document>
      <Page size={{ width: 842, height: 595 }} style={styles.page}>
      <View style={styles.tableContainer}>
          <Text style={styles.centerTitle}>應收帳款明細表</Text>
        </View>
        <View style={styles.headerRow}>
          <Text style={styles.headerItem}>客戶:{cus}</Text> 
          <Text style={styles.headerItem}>列印日期{dateString}</Text>
        </View>
        <View style={styles.headerRow}>
            <Text style={styles.headerItem}>月份:{day}</Text>
            <Text style={styles.headerItem}>Page: 1 / 1</Text>
        </View>
        <View style={styles.headerDashRow}>
            <Text style={styles.headerDashItem}>出貨日期</Text>
            <Text style={styles.headerDashItem}>訂單號碼</Text>
            <Text style={styles.headerDashItem}>流水</Text>
            <Text style={styles.headerDashItem}>批號</Text>
            <Text style={styles.headerDashItem}>圖號</Text>
            <Text style={styles.header物品名}>物品名</Text>
            <Text style={styles.headerDashItem}>實交貨數</Text>
            <Text style={styles.headerDashItem}>訂購單價</Text>
            <Text style={styles.headerDashItem}>應收金額</Text>
            <Text style={styles.headerDashItem}>發票日期</Text>
            <Text style={styles.headerDashItem}>發票號碼</Text>
        </View>
      {data.map((item, index) => (
      <>
        <View style={styles.headerDashRow}>
            <Text style={styles.headerDashItem}>{item.出貨日期}</Text>
            <Text style={styles.headerDashItem}>{item.訂單號碼}</Text>
            <Text style={styles.headerDashItem}>{item.流水}</Text>
            <Text style={styles.headerDashItem}>{item.批號}</Text>
            <Text style={styles.headerDashItem}>{item.圖號}</Text>
            <Text style={styles.header物品名}>{item.物品名}</Text>
            <Text style={styles.headerDashItem}>{item.實交貨數}</Text>
            <Text style={styles.headerDashItem}>{item.訂購單價}</Text>
            <Text style={styles.headerDashItem}>{item.應收金額}</Text>
            <Text style={styles.headerDashItem}>{item.發票日期}</Text>
            <Text style={styles.headerDashItem}>{item.發票號碼}</Text>
        </View>
        </>     
        ))}
          <View style={styles.headerDashRow}>
            <Text style={styles.headerDashItem}/>
            <Text style={styles.headerDashItem}/>
            <Text style={styles.headerDashItem}/>
            <Text style={styles.headerDashItem}/>
            <Text style={styles.headerDashItem}/>
            <Text style={styles.headerDashItem}/>
            <Text style={styles.headerDashItem}>實交貨總數:{total}</Text>
            <Text style={styles.headerDashItem}/>
            <Text style={styles.headerDashItem}>應收總金額:{money}</Text>
            <Text style={styles.headerDashItem}/>
            <Text style={styles.headerDashItem}/>
        </View>
      </Page>            
  </Document>
);

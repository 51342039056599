import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { apiUrl } from '../../apiUrl/ApiUrl';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  

const table1Columns = [
  { field: 'id', headerName: 'ID', width: 60 },
  {
    field: 'makeCom',
    headerName: '承製公司',
    width: 100,
    editable: true,
  },
  {
    field: 'totalAmount',
    headerName: '金額',
    type: 'number',
    width: 90,
    editable: true,
  },
  {
    field: 'totalTax',
    headerName: '稅額',
    type: 'number',
    width: 90,
    editable: true,
  },
  {
    field: 'grandTotal',
    headerName: '總計',
    type: 'number',
    width: 90,
    editable: true,
  },
];
const table2Columns = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'billDate',
      headerName: '發票日期',
      width: 150,
      editable: true,
    },
    {
      field: 'billNum',
      headerName: '發票號碼',
      width: 160,
      editable: true,
    },
    {
      field: 'client',
      headerName: '客戶',
      width: 90,
      editable: true,
    },
    {
      field: 'amount',
      headerName: '金額',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
        field: 'tax',
        headerName: '稅額',
        type: 'number',
        width: 100,
        editable: true,
    },
    {
    field: 'total',
    headerName: '總計',
    type: 'number',
    width: 120,
    editable: true,
    },
];
const table3Columns = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'toShipDate',
      headerName: '出貨日期',
      width: 100,
      editable: true,
    },
    {
      field: 'toShipNum',
      headerName: '出貨單號',
      width: 100,
      editable: true,
    },
    {
      field: 'orderNum',
      headerName: '訂單號碼',
      width: 150,
      editable: true,
    },
    {
      field: 'piNum',
      headerName: '批號',
      width: 150,
      editable: true,
    },
    {
        field: 'drawNum',
        headerName: '圖號',
        width: 150,
        editable: true,
    },
    {
        field: 'itemName',
        headerName: '物品名',
        width: 170,
        editable: true,
    },
    {
        field: 'unit',
        headerName: '單位',
        width: 60,
        editable: true,
    },
    {
    field: 'realGive',
    headerName: '實交貨數',
    type: 'number',
    width: 120,
    editable: true,
    },
    {
    field: 'orderPrice',
    headerName: '訂購單價',
    type: 'number',
    width: 120,
    editable: true,
    },
    {
    field: 'amountReceivable',
    headerName: '應收金額',
    type: 'number',
    width: 120,
    editable: true,
    },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function Bill() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const [value, setValue] = React.useState(dayjs(formattedDate));
    const [groupBills,setGroupBills] = React.useState([]);
    const [detailBills,setDetailBills] = React.useState([]);
    const [toShips,setToShips] = React.useState([]);
    const [excelParam,setExcelParam] = React.useState('');
    const [selectItem,setSelectItem] = React.useState(null);

    const handleSearch = async () => {
      const formattedValue = value.format('YYYY/MM');
      try {
        const response = await axios.get(`${apiUrl}/Bill/GetBills?month=${formattedValue}`);
        console.log(response.data);
        if(response.data.statusCode === 200)  {
          setGroupBills(response.data.billMonthDtos)
        }
        // 在此处处理API返回的数据
      } catch (error) {
        console.error('There was an error!', error);
        // 在此处处理错误
      }
    };

    const handleDetailSearch = async (params) => {
      const formattedValue = value.format('YYYY/MM');
      try {
        const response = await axios.get(`${apiUrl}/Bill/GetBillsDetail?com=${params.row.makeCom}&month=${formattedValue}`);
        console.log(response.data);
        if(response.data.statusCode === 200)  {
          setDetailBills(response.data.billDtos)
        }
        // 在此处处理API返回的数据
      } catch (error) {
        console.error('There was an error!', error);
        // 在此处处理错误
      }
    };

    const handleToShipSearch = async (params) => {
      setExcelParam(params.row.billNum);
      setSelectItem(params.row)
      try {
        const response = await axios.get(`${apiUrl}/Bill/GetToShips?billNum=${params.row.billNum}`);
        console.log(response.data);
        if(response.data.statusCode === 200)  {
          setToShips(response.data.toShipDtos)
        }
        // 在此处处理API返回的数据
      } catch (error) {
        console.error('There was an error!', error);
        // 在此处处理错误
      }
    };

    const handleExcel = async () => {
      try {
        const response = await axios.get(`${apiUrl}/Bill/Excel?billNum=${excelParam}`, {
          responseType: 'blob' // Tell axios to expect a Blob
        });
    
        if (response.data) {
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JS
          const day = String(today.getDate()).padStart(2, '0');
          const filename = `發票總覽${year}${month}${day}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
    
          link.setAttribute('download', filename); // or any other filename you want
          document.body.appendChild(link);
          link.click();
          alert('列印成功');
        } else {
          alert('列印失敗');
        }
      } catch (error) {
        console.log(error);
        alert('發生錯誤 欄位不得為空');
      }
    };
    
    
  return (
    <Box sx={{ height: 900, width: '100%' }}>
         <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>              
                <Grid item xs={4}>
                    <Item style={{background:'transparent'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                <DatePicker
                                label="月份選擇"
                                value={value}
                                onChange={(newValue) => setValue(newValue)}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item style={{background:'transparent'}}>
                        <Button variant="contained" endIcon={<SendIcon />} style={{marginTop:'20px'}} onClick={handleSearch}>
                            搜尋
                        </Button>
                    </Item>
                </Grid>
                <Grid item xs={6}/>
                <Grid item xs={5}>
                    <Item>
                      <DataGrid
                      style={{width:'100%',height: 400}}
                      rows={groupBills}
                      columns={table1Columns}
                      onCellClick={handleDetailSearch}
                      initialState={{
                      pagination: {
                          paginationModel: {
                          pageSize: 5,
                          },
                      },
                      }}
                      pageSizeOptions={[5]}
                      disableRowSelectionOnClick
                      />
                    </Item>
                </Grid>
                <Grid item xs={7}>
                    <Item><DataGrid
                    rows={detailBills}
                    columns={table2Columns}
                    onCellClick={handleToShipSearch}
                    style={{width:'100%',height: 400}}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                    }}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                /></Item>
                </Grid>
                <Grid item xs={4}>
                <Item>
                    <Typography variant="h6" gutterBottom>
                        發票日期:  {selectItem ? selectItem.billDate : "未提供"}
                    </Typography>
                </Item>
                </Grid>
                <Grid item xs={4}>
                <Item>
                    <Typography variant="h6" gutterBottom>
                        發票號碼 :  {selectItem ? selectItem.billNum : "未提供"}
                    </Typography>
                </Item>
                </Grid>
                <Grid item xs={4}>
                  <Button variant="contained" endIcon={<SendIcon />} style={{marginTop:'20px'}} onClick={handleExcel}>
                      下載EXCEL
                  </Button>
                </Grid>
                <Grid item xs={12}>
                <Item>
                    <DataGrid
                    style={{width:'100%',height: 600}}
                      rows={toShips}
                      columns={table3Columns}                      
                      initialState={{
                      pagination: {
                          paginationModel: {
                          pageSize: 5,
                          },
                      },
                      }}
                      pageSizeOptions={[5]}
                      disableRowSelectionOnClick
                  />
                </Item>
                </Grid>
            </Grid>
        </Box>
      
    </Box>
  );
}
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg } from '@react-pdf/renderer';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import { apiUrl } from '../../apiUrl/ApiUrl';








const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function ToShip({data}) {
    const [newData,setNewData] = useState(null);
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('出貨交運單');
    const [page,setPage] = useState(1);
    const [rows,setRows] = useState([]);
    const [createRows,setCreateRows] = useState([]);
    const [orderRows,setOrderRows] = useState([]);
    const [coms,setComs] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [way,setWay]= useState('drawNum');
    const [num,setNum]= useState('');
    const [barCode,setBarCode]= useState('');
    const [isCreate,setIsCreate] = useState(false);
    const [editedRows, setEditedRows] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [selectedOption, setSelectedOption] =React.useState(null);
    const [month, setMonth] = useState("");

    useEffect(() => {
        console.log("Month state updated:", month);
    }, [month]);
    
    const columns = 
     [
    
    {
        field: '訂單號碼',
        headerName: '訂單號碼',
        width: 120,
        editable: true,
    },
    {
        field: '流水號',
        headerName: '流水號',
        width: 60,
        editable: true,
    },
    {
        field: '批號',
        headerName: '批號',
        width: 110,
        editable: true,
    },
    {
        field: '圖號',
        headerName: '圖號',
        width: 110,
        editable: true,
    },
    {
        field: '機種',
        headerName: '機種',
        width: 60,
        editable: true,
    },
    {
        field: '物品名',
        headerName: '物品名',
        width: 110,
        editable: true,
    },
    {
        field: '訂單數量',
        headerName: '訂單數量',
        width: 90,
        editable: true,
    },
    {
        field: '實交貨數',
        headerName: '實交貨數',
        width: 90,
        editable: true,
    },
    {
        field: '單位',
        headerName: '單位',
        width: 60,
        editable: true,
    },
    {
        field: '出貨備註',
        headerName: '出貨備註',
        width: 110,
        editable: true,
    },
    { field: 'id', headerName: 'ID', width: 90 },
    ];
    const orderColumns = 
    [
        
        {
            field: '訂單號碼',
            headerName: '訂單號碼',
            width: 110,
            editable: true,
        },
        {
            field: '流水號',
            headerName: '流水號',
            width: 70,
            editable: true,
        },
        {
            field: '批號',
            headerName: '批號',
            width: 110,
            editable: true,
        },
        {
            field: '圖號',
            headerName: '圖號',
            width: 110,
            editable: true,
        },
        {
            field: '項次',
            headerName: '項次',
            width: 60,
            editable: true,
        },
       
        {
            field: '物品名',
            headerName: '物品名',
            width: 110,
            editable: true,
        },
        {
            field: '訂單數量',
            headerName: '訂單數量',
            width: 90,
            editable: true,
        },
        {
            field: '未交貨數',
            headerName: '未交貨數',
            width: 90,
            editable: false, // 這個列不需要手動編輯
            valueGetter: (params) => {
                // 計算未交貨數
                const 訂單數量 = params.row.訂單數量 || 0;
                const 實交貨數 = params.row.實交貨數 || 0;
                return 訂單數量 - 實交貨數;
            }
        },
        {
            field: '單位',
            headerName: '單位',
            width: 110,
            editable: true,
        },
        {
            field: '機種',
            headerName: '機種',
            width: 110,
            editable: true,
        },
        {
            field: '實交貨數',
            headerName: '實交貨數',
            width: 110,
            editable: true,
        },
        {
            field: '承製公司',
            headerName: '承製公司',
            width: 110,
            editable: true,
        },
        {
            field: 'remove',
            headerName: '移除',
            width: 110,
            renderCell: (params) => {
                const onClick = () => {
                    removeRow(params.row.id)
                };
    
                return isCreate ? <button type='button' onClick={onClick}>移除</button> : null;
            }
        },
        {
            field: 'memo',
            headerName: '出貨備註',
            width: 110,
            editable: true,
        },
        { field: 'id', headerName: 'ID', width: 90 },
    ];
      
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 

    const removeRow = (id) => {
        const updatedRows = createRows.filter(row => row.id !== id);
        const newEditedRows = editedRows.filter(row => row.id !== id);
        setCreateRows(updatedRows);
        setEditedRows(newEditedRows);
    };

    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
    }

    const fetchOrders = async () => {
        try {
            // 使用空字符串作为默认值，如果 selectedOption 是 null 或 undefined
            const client = selectedOption ? selectedOption.manufacturer_AbbreviationName : '';
            const response = await axios.get(`${apiUrl}/ToShip/Orderlist?client=${client}&num=${num}&way=${way}&barcode=${barCode}`);
            // 检查响应的结果，并设置到 state
            if (response.status === 200) {
                setOrderRows(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            // 可选：在 UI 中显示错误消息
            // setError('Failed to fetch data. Please try again.');
        }
    };
    

    const fetchOptions = async () => {
        try {    
          const response = await axios.get(`${apiUrl}/Manufacturer/list`);
          if (response.status === 200) {
            setOptions(response.data.manufacturers);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };


    const handleAddToShip = async (params) => {
        setIsCreate(true);
        // 檢查 createRows 中是否已存在具有相同 id 的 row
        if (!createRows.some(row => row.id === params.row.id)) {
            setCreateRows([...createRows, params.row]);
        }
    };  
    

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
    };

    const handleSave = async () => {
        const modifiedRows = editedRows.map(item => ({
          ...item,
        }));
        console.log(modifiedRows)
        console.log(createRows)

        const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');

        // 检查實交貨數是否大于訂單數量
        // eslint-disable-next-line no-restricted-syntax
        for (const item of modifiedRows) {
            if (item.實交貨數 > item.訂單數量) {
                alert('實交貨數不能大于訂單數量');
                return; // 停止执行更多的代码
            }
        }

        // 如果没有问题，继续执行
        const toShipData = {
            ToShipDate: formattedDate,
            Client:selectedOption.manufacturer_AbbreviationName,
            SellWay: detailData.toShipKind,
            Orders: modifiedRows,
            ShipNum: detailData.toShipNum || ''
        };

        try {
            const response = await axios.post(`${apiUrl}/ToShip/OrderToShip`, toShipData);
            if (response.status === 200) {
                
                alert(`出貨成功\n出貨單號: ${response.data.shipNum}`);
                setDetailData(prevDetailData => ({
                    ...prevDetailData,
                    toShipNum: response.data.toShipNum
                }));
                // 这里可以添加更多的处理逻辑，比如更新UI或状态
                console.log(detailData.toShipNum)
            }
            console.log("單號", detailData.toShipNum);
        } catch (error) {
            alert('出貨失敗:', error);
            // 这里处理错误情况，可能需要更新UI来显示错误信息
        }
        
    };


    const processRowUpdate = (newRow, oldRow, action) => {
        // 透過 newRow 的 id 找到 editedRows 陣列中的索引
        const index = editedRows.findIndex(row => row.id === newRow.id);
      
        // 若找到相同的 id，則先刪除
        if (index > -1) {
          editedRows.splice(index, 1);
        }
      
        // 將 newRow 加入 editedRows 陣列
        setEditedRows([...editedRows, newRow]);
        if (action === 'editCommit') {
            // 當 '訂單數量' 或 '實交貨數' 更改時, 更新 '未交貨數'
            if (newRow.訂單數量 !== oldRow.訂單數量 || newRow.實交貨數 !== oldRow.實交貨數) {
                return { ...newRow, 未交貨數: newRow.訂單數量 - newRow.實交貨數,出貨備註:newRow.出貨備註};
            }
        }
        return newRow;
      };
      const handleSellChange = (event) => {
        // 更新 detailData 状态，特别是 toShipKind 属性
        setDetailData({
            ...detailData,
            toShipKind: event.target.value,
        });
    };

    React.useEffect(() => {
        fetchOptions();
    }, []); 
    
    return(
        <div className='container-fluid'>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                <SelectFilter setMonth={setMonth} setNewData={setNewData} />
               
                </div>
            </div>
            <div className='row'>
                <div className='col-3'>
                    <h2><span className="badge bg-dark">出貨資料</span></h2>
                </div>
                <div className='col-1'/>
                <div className='col-7'>
                    <h2><span className="badge bg-dark">訂單資料</span></h2>
                </div>      
                <div className='col-1'/>        
            </div>
            <div className='row'>
                <div className='col-3'>
                    {data != null && model === '出貨交運單'?<ListTable setDetailData={setDetailData} 
                    setNewData={setNewData} data={newData || data} 
                    setModel={setModelandGetDate} page={page} 
                    setPage={setPage}
                    setRows={setRows}
                    setComs={setComs}
                    setSelectedDate={setSelectedDate}
                    options={options}
                    setSelectedOption={setSelectedOption}
                    setIsCreate={setIsCreate}
                    month={month}  // 確保傳遞 month 作為 props
                    /> : 
                    <div>目前還沒取得資料或取不到資料</div>}
                </div>
                <div className='col-1'/>
                <div className='col-7'>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={options}
                                            sx={{ width: '100%' }}
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                            getOptionLabel={(option) => option.manufacturer_Seq+ option.manufacturer_AbbreviationName}
                                            renderInput={(params) => <TextField {...params} label="廠商" />}
                                        />
                                        {/* <InputLabel id="demo-simple-select-label">客戶</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={detailData != null ? detailData.client : ''}
                                            onChange={handleClientChange}
                                        >
                                            {coms.map((item) => (
                                                <MenuItem key={item.id} value={item.簡稱}>
                                                {item.簡稱}
                                                </MenuItem>
                                            ))}
                                        </Select> */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">出貨庫別</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={detailData != null ? detailData.toShipKind : ''}
                                            // label="Age"
                                            onChange={handleSellChange}
                                        >
                                            <MenuItem key={1} value='銷貨出庫'>
                                                銷貨出庫
                                            </MenuItem>
                                            <MenuItem key={2} value='托外出庫'>
                                                托外出庫
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                <TextField
                                    id="outlined-basic"
                                    label="出貨單號"
                                    variant="outlined"
                                    value={detailData != null ? detailData.toShipNum : ''}
                              
                                />

                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="outlined-basic" label="聯絡人" variant="outlined" 
                                    value={selectedOption ? selectedOption.manufacturer_ContactName : ''}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" label="電話" variant="outlined" style={{width:'100%'}}
                                    value={selectedOption ? selectedOption.manufacturer_CompanyPhone : ''}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField id="outlined-basic" label="送貨地址" variant="outlined" style={{width:'100%'}}
                                    value={selectedOption ? selectedOption.manufacturer_ShipAddress : ''}/>
                                </Grid>
                                <Grid item xs={5}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="出貨日期" value={selectedDate}/>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" onClick={handleSave}>修改存入</Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ height: 600, width: '100%' }}>
                            <DataGrid
                                rows={isCreate?createRows : rows}
                                columns={!isCreate?columns : orderColumns}
                                initialState={{
                                pagination: {
                                    paginationModel: {
                                    pageSize: 75,
                                    },
                                },
                                }}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={error=>alert(error)}
                                onRowEditCommit={(rowId, e) => {
                                  handleSave(rowId,e);
                                }}
                                pageSizeOptions={[100]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                        <Box sx={{ height: 600, width: '100%' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">未出貨訂單</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={way}
                                            onChange={(e)=>setWay(e.target.value)}
                                        >
                                            <FormControlLabel value="orderNum" control={<Radio />} label="訂單號碼" />
                                            <FormControlLabel value="drawNum" control={<Radio />} label="圖號" />
                                            <FormControlLabel value="piNum" control={<Radio />} label="批號" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="outlined-basic" label="圖號" variant="outlined" 
                                    value={num} onChange={(e)=>setNum(e.target.value)}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField id="outlined-basic" label="條碼" variant="outlined" 
                                    value={barCode} onChange={(e)=>setBarCode(e.target.value)}/>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" onClick={fetchOrders}>搜尋</Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ height: 600, width: '100%' }}>
                            <DataGrid
                                rows={orderRows}
                                columns={orderColumns}
                                initialState={{
                                pagination: {
                                    paginationModel: {
                                    pageSize: 100,
                                    },
                                },
                                }}
                                onCellClick={handleAddToShip}
                                pageSizeOptions={[100]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                        </Box>
                    </Box>
                </div> 
                <div className='col-1'/>              
            </div>
        </div>     
    )
}


function ListTable({data,setNewData,setRows,setDetailData,page,setPage,setComs,setSelectedDate,setIsCreate,options,setSelectedOption,month}) {
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }
    const fetchCom = async (client) => {
        try {       
          const response = await axios.get(`${apiUrl}/ToShip/Comlist?com=${client}`);
          // 檢查響應的結果，並設置到 state
          if (response.status === 200) {
            setComs(response.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const nextPage = () => {
        console.log("月份", month); // 确认 month 的值
        axios.get(`${apiUrl}/ToShip/GetMains?month=${month}&page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
    }

    const prePage = () => {
        console.log("月份",month);
        axios.get(`${apiUrl}/ToShip/GetMains?month=${month}&page=${page - 1}`)
        
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
    }
    const handlePrint = (toShipNum) => {
        axios.get(`${apiUrl}/ToShip/CreateExcel?num=${toShipNum}`, {
            responseType: 'blob',  // 指定响应类型为 Blob
            headers: {
                'Id': localStorage.getItem('Id')
            } 
        })
        .then(response => {
            if (response.data) {
                // Create a URL for the blob
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            
                // Open the PDF in a new window
                window.open(url, '_blank');
    
                // Clean up the URL object
                window.URL.revokeObjectURL(url);
    
                alert('PDF 文件已開啟');
            } else {
                alert('列印失败');
            }
        })
        .catch(error => {
            console.error('發生錯誤:', error);
            alert('發生錯誤');
        });
    };
    
    const GetDetails = (toShipNum,client) => {
        axios.get(`${apiUrl}/ToShip/GetMainAndDetails?toShipNum=${toShipNum}`)
        .then(response => {
            if(response.data.statusCode === 200) {
                setIsCreate(false)
                setDetailData(response.data.toShip)
                setRows(response.data.orderDtos)
                console.log(response.data.toShip)
                setSelectedDate(dayjs(response.data.toShip.toShipDate));
                const matchedOption = options.find(option => option.manufacturer_AbbreviationName === response.data.toShip.client);
                setSelectedOption(matchedOption);
                fetchCom(client);
            }else{
                alert('找不到資料');
            }            
        })
        .catch(error =>{
            // handle error
            console.log(error);
        })
    }

    return(
        <>
    <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
        <table className="table">
            <thead>
                <tr>
                    <th scope="col" style={thStyle.centered}>#</th>
                    <th scope="col" style={thStyle.centered}>出貨單號</th>
                    <th scope="col" style={thStyle.centered}>客戶</th>
                    <th scope="col" style={thStyle.centered}>操作</th>
                </tr>
            </thead>
            <tbody>
                {data.toShips.map((item, index) => (
                    <tr key={uuidv4()}>
                        <th scope="row" style={thStyle.centered}>{index + 1}</th>
                        <td style={thStyle.centered}>{item.toShipNum}</td>
                        <td style={thStyle.centered}>{item.client}</td>
                        <td style={thStyle.centered}>
                            <Button variant="contained" color='warning' onClick={() => GetDetails(item.toShipNum,item.client)}>詳細</Button>
                            <Button variant="contained" color='warning' onClick={() => handlePrint(item.toShipNum)}>
                                列印
                            </Button>
                        </td>          
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    <div style={{textAlign: 'center'}} className='mt-3'>
        <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
        <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
        <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
    </div>
</>

    )
}

function SelectFilter({setNewData,setMonth}) {
    const [localMonth, setLocalMonth] = useState("");
    const [client, setClient] = useState("");

    
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Replace with your actual endpoint
        
        console.log("Local Month:", localMonth); // 確認 localMonth 的值
        setMonth(localMonth);
       
    
        const page = 1;
        const Realapiurl = `${apiUrl}/ToShip/GetMains`;
        const params = {
            month: localMonth,
            client,
            page
        };
       
        const response = await axios.get(Realapiurl, { params });
        setNewData(response.data)
    };
  
    return(
        <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row mb-3'>
                <div className='col-6'>
                    <label htmlFor="inputText1">日期
                    <input type="text" placeholder='yyyy/mm/dd' className="form-control" id="inputText1" value={localMonth} onChange={(e) => setLocalMonth(e.target.value)}/>
                    </label>
                </div>
                <div className='col-6'>
                    <label htmlFor="inputText2">客戶
                    <input type="text" className="form-control" id="inputText2" value={client} onChange={(e) => setClient(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='col-12'>
                    <button type="submit" className="btn btn-dark">提交</button>
                </div>
            </div>
        </div>      
  </form>
    )
}




import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Tabs from '../components/erp/tabs/Tab'
import Bill from '../components/erp/bill/Bill'
import ReceviableAccountTotal from '../components/erp/receviableAccountTotal/ReceviableAccountTotal';
import AcceptanceInStockDetail from '../components/erp/acceptanceInStockDetail/AcceptanceInStockDetail';
import ShouldPay from '../components/erp/shouldPay/ShouldPay';
import ShouldGetMoney from '../components/erp/shouldGetMoney/ShouldGetMoney';

import ReceivableAccount from '../components/erp/receivableAccount/ReceivableAccount';
import { apiUrl } from '../components/apiUrl/ApiUrl';



export default function AccountManage () {
    const tabArray = ['應收對帳單','應收帳款總表','應收帳款','發票總覽','驗收入庫明細','應付帳款'] // '發動生產','補印製令','生產排程'
    const [activeTab, setActiveTab] = useState(0);
    const [dataStoChanges,setStoChanges] =  useState(null);
    const [dataStorage,setStorageData] = useState(null);
    const [dataStoDetails,setStoDetailsData] = useState(null);
    const [dataStoStatistics,setStoStatisticsData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      const token = sessionStorage.getItem('jwtData');
      if (!token) {
        navigate('/login');
      }
    }, [navigate]);
    useEffect(() => {
        const fetchData = async () => {
            let url;
            switch (activeTab) {
                case 0:
                    url = `${apiUrl}/Storage/GetMains?page=1`;
                    break;
                case 1:
                    url = `${apiUrl}/Storage/GetMainsByFilter`;
                    break;
                case 2:
                    url = `${apiUrl}/Storage/GetMainsByDrawNum?page=1`;
                    break;
                case 3:
                    url = `${apiUrl}/Storage/GetInitChanges`;
                    break;
                default:
                    setStorageData(null);
                    setStoDetailsData(null);
                    setStoStatisticsData(null);
                    setStoChanges(null)
                    return;
            }
            try {
                const result = await axios.get(url);
                if (url === `${apiUrl}/Storage/GetMains?page=1`)
                {
                    setStorageData(result.data);
                }else if (url === `${apiUrl}/Storage/GetMainsByFilter`)
                {
                    setStoDetailsData(result.data)
                }else if (url === `${apiUrl}/Storage/GetMainsByDrawNum?page=1`)
                {
                    setStoStatisticsData(result.data);
                }
                else if (url === `${apiUrl}/Storage/GetInitChanges`)
                {
                    setStoChanges(result.data);
                }
                
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [activeTab]);  // ${apiUrl}/PurchasePrice/MainList
    const renderTabContent = (tabIndex) => {
            switch (tabIndex) {
                case 0:
                    return <ReceivableAccount data={dataStorage}/>;
                case 1:
                    return <ReceviableAccountTotal data={dataStoDetails}/>;
                case 2:
                    return <ShouldGetMoney/>
                case 3:
                    return <Bill data={dataStoChanges}/>;
                case 4:
                    return <AcceptanceInStockDetail data={dataStoChanges}/>;
                case 5:
                    return <ShouldPay data={dataStoChanges}/>;
                default:
                    return null;
            }
    };
    return(
        <div>
            <Tabs tabsNames={tabArray} activeTab={activeTab} setActiveTab={setActiveTab}/>
            {renderTabContent(activeTab)}
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Tabs from '../components/erp/tabs/Tab'
import Order from '../components/erp/order/Order'
import ToShip from '../components/erp/toShip/ToShip';
import ToShipList from '../components/erp/toShipList/ToShipList';
import MatCard from '../components/erp/matCard/MatCard';
import StockChange from '../components/erp/stockChange/StockChange';
import StorageWork from '../components/erp/storageWork/StorageWork';
import StoDetails from '../components/erp/stoDetails/StoDetails';
import StoStatistics from '../components/erp/stoStatistics/StoStatistics';
import { apiUrl } from '../components/apiUrl/ApiUrl';



export default function StorageManager () {
    const tabArray = ['採購入庫作業','採購入庫明細表','採購入庫統計表','庫存異動調整'] // '發動生產','補印製令','生產排程'
    const [activeTab, setActiveTab] = useState(0);
    const [dataStoChanges,setStoChanges] =  useState(null);
    const [dataStorage,setStorageData] = useState(null);
    const [dataStoDetails,setStoDetailsData] = useState(null);
    const [dataStoStatistics,setStoStatisticsData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      const token = sessionStorage.getItem('jwtData');
      if (!token) {
        navigate('/login');
      }
    }, [navigate]);
    useEffect(() => {
        const fetchData = async () => {
            let url;
            switch (activeTab) {
                case 0:
                    url = `${apiUrl}/Storage/GetMains?page=1`;
                    break;
                case 1:
                    url = `${apiUrl}/Storage/GetMainsByFilter`;
                    break;
                case 2:
                    url = `${apiUrl}/Storage/GetMainsByDrawNum?page=1`;
                    break;
                case 3:
                    url = `${apiUrl}/Storage/GetInitChanges`;
                    break;
                default:
                    setStorageData(null);
                    setStoDetailsData(null);
                    setStoStatisticsData(null);
                    setStoChanges(null)
                    return;
            }
            try {
                const result = await axios.get(url);
                if (url === `${apiUrl}/Storage/GetMains?page=1`)
                {
                    setStorageData(result.data);
                }else if (url === `${apiUrl}/Storage/GetMainsByFilter`)
                {
                    setStoDetailsData(result.data)
                }else if (url === `${apiUrl}/Storage/GetMainsByDrawNum?page=1`)
                {
                    setStoStatisticsData(result.data);
                }
                else if (url === `${apiUrl}/Storage/GetInitChanges`)
                {
                    setStoChanges(result.data);
                }
                
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [activeTab]);  // ${apiUrl}/PurchasePrice/MainList
    const renderTabContent = (tabIndex) => {
            switch (tabIndex) {
                case 0:
                    return <StorageWork/>;
                case 1:
                    return <StoDetails data={dataStoDetails}/>;
                case 2:
                    return <StoStatistics data={dataStoStatistics}/>
                case 3:
                    return <StockChange data={dataStoChanges}/>;
                default:
                    return null;
            }
    };
    return(
        <div>
            <Tabs tabsNames={tabArray} activeTab={activeTab} setActiveTab={setActiveTab}/>
            {renderTabContent(activeTab)}
        </div>
    )
}
import React, { useState } from 'react';
import '../../../css/tab.css'

export default function Tabs({ tabsNames, setActiveTab }) {
    const [activeTab, setTab] = useState(0);

    const onClickTab = (index) => {
        setTab(index);
        setActiveTab(index);
    }

    return (
        <ul className="nav nav-tabs">
            {tabsNames.map((tabName, index) => (
                <li className="nav-item" key={index}>
                    <a className={activeTab === index ? "nav-link active tabFont" : "nav-link tabFont"} 
                       onClick={() => onClickTab(index)}
                       href="#">{tabName}</a>
                </li>
            ))}
        </ul>
    );
}

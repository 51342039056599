import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Input  from '@mui/material/Input';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid } from '@mui/x-data-grid';
import { apiUrl } from '../../apiUrl/ApiUrl';


const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);



export default function MatSet() {
      

    const columns = [      
        {
            field: 'Operation',
            headerName: '操作',
            width: 60,
            renderCell: (params) => (
              <>
                <IconButton aria-label="delete" onClick={() => handleDeleteSubmit(params.row.id)}>
                  <DeleteIcon />
                </IconButton>
              </>  
            ),
        },  
        {   field: 'id', headerName: 'ID', width: 90 },
        {
            field: '圖號',
            headerName: '圖號',
            width: 150,
            editable: false,
        },
        {
            field: '物品名',
            headerName: '物品名',
            width: 120,
            editable: false,
        },
        {
            field: '單位',
            headerName: '單位',
            width: 120,
            editable: false,
        },
        {
            field: '規格',
            headerName: '規格',
            width: 120,
            editable: false,
        },
        {
            field: '客戶',
            headerName: '客戶',
            width: 120,
            editable: false,
        },
        {
            field: '機種',
            headerName: '機種',
            width: 120,
            editable: false,
        },
        {
            field: '類別',
            headerName: '類別',
            width: 120,
            editable: false,
        },
        {
            field: '料品類別',
            headerName: '料品類別',
            width: 120,
            editable: false,
        },
        {
            field: '庫存管制',
            headerName: '庫存管制',
            width: 120,
            editable: false,
        },
        {
            field: '免檢',
            headerName: '免檢',
            width: 120,
            editable: false,
        },
        {
            field: '外購廠商',
            headerName: '外購廠商',
            width: 120,
            editable: false,
        },
        {
            field: '安全量',
            headerName: '安全量',
            width: 120,
            editable: false,
        },
        {
            field: '成本單價',
            headerName: '成本單價',
            width: 120,
            editable: false,
        },
        {
            field: '材料圖號',
            headerName: '材料圖號',
            width: 120,
            editable: false,
        },
        {
            field: '材質',
            headerName: '材質',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '定尺',
            headerName: '定尺',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '承製公司',
            headerName: '承製公司',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '物品名',
            headerName: '物品名',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '非定尺',
            headerName: '非定尺',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '客戶',
            headerName: '客戶',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '客戶供料',
            headerName: '客戶供料',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '庫存量',
            headerName: '庫存量',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '庫存管制',
            headerName: '庫存管制',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '料品類別',
            headerName: '料品類別',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '託外價',
            headerName: '託外價',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '售價',
            headerName: '售價',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '條料',
            headerName: '條料',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '現有成本',
            headerName: '現有成本',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '託外廠商',
            headerName: '託外廠商',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '規格',
            headerName: '規格',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '單位',
            headerName: '單位',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '替代圖號',
            headerName: '替代圖號',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '進價',
            headerName: '進價',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '鈑金l',
            headerName: '鈑金l',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '鈑金t',
            headerName: '鈑金t',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '鈑金w',
            headerName: '鈑金w',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '鈑金材質',
            headerName: '鈑金材質',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '鈑金重量',
            headerName: '鈑金重量',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠比例1',
            headerName: '塑膠比例1',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠比例2',
            headerName: '塑膠比例2',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠比例3',
            headerName: '塑膠比例3',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠比例4',
            headerName: '塑膠比例4',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠比例5',
            headerName: '塑膠比例5',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠材質1',
            headerName: '塑膠材質1',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠材質2',
            headerName: '塑膠材質2',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠材質3',
            headerName: '塑膠材質3',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠材質4',
            headerName: '塑膠材質4',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠材質5',
            headerName: '塑膠材質5',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠供料1',
            headerName: '塑膠供料1',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠供料2',
            headerName: '塑膠供料2',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠供料3',
            headerName: '塑膠供料3',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠供料4',
            headerName: '塑膠供料4',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠供料5',
            headerName: '塑膠供料5',
            width: 120,
            editable: false,
        }

        ,
        {
            field: '塑膠重量',
            headerName: '塑膠重量',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠重量1',
            headerName: '塑膠重量1',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠重量2',
            headerName: '塑膠重量2',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠重量3',
            headerName: '塑膠重量3',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠重量4',
            headerName: '塑膠重量4',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '塑膠重量5',
            headerName: '塑膠重量5',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '會計類別',
            headerName: '會計類別',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '圖號',
            headerName: '圖號',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '機種',
            headerName: '機種',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '類別',
            headerName: '類別',
            width: 120,
            editable: false,
        }
        ,
        {
            field: 'Sp',
            headerName: 'Sp',
            width: 120,
            editable: false,
        }
        ,
        {
            field: 'Nsp',
            headerName: 'Nsp',
            width: 120,
            editable: false,
        }
        ,
        {
            field: 'Qty',
            headerName: 'Qty',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '廠商免檢',
            headerName: '廠商免檢',
            width: 120,
            editable: false,
        }  
    ];

    const pricecolumns = [
     
        {   field: 'id', headerName: 'ID', width: 90 },
        {
            field: '廠商',
            headerName: '廠商',
            width: 150,
            editable: false,
        },
        {
            field: '進價',
            headerName: '進價',
            width: 120,
            editable: false,
        }
    ];

    const makecolumns = [
     
        {   field: 'id', headerName: 'ID', width: 90 },
        {
            field: '圖號',
            headerName: '圖號',
            width: 150,
            editable: false,
        },
        {
            field: '序',
            headerName: '序',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '工程內容',
            headerName: '工程內容',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '使用機械',
            headerName: '使用機械',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '廠商',
            headerName: '廠商',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '代料',
            headerName: '代料',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '單位',
            headerName: '單位',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '外注價',
            headerName: '外注價',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '前置天數',
            headerName: '前置天數',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '職場',
            headerName: '職場',
            width: 120,
            editable: false,
        }
        ,
        {
            field: '秒數',
            headerName: '秒數',
            width: 120,
            editable: false,
        }
        ,
        {
            field: 'Code',
            headerName: 'Code',
            width: 120,
            editable: false,
        }
        ,
        {
            field: 'Code',
            headerName: 'Code',
            width: 120,
            editable: false,
        }
        ,
        {
            field: 'PrePa',
            headerName: 'PrePa',
            width: 120,
            editable: false,
        }
        ,
        {
            field: 'ProPa',
            headerName: 'ProPa',
            width: 120,
            editable: false,
        }
        ,
        {
            field: 'ForcePre',
            headerName: 'ForcePre',
            width: 120,
            editable: false,
        }
        ,
        {
            field: 'ForcePro',
            headerName: 'ForcePro',
            width: 120,
            editable: false,
        }
    ];
    const [rows,setRows] = useState([]);
    const [priceRows,setPriceRows] = useState([]);
    const [makeRows,setMakeRows] = useState([]);
    const [filterRows,setFilterRows] = useState([]);
    const [orderNum, setOrderNum] = useState("");
    const [itemName, setItemName] = useState("");
    const [open, setOpen] = React.useState(false);
    const [proOpen, setProOpen] = React.useState(false);
    const [selectedRow,setSelectedRow] = useState(null);
    const [request, setRequest] = useState({
        Id: 0,
        外購廠商: '',
        安全量: 0,
        成本單價: 0,
        免檢: '',
        材料圖號: '',
        材質: '',
        定尺: 0,
        承製公司: '',
        物品名: '',
        非定尺: 0,
        客戶: '',
        客戶供料: 0,
        庫存量: 0,
        庫存管制: '',
        料品類別: '鈑金件',
        託外價: 0,
        託外廠商: '',
        售價: 0,
        條料: 0,
        現有成本: 0,
        規格: '',
        單位: '',
        替代圖號: '',
        進價: 0,
        鈑金l: 0,
        鈑金t: 0,
        鈑金w: 0,
        鈑金材質: '',
        鈑金重量: 0,
        塑膠比例1: 0,
        塑膠比例2: 0,
        塑膠比例3: 0,
        塑膠比例4: 0,
        塑膠比例5: 0,
        塑膠材質1: '',
        塑膠材質2: '',
        塑膠材質3: '',
        塑膠材質4: '',
        塑膠材質5: '',
        塑膠供料1: 0,
        塑膠供料2: 0,
        塑膠供料3: 0,
        塑膠供料4: 0,
        塑膠供料5: 0,
        塑膠重量: 0,
        塑膠重量1: 0,
        塑膠重量2: 0,
        塑膠重量3: 0,
        塑膠重量4: 0,
        塑膠重量5: 0,
        會計類別: '',
        圖號: '',
        機種: '',
        類別: '買賣',
        Sp: 0,
        Nsp: 0,
        Qty: 0,
        廠商免檢: '',
    });
    const [price,setPrice] = useState({
        圖號:'',
        廠商:'',
        進價:0,
        工程內容:'',
        選:'',
        id:0
    })

    const [pro, setPro] = useState({
        圖號: "",
        序: "",
        工程內容: "",
        使用機械: "",
        廠商: "",
        代料: "",
        單位: "",
        外注價: 0,  // 假设这是一个数字字段
        前置天數: 0,  // 假设这是一个数字字段
        職場: "",
        秒數: 0,  // 假设这是一个数字字段
        Code: "",
        PrePa: "",
        ProPa: "",
        ForcePre: "",
        ForcePro: "",
        Id: 0,  // 假设Id在这里是一个数字，通常是数据库的自增字段
      });
      

      const handleDeleteSubmit = async (id) => {
        try {
            const response = await axios.delete(`${apiUrl}/MatCard/Item`, {
                params: { id }
            });
    
            if (response.status === 200) {
                fetchData();
            }
        } catch (error) {
            console.error("Error deleting record:", error);
            alert('請確認資料型態有無錯誤')
        }
      }

    const handleClear = () => {
        setRequest({
            Id: 0,
        外購廠商: '',
        安全量: 0,
        成本單價: 0,
        免檢: '',
        材料圖號: '',
        材質: '',
        定尺: 0,
        承製公司: '',
        物品名: '',
        非定尺: 0,
        客戶: '',
        客戶供料: 0,
        庫存量: 0,
        庫存管制: '',
        料品類別: '鈑金件',
        託外價: 0,
        託外廠商: '',
        售價: 0,
        條料: 0,
        現有成本: 0,
        規格: '',
        單位: '',
        替代圖號: '',
        進價: 0,
        鈑金l: 0,
        鈑金t: 0,
        鈑金w: 0,
        鈑金材質: '',
        鈑金重量: 0,
        塑膠比例1: 0,
        塑膠比例2: 0,
        塑膠比例3: 0,
        塑膠比例4: 0,
        塑膠比例5: 0,
        塑膠材質1: '',
        塑膠材質2: '',
        塑膠材質3: '',
        塑膠材質4: '',
        塑膠材質5: '',
        塑膠供料1: 0,
        塑膠供料2: 0,
        塑膠供料3: 0,
        塑膠供料4: 0,
        塑膠供料5: 0,
        塑膠重量: 0,
        塑膠重量1: 0,
        塑膠重量2: 0,
        塑膠重量3: 0,
        塑膠重量4: 0,
        塑膠重量5: 0,
        會計類別: '',
        圖號: '',
        機種: '',
        類別: '買賣',
        Sp: 0,
        Nsp: 0,
        Qty: 0,
        廠商免檢: '',
        });
    };

    
    const handlePriceClear = () => {
        setPrice({
            圖號:'',
            廠商:'',
            進價:0,
            工程內容:'',
            選:'',
            id:0
        });
    };

    const handleProClear = () => {
        setPro({
            圖號: "",
            序: "",
            工程內容: "",
            使用機械: "",
            廠商: "",
            代料: "",
            單位: "",
            外注價: 0,  // 假设这是一个数字字段
            前置天數: 0,  // 假设这是一个数字字段
            職場: "",
            秒數: 0,  // 假设这是一个数字字段
            Code: "",
            PrePa: "",
            ProPa: "",
            ForcePre: "",
            ForcePro: "",
            Id: 0, 
        });
    };
  
    const handleChooseRow = (row) => {
      fetchPriceData(row.圖號);
      fetchMakeData(row.圖號);
      setSelectedRow(row);
      setRequest({
        ...request,
        ...row
      })
    };

    const handleChoosePriceRow = (row) => {
        setPrice({
          ...price,
          ...row
        })
    };

    const handleChooseProRow = (row) => {
        fetchNewPriceData(row.圖號,row.工程內容);
        setPro({
          ...pro,
          ...row
        })
    };

    const fetchData = async () => {
        try {       
          const response = await axios.get(`${apiUrl}/MatCard/MaterialSet?orderNum=${orderNum}&itemName=${itemName}`);
          // 檢查響應的結果，並設置到 state
          if (response.status === 200) {

            setRows(response.data);
            setFilterRows(response.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const fetchPriceData = async (drawNum) => {
        try {       
          const response = await axios.get(`${apiUrl}/MatCard/MaterialSetPrice?drawNum=${drawNum}`);
          // 檢查響應的結果，並設置到 state
          if (response.status === 200) {
            setPriceRows(response.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const fetchNewPriceData = async (drawNum,content) => {
        try {       
          const response = await axios.get(`${apiUrl}/MatCard/NewMaterialSetPrice?drawNum=${drawNum}&content=${content}`);
          // 檢查響應的結果，並設置到 state
          if (response.status === 200) {
            setPriceRows(response.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const fetchMakeData = async (drawNum) => {
        try {       
          const response = await axios.get(`${apiUrl}/MatCard/MaterialSetMakeProcess?drawNum=${drawNum}`);
          // 檢查響應的結果，並設置到 state
          if (response.status === 200) {
            setMakeRows(response.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (event, propertyName) => {
        const value = event.target ? event.target.value : event;
        setRequest((prevData) => ({
        ...prevData,
        [propertyName]: value,
        }));      
    };

    const handlePriceInputChange = (event, propertyName) => {
        const value = event.target ? event.target.value : event;
        setPrice((prevData) => ({
        ...prevData,
        [propertyName]: value,
        }));      
    };

    const handleProInputChange = (event, propertyName) => {
        const value = event.target ? event.target.value : event;
        setPro((prevData) => ({
        ...prevData,
        [propertyName]: value,
        }));      
    };

    const handleSubmit = async () => {   
         
        try {
          const response = await axios.post(`${apiUrl}/MatCard/EditMaterialSet`, request );
          if (response.status === 200) {
            setRows(response.data);
            setFilterRows(response.data);
          }
        } catch (error) {
            console.error("Error logging in:", error);
            alert('失敗 欄位有誤');
        } 
    }

    const handlePriceSubmit = async () => {   
         
        try {
          const response = await axios.post(`${apiUrl}/MatCard/EditPrice`, price );
          if (response.status === 200) {
            setPriceRows(response.data);
            handleClose();
          }
        } catch (error) {
            console.error("Error logging in:", error);
            alert('失敗 欄位有誤');
        } 
    }

    const handleProSubmit = async () => {   
         
        try {
          const response = await axios.post(`${apiUrl}/MatCard/EditMakeProcess`, pro );
          if (response.status === 200) {
            setMakeRows(response.data);
            handleProClose();
          }
        } catch (error) {
            console.error("Error logging in:", error);
            alert('失敗 欄位有誤');
        } 
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    
      const handleClose = () => {
        setOpen(false);
    };

    const handleProClickOpen = () => {
        setProOpen(true);
    };
    
      const handleProClose = () => {
        setProOpen(false);
    };


    useEffect(() => {
        fetchData();
    }, []); 
 
    const [pjNames, setPjNames] = useState([]);

    useEffect(() => {
      const fetchPJNames = async () => {
        try {
          const response = await axios.get(`${apiUrl}/MatCard/ProjectNameGet`);
          if (response.status === 200) {
            const formattedNames = response.data.map((item) => ({
                id: item.id,  // 使用 response.data 裡的 ID 欄位
                工程名稱: item.工程名稱1  // 使用 response.data 裡的 工程名稱1 欄位
              }));
            setPjNames(formattedNames);  // 設置返回的 PJNAME 資料到狀態中
          }
        } catch (error) {
          console.error('無法取得工程名稱:', error);
        }
      };
    
      fetchPJNames();
    }, []);
    
    
  return (
    <>
    <Box
      sx={{
        margin:'2%',
        width: '95%',
        height: 1000,
      }}
    >
        <Dialog open={proOpen} onClose={handleProClose}>
            <DialogTitle>新增or修改製程</DialogTitle>
            <DialogContent>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="圖號"
                type="search"
                variant="standard"
                value={pro.圖號}
                onChange={(e) => handleProInputChange(e, '圖號')}
                />
            </Grid>     
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="序"
                type="search"
                variant="standard"
                value={pro.序}
                onChange={(e) => handleProInputChange(e, '序')}
                />
            </Grid>    
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <FormControl fullWidth variant="standard">
                <InputLabel id="pjname-select-label">工程內容</InputLabel>
                <Select
                    labelId="pjname-select-label"
                    id="pjname-select"
                    value={pro.工程內容}
                    onChange={(e) => handleProInputChange(e, '工程內容')}
                >
                    {pjNames.map((pjname) => (
                    <MenuItem key={pjname.id} value={pjname.工程名稱}>
                    {pjname.工程名稱}
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="使用機械"
                type="search"
                variant="standard"
                value={pro.使用機械}
                onChange={(e) => handleProInputChange(e, '使用機械')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="廠商"
                type="search"
                variant="standard"
                value={pro.廠商}
                onChange={(e) => handleProInputChange(e, '廠商')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="代料"
                type="search"
                variant="standard"
                value={pro.代料}
                onChange={(e) => handleProInputChange(e, '代料')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="單位"
                type="search"
                variant="standard"
                value={pro.單位}
                onChange={(e) => handleProInputChange(e, '單位')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="外注價"
                type="search"
                variant="standard"
                value={pro.外注價}
                onChange={(e) => handleProInputChange(e, '外注價')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="前置天數"
                type="search"
                variant="standard"
                value={pro.前置天數}
                onChange={(e) => handleProInputChange(e, '前置天數')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="職場"
                type="search"
                variant="standard"
                value={pro.職場}
                onChange={(e) => handleProInputChange(e, '職場')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="秒數"
                type="search"
                variant="standard"
                value={pro.秒數}
                onChange={(e) => handleProInputChange(e, '秒數')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="Code"
                type="search"
                variant="standard"
                value={pro.Code}
                onChange={(e) => handleProInputChange(e, 'Code')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="PrePa"
                type="search"
                variant="standard"
                value={pro.PrePa}
                onChange={(e) => handleProInputChange(e, 'PrePa')}
                />
            </Grid>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="ProPa"
                type="search"
                variant="standard"
                value={pro.ProPa}
                onChange={(e) => handleProInputChange(e, 'ProPa')}
                />
            </Grid>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="ForcePre"
                type="search"
                variant="standard"
                value={pro.ForcePre}
                onChange={(e) => handleProInputChange(e, 'ForcePre')}
                />
            </Grid>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="ForcePro"
                type="search"
                variant="standard"
                value={pro.ForcePro}
                onChange={(e) => handleProInputChange(e, 'ForcePro')}
                />
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleProClose}>取消</Button>
            <Button  onClick={handleProSubmit}>送出</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>新增or修改廠商報價</DialogTitle>
            <DialogContent>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="圖號"
                type="search"
                variant="standard"
                value={price.圖號}
                onChange={(e) => handlePriceInputChange(e, '圖號')}
                />
            </Grid>     
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="廠商"
                type="search"
                variant="standard"
                value={price.廠商}
                onChange={(e) => handlePriceInputChange(e, '廠商')}
                />
            </Grid>   
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'5%'}}>      
                <TextField
                id="standard-search"
                label="進價"
                type="search"
                variant="standard"
                value={price.進價}
                onChange={(e) => handlePriceInputChange(e, '進價')}

                />
            </Grid>   
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="工程內容"
                type="search"
                variant="standard"
                onChange={(e) => handlePriceInputChange(e, '工程內容')}
                value={price.工程內容}
                />
            </Grid>   
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="選"
                type="search"
                variant="standard"
                onChange={(e) => handlePriceInputChange(e, '選')}
                value={price.選}
                />
            </Grid>  
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>取消</Button>
            <Button  onClick={handlePriceSubmit}>送出</Button>
            </DialogActions>
        </Dialog>
        <Grid container spacing={0} style={{marginBottom:'1%'}}>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center'}}>      
                <Typography variant="h2" component="h2">
                    料品資料
                </Typography>
            </Grid>
            <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>      
                <Button size="medium" variant="contained" onClick={handleClear}>新增</Button>
            </Grid>
            <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>      
                <Button size="medium" variant="contained" onClick={handleClear}>取消</Button>
            </Grid>
            <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>      
                <Button size="medium" variant="contained" onClick={handleSubmit}>保存</Button>
            </Grid>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center',marginTop:'2%'}}/>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="圖號"
                type="search"
                variant="standard"
                value={request.圖號}
                onChange={(e) => handleInputChange(e, '圖號')}
                />
            </Grid>     
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="單位"
                type="search"
                variant="standard"
                value={request.單位}
                onChange={(e) => handleInputChange(e, '單位')}
                />
            </Grid>   
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="客戶"
                type="search"
                variant="standard"
                value={request.客戶}
                onChange={(e) => handleInputChange(e, '客戶')}

                />
            </Grid>   
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="材料圖號"
                type="search"
                variant="standard"
                onChange={(e) => handleInputChange(e, '材料圖號')}
                value={request.材料圖號}
                />
            </Grid>   
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="物品名"
                type="search"
                variant="standard"
                onChange={(e) => handleInputChange(e, '物品名')}
                value={request.物品名}
                />
            </Grid>  
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="庫存量"
                type="search"
                variant="standard"
                onChange={(e) => handleInputChange(e, '庫存量')}
                value={request.庫存量}
                />
            </Grid>  
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="安全量"
                type="search"
                variant="standard"
                onChange={(e) => handleInputChange(e, '安全量')}
                value={request.安全量}
                />
            </Grid>  
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="鈑金材質"
                type="search"
                variant="standard"
                onChange={(e) => handleInputChange(e, '鈑金材質')}
                value={request.鈑金材質}
                />
            </Grid>  
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'2%'}}>      
                <FormControl>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        銷售類別
                    </InputLabel>  
                    <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={request.類別}
                    label="typeId"
                    size="small"
                    onChange={(e) => handleInputChange(e, '類別')}

                    // onChange={(e) => handleInputChange(e, 'EmploymentTypeId')}
                    >
                        <MenuItem value='買賣'>買賣</MenuItem>
                        <MenuItem value='材料'>材料</MenuItem>
                        <MenuItem value='成品'>成品</MenuItem>
                        <MenuItem value='商品'>商品</MenuItem>
                        <MenuItem value='在製品'>在製品</MenuItem>
                        <MenuItem value='模具'>模具</MenuItem>
                        <MenuItem value='費用'>費用</MenuItem>
                        <MenuItem value='其他'>其他</MenuItem>
                    </Select>
                </FormControl>
            </Grid>  
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'2%'}}>    
                <FormControl>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        料品類別
                    </InputLabel>  
                    <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={request.料品類別}
                    onChange={(e) => handleInputChange(e, '料品類別')}
                    label="typeId"
                    size="small"
                    // onChange={(e) => handleInputChange(e, 'EmploymentTypeId')}
                    >
                        <MenuItem value='鈑金件'>鈑金件</MenuItem>
                        <MenuItem value='塑膠件'>塑膠件</MenuItem>
                    </Select>
                </FormControl>  
            </Grid>  
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'2%'}}/>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'2%'}}/>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="規格"
                type="search"
                variant="standard"
                value={request.規格}
                onChange={(e) => handleInputChange(e, '規格')}

                />
            </Grid>  
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="機種"
                type="search"
                variant="standard"
                value={request.機種}
                onChange={(e) => handleInputChange(e, '機種')}

                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="替代圖號"
                type="search"
                variant="standard"
                value={request.替代圖號}
                onChange={(e) => handleInputChange(e, '替代圖號')}

                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}/>    
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="鈑金t"
                type="search"
                variant="standard"
                value={request.鈑金t}
                onChange={(e) => handleInputChange(e, '鈑金t')}
                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="鈑金w"
                type="search"
                variant="standard"
                value={request.鈑金w}
                onChange={(e) => handleInputChange(e, '鈑金w')}

                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="鈑金l"
                type="search"
                variant="standard"
                value={request.鈑金l}
                onChange={(e) => handleInputChange(e, '鈑金l')}

                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField
                id="standard-search"
                label="重量"
                type="search"
                variant="standard"
                value={request.鈑金重量}
                onChange={(e) => handleInputChange(e, '鈑金重量')}

                />
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'1%'}}>      
                <FormGroup>
                    <FormControlLabel control={<Checkbox   checked={request.庫存管制 ==='Y'} onChange={(e) => {
                        
                        const isChecked = e.target.checked;
                        setRequest({ ...request, 庫存管制: isChecked ? 'Y' : 'N' });
                    }}/>} label="庫存管制"/>
                </FormGroup>
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'1%'}}>      
                <FormGroup>
                    <FormControlLabel control={<Checkbox   checked={request.客戶免檢 ==='Y'} onChange={(e) => {
                        
                        const isChecked = e.target.checked;
                        setRequest({ ...request, 客戶免檢: isChecked ? 'Y' : 'N' });
                    }}/>} label="客戶免檢"/>
                </FormGroup>
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'1%'}}>      
                <FormGroup>
                    <FormControlLabel control={<Checkbox  checked={request.廠商免檢 ==='Y'} onChange={(e) => {
                    const isChecked = e.target.checked;
                    setRequest({ ...request, 廠商免檢: isChecked ? 'Y' : 'N' });
                }}/>} label="廠商免檢" />
                </FormGroup>
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'1%'}}>      
                <FormGroup>
                    <FormControlLabel control={<Checkbox   checked={request.客戶供料 ===1}          onChange={(e) => {
            // 复选框状态发生变化时执行的操作
            const isChecked = e.target.checked;
            setRequest({ ...request, 客戶供料: isChecked ? 1 : 0 });
          }}/>} label="客戶供料"/>
                </FormGroup>
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'1%'}}>      
                <FormGroup>
                    <FormControlLabel control={<Checkbox   checked={request.條料 ===1}          onChange={(e) => {
            // 复选框状态发生变化时执行的操作
            const isChecked = e.target.checked;
            setRequest({ ...request, 條料: isChecked ? 1 : 0 });
          }}/>} label="條料"/>
                </FormGroup>
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'1%'}}>      
                <FormGroup>
                    <FormControlLabel control={<Checkbox  checked={request.定尺 ===1}           onChange={(e) => {
            // 复选框状态发生变化时执行的操作
            const isChecked = e.target.checked;
            setRequest({ ...request, 定尺: isChecked ? 1 : 0 });
          }}/>} label="定尺" />
                </FormGroup>
            </Grid> 
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'1%'}}>      
                <FormGroup>
                    <FormControlLabel control={<Checkbox  checked={request.非定尺 ===1}           onChange={(e) => {
            // 复选框状态发生变化时执行的操作
            const isChecked = e.target.checked;
            setRequest({ ...request, 非定尺: isChecked ? 1 : 0});
          }}/>} label="非定尺" />
                </FormGroup>
            </Grid>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center',marginTop:'1%'}}/>  
            <Grid item xs={12} style={{display:'flex',justifyContent:'center',marginBottom:'3%'}}/>  
        <Box
            sx={{
                width: '30%',
                height: 250,
            }}
        >
        <Grid container spacing={0} style={{marginBottom:'1%'}}>
            <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>      
                <Button size="medium" variant="contained" onClick={handleClickOpen}>新增或修改</Button>
            </Grid>
            <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>      
                <Button size="medium" variant="contained" onClick={handlePriceClear}>取消</Button>
            </Grid>
            <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>      
                <Button size="medium" variant="contained" onClick={handlePriceSubmit}>保存</Button>
            </Grid>
        </Grid>
        <DataGrid
            rows={priceRows}
            columns={pricecolumns}
            onCellDoubleClick={(params) => handleChoosePriceRow(params.row)}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 30,
                },
            },
            }}
            pageSizeOptions={[30,20,10]}
        />  
        </Box>
        <Box
            sx={{
                marginLeft:'2%',
                width: '68%',
                height: 250,
            }}
        >
        <Grid container spacing={0} style={{marginBottom:'1%'}}>
            <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>      
                <Button size="medium" variant="contained" onClick={handleProClickOpen}>新增或修改</Button>
            </Grid>
            <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>      
                <Button size="medium" variant="contained" onClick={handleProClear}>取消</Button>
            </Grid>
            <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>      
                <Button size="medium" variant="contained" onClick={handleProSubmit}>保存</Button>
            </Grid>
        </Grid>
        <DataGrid
            rows={makeRows}
            columns={makecolumns}
            onCellDoubleClick={(params) => handleChooseProRow(params.row)}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 30,
                },
            },
            }}
            pageSizeOptions={[30,20,10]}
        />
        </Box>
            <Grid item xs={12} style={{display:'flex',justifyContent:'center',marginBottom:'3%'}}/>  
            <Grid item xs={12} style={{display:'flex',justifyContent:'center',marginBottom:'3%'}}/>  
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField id="outlined-basic" label="圖號" value={orderNum} variant="outlined" onChange={(e)=>setOrderNum(e.target.value)}/>
            </Grid>
            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <TextField id="outlined-basic" label="物品名"value={itemName}  variant="outlined" onChange={(e)=>setItemName(e.target.value)}/>
            </Grid>  

            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>      
                <Button variant="contained" size="medium" onClick={fetchData}>
                    搜尋
                </Button>
            </Grid>
        </Grid>
        <Box
            sx={{
                width: '100%',
                height: 700,
            }}
        >
        <DataGrid
            rows={filterRows}
            columns={columns}
            onCellDoubleClick={(params) => handleChooseRow(params.row)}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 30,
                },
            },
            }}
            pageSizeOptions={[30,20,10]}
            disableRowSelectionOnClick
        />
        </Box>
    </Box>
    </>
  );
}


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Tabs from '../components/erp/tabs/Tab'
import Order from '../components/erp/order/Order'
import ToShip from '../components/erp/toShip/ToShip';
import ToShipList from '../components/erp/toShipList/ToShipList';
import Inspection from '../components/erp/inspection/Inspection';
import OrderExcel from '../components/erp/order/OrderExcel';
import HandOrder from '../components/erp/order/HandOrder';
import { apiUrl } from '../components/apiUrl/ApiUrl';


export default function BusinessManager () {
    const tabArray = ['訂單管理','台日檢收票','出貨交運單','出貨明細','訂單輸入','訂單手輸']
    const [activeTab, setActiveTab] = useState(0);
    const [dataOrders, setOrdersData] = useState(null);
    const [dataToSHip, setToSHipData] = useState(null);
    const [dataToShipList,setToShipList] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      const token = sessionStorage.getItem('jwtData');
      if (!token) {
        navigate('/login');
      }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            let url;
            switch (activeTab) {
                case 0:
                    url = `${apiUrl}/OrderManage/NoFilterGetMains?page=1`;
                    break;
                case 1:
                    url = `${apiUrl}/Inspection/GetOrders`;
                    break;
                case 2:
                    url = `${apiUrl}/ToShip/GetMains?page=1`;
                    break;
                case 3:
                    url = `${apiUrl}/ToShip/GetMains?page=1`;
                    break;
                default:
                    setOrdersData(null);
                    setToSHipData(null);
                    setToShipList(null);
                    return;
            }
            try {
                const result = await axios.get(url);
                if (url === `${apiUrl}/OrderManage/NoFilterGetMains?page=1`)
                {
                    setOrdersData(result.data);
                }else if (url === `${apiUrl}/ToShip/GetMains?page=1`)
                {
                    setToSHipData(result.data);
                    setToShipList(result.data);
                }else if (url === `${apiUrl}/Inspection/GetOrders`)
                {
                    setOrdersData(result.data);
                }
                
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [activeTab]);  // ${apiUrl}/PurchasePrice/MainList
    const renderTabContent = (tabIndex) => {
            switch (tabIndex) {
                case 0:
                    return <Order data={dataOrders}/>;
                case 1:
                    return <Inspection data={dataOrders}/>;
                case 2:
                    return <ToShip data={dataToSHip}/>
                case 3:
                    return <ToShipList data={dataToShipList}/>;
                case 4:
                    return <OrderExcel/>;
                case 5:
                    return <HandOrder/>;
                default:
                    return null;
            }
    };
    return(
        <div>
            <Tabs tabsNames={tabArray} activeTab={activeTab} setActiveTab={setActiveTab}/>
            {renderTabContent(activeTab)}
        </div>
    )
}
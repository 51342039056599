import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../../apiUrl/ApiUrl';

export default function Machine({data}) {
    const [newData,setNewData] = useState(null);
    const [model,setModel] = useState('搜尋模式');
    const [editItem,setEditItem] = useState('')

    const workMargin = {
        marginTop: '5%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel,machineName) =>{
        setModel(newModel);
        if(machineName == null)
        {
            axios.get(`${apiUrl}/Machine/list`)
            .then(response => {
                if(response.data.statusCode === 200) {
    
                    setNewData(response.data);
    
                }else {
                    alert('失敗，請檢查網路問題')
                }
            })
            .catch(error => {
                // handle error
                alert(error);
            });
        } else if (machineName != null)
        {
            setEditItem(machineName);
        }

    }

    return(
        <div className='container-fluid'>
            <div className='row' style={workMargin}>
                <div className='col-1'>
                    <ChooseModel setModel={setModelandGetDate}/>
                </div>
                <div className='col-2'/>
                <div className='col-7'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                    {model === '修改模式'?<EditForm model={model} editItem={editItem} setNewData={setNewData}/> :<OperateForm setNewData={setNewData} model={model}/>}
                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null?<ListTable setNewData={setNewData} data={newData || data} setModel={setModelandGetDate}/> :null}
                </div>              
            </div>
        </div>
        
    )
}
function EditForm({model,editItem,setNewData}) {

    const [errorMessage, setErrorMessage] = useState('');
    const [errorNumMessage, setErrorNumMessage] = useState('');

    const handleSubmit = event => {
        if(event.target.elements.second.value.trim() !== '')
        {
            event.preventDefault();
            let MachineName = event.target.elements.MachineName.value;
            MachineName = MachineName.trim(); 
        
            if (model === '修改模式') {
                let second = event.target.elements.second.value;
                second = second.trim(); 
                const data = {
                    MachineName,
                    second
                };
                axios
                    .post(`${apiUrl}/Machine/edit`, data)
                    .then(response => {             
                        if (response.data === 200) {
                            alert('修改成功');
                            axios
                            .get(`${apiUrl}/Machine/Get?machineName=${MachineName}`)
                            .then(response => {
                                // handle success
                                if (response.data.statusCode === 200) {
                                    response.data.machinesList = [];
                                    response.data.machinesList.push(response.data.machine)
                                    setNewData(response.data);
                                } else {
                                    alert('修改失敗，找不到可以修改的機台名稱');
                                }
                            })
                            .catch(error => {
                                // handle error
                                alert(error);
                            });
                        } else {
                            alert('修改失敗');
                        }
                    })
                    .catch(error => {
                        // handle error
                        alert('修改失敗');
                    });
        }}else 
        {
            event.preventDefault();
            alert('請符合輸入資料格式');
        }
    };
    
    const checkValue = event => {
        if (!event.target.value.trim()) {
            setErrorMessage('欄位不得為空白')
        }else{
            setErrorMessage('')
        }
    };

    const checkNumValue = event => {
        const value = event.target.value.trim();
        if (value === '') {
            setErrorNumMessage('欄位不得為空白');
        } else if (!/^\d+(\.\d+)?$/.test(value)) {
            setErrorNumMessage('請輸入有效的小數或是整數');
        } else {
            setErrorNumMessage('');
        }
    };

    const renderForm = () => {
        if (model === '修改模式') {
          return (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="MachineName" className="form-label">機台名稱
                  <input type="text" className="form-control" id="MachineName" onBlur={checkValue} value={editItem} readOnly/>
                  <div id="emailHelp" className="form-text">{errorMessage}</div>
                </label>
              </div>
              <div className="mb-3">
                <label htmlFor="second" className="form-label">單位耗用時間
                  <input type="text" className="form-control" id="second" onBlur={checkNumValue} />
                  <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                </label>
              </div>
              <button type="submit" className="btn btn-dark">提交</button>
            </form>
          );
        }
        return null;
      };

    return(
        <>
        {renderForm()}
        </>      
    )
}
function ListTable({data,setNewData,setModel}) {

    const btnMargin = {
        marginLeft: '2%'
    }
// ${apiUrl}/WorkPlace/delete/WorkPlacemain?WorkPlaceName=%E5%89%AA%E5%BA%8A2
    const handleDelete = machineName => {
        axios.get(`${apiUrl}/Machine/DeleteMachinemain?MachineName=${machineName}`)
        .then(response => {
            // handle success
            if(response.data === 200) {
                axios.get(`${apiUrl}/Machine/list`)
                    .then(response => {
                        // Update the data
                        setNewData(response.data);
                        alert('刪除成功')
                    })
                    .catch(error => {
                        // handle error
                        console.log(error);
                        alert('刪除失敗，找不到可以刪除的機台名稱')
                    });
            }else {
                alert('刪除失敗，找不到可以刪除的機台名稱')
            }
        })
        .catch(error => {
            // handle error
            alert(error);
        });
    }  

    const handleEdit = machineName => {
        setModel('修改模式',machineName)

    }  
    return(
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">機台名稱</th>
                    <th scope="col">單位耗用時間</th>
                    <th scope="col">操作</th>
                </tr>
            </thead>
            <tbody>
                {data.machinesList.map((machine, index) => (
                    <tr key={uuidv4()}>
                        <th scope="row">{index + 1}</th>
                        <td>{machine.machineName}</td>
                        <td>{machine.second}</td>
                        <td>
                            <button type="button" className="btn btn-danger" onClick={() => handleDelete(machine.machineName)}>刪除</button>
                            <button type="button" style={btnMargin} className="btn btn-warning" onClick={() => handleEdit(machine.machineName)}>編輯</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

function OperateForm({setNewData,model}) {
    const [errorMessage, setErrorMessage] = useState('');
    const [errorNumMessage, setErrorNumMessage] = useState('');
    const [filter,setFilter] = useState('');

    const handleSubmit = event => {
        event.preventDefault();

        let MachineName = event.target.elements.MachineName.value;
        MachineName = MachineName.trim();

        if (MachineName !== '') {
            if (model === '新增模式') {
                let second = event.target.elements.second.value;
                second = second.trim();
                if(second !== '') {
                    const data = {
                        MachineName,
                        second
                    };

                    axios
                        .post(`${apiUrl}/Machine/CreateMachineMain`, data)
                        .then(response => {
                            // handle success
                            if (response.data === 200) {
                                axios
                                    .get(`${apiUrl}/Machine/list`)
                                    .then(response => {
                                        // Update the data
                                        setNewData(response.data);
                                    })
                                    .catch(error => {
                                        // handle error
                                        console.log(error);
                                    });
                            } else {
                                alert('新增失敗，已有相同的機台名稱');
                            }
                        })
                        .catch(error => {
                            // handle error
                            alert('新增失敗，已有相同的機台名稱');
                        });
                } else {
                    alert('單位耗用時間欄位不得為空白');
                }
            } else if (model === '搜尋模式') {
                axios
                    .get(`${apiUrl}/Machine/Get?machineName=${filter}`)
                    .then(response => {
                        // handle success
                        if (response.data.statusCode === 200) {
                            response.data.machinesList = [];
                            response.data.machinesList.push(response.data.machine)
                            setNewData(response.data);
                        } else {
                            alert('搜尋失敗，找不到可以搜尋的機台名稱');
                        }
                    })
                    .catch(error => {
                        // handle error
                        alert(error);
                    });
            }
        } else {
            alert('機台名稱欄位不得為空白');
        }
    };
    
    const checkValue = event => {
        if (!event.target.value.trim()) {
            setErrorMessage('欄位不得為空白')
        }else{
            setErrorMessage('')
        }
    };

    const checkNumValue = event => {
        const value = event.target.value.trim();
        if (value === '') {
            setErrorNumMessage('欄位不得為空白');
        } else if (!/^\d+(\.\d+)?$/.test(value)) {
            setErrorNumMessage('請輸入有效的小數或是整數');
        } else {
            setErrorNumMessage('');
        }
    };

    const renderForm = () => {
        if (model === '新增模式') {
          return (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="MachineName" className="form-label">機台名稱
                  <input type="text" className="form-control" id="MachineName" onBlur={checkValue} />
                  <div id="emailHelp" className="form-text">{errorMessage}</div>
                </label>
              </div>
              <div className="mb-3">
                <label htmlFor="second" className="form-label">單位耗用時間
                  <input type="text" className="form-control" id="second" onBlur={checkNumValue} />
                  <div id="emailHelp" className="form-text">{errorNumMessage}</div>
                </label>
              </div>
              <button type="submit" className="btn btn-dark">提交</button>
            </form>
          );
        }
    
        if (model === '搜尋模式') {
            return (
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="MachineName" className="form-label">機台名稱
                      <input type="text" className="form-control" id="MachineName" onChange={(e) => setFilter(e.target.value)} onBlur={checkValue} />
                      <div id="emailHelp" className="form-text">{errorMessage}</div>
                    </label>
                  </div>
                  <button type="submit" className="btn btn-dark">提交</button>
                </form>
              );
        }
        return null;
      };

    return(
        <>
        {renderForm()}
        </>      
    )
}

function ChooseModel({setModel}) {
    const buttonStyle = {
        display: 'block',
        width: '100%',
        border: 'none',
        backgroundColor: '#6c757d',
        color: 'white', // set the text color
        textAlign: 'center',
        padding: '10px 0'
    };

    return(
        <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
                <button type="button" style={buttonStyle} onClick={() => setModel('新增模式')}>新增模式</button>
            </div>
            <div className="accordion-item">
                <button type="button" style={buttonStyle} onClick={() => setModel('搜尋模式')}>搜尋模式</button>
            </div>
        </div>
    )
}
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg, pdf } from '@react-pdf/renderer';
import { Grid, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { apiUrl } from '../../apiUrl/ApiUrl';


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


const columns = [
  { field: 'id', headerName: 'ID', width: 20 },
  {
    field: '單號',
    headerName: '單號',
    width: 150,
    editable: false,
  },
  {
    field: '序',
    headerName: '序',
    width: 120,
    editable: false,
  },
  {
    field: '廠商',
    headerName: '廠商',
    width: 100,
    editable: false,
  },
];

const detailColumns = [
    { field: 'id', headerName: 'ID', width: 20 },
    {
      field: '圖號',
      headerName: '圖號',
      width: 150,
      editable: false,
    },
    {
      field: '物品名',
      headerName: '物品名',
      width: 150,
      editable: false,
    },
    {
      field: '單位',
      headerName: '單位',
      width: 150,
      editable: false,
    },
    {
        field: '數量',
        headerName: '數量',
        width: 150,
        editable: false,
      },
      {
        field: '單價',
        headerName: '單價',
        width: 150,
        editable: false,
      },
      {
        field: '金額',
        headerName: '金額',
        width: 150,
        editable: false,
      },
      {
        field: '批號',
        headerName: '批號',
        width: 150,
        editable: false,
      },
  ];

export default function StorageWork() {
    const [rows,setRows] = React.useState([]);
    const [month, setMonth] = React.useState(() => {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // 月份是从0开始的
      let dd = today.getDate();
    
      // 如果月份和日期小于10，前面添加0
      if (mm < 10) {
        mm = `0${mm}`;
      }
      if (dd < 10) {
        dd = `0${dd}`;
      }
    
      return `${yyyy}-${mm}-${dd}`;
    });
    const [orderNo,setoOderNo] = React.useState('');
    const [seletedRow,setSelectedRow] = React.useState([]);
    const [selectedOption, setSelectedOption] =React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [detail,setDetail] = React.useState({
        圖號:'',
        物品名:'',
        單位:'',
        數量:'',
        單價:'',
        金額:'',
        批號:'',
    });
    const [selectedInfo,setSelectedInfo] = React.useState({
        入庫日期:'',
        採購單號:'',
        廠商:'',
        對帳月份:'',
        入庫類別:'',
        序號:'',
        發票號碼:'',
    });
    const [open, setOpen] = React.useState(false);
    const [openPdf, setPdfOpen] = React.useState(false);

    const handlePdfClickOpen = () => {
      setPdfOpen(true);
    };

    const handlePdfClose = () => {
      setPdfOpen(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleCellClick = (params) => {
        console.log(params.row.入庫List[0].類別);
        setSelectedInfo({
            ...selectedInfo,
            採購單號:params.row.單號,
            入庫日期:params.row.入庫List.length>0?params.row.入庫List[0].日期:'',
            廠商:params.row.入庫List.length>0?params.row.入庫List[0].廠商:'',
            對帳月份:params.row.入庫List.length>0?params.row.入庫List[0].對帳月份:'',
            入庫類別:params.row.入庫List.length>0?params.row.入庫List[0].類別:'',
            序號:params.row.入庫List.length>0?params.row.入庫List[0].序:'',
            發票號碼:params.row.入庫List.length>0 && params.row.入庫List[0].發票no ?params.row.入庫List[0].發票no:'',
        });
        setSelectedRow(params.row.入庫List);
        const com = params.row.入庫List.length > 0 ? params.row.入庫List[0].廠商:'';
        if(com === '') return;
        const searchResults = options.filter(option => option.manufacturer_AbbreviationName.includes(com));
        setSelectedOption(searchResults[0]);

    };

    const handleAddDetail = () => {
        // 根据 selectedRows 的当前长度设置新的 id
        const newId = seletedRow.length + 1;
    
        // 创建一个新的 detail 对象，其中包含新的 id
        const newDetail = {
            ...detail,
            id: newId
        };
    
        // 将新的 detail 对象添加到 selectedRows 数组中
        setSelectedRow([...seletedRow, newDetail]);
    
        // 如果需要，可以在此处重置详细信息
        setDetail({
            圖號: '',
            物品名: '',
            單位: '',
            數量: '',
            單價: '',
            金額: '',
            批號: ''
        });
    };
    const fetchOptions = async () => {
        try {    
          const response = await axios.get(`${apiUrl}/Manufacturer/list`);
          if (response.status === 200) {
            setOptions(response.data.manufacturers);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };

    const handleBaseChange = (field, value) => {
        setSelectedInfo({
            ...selectedInfo,
            [field]: value
        });
    };

    
const handleSummit = async () => {

    const requestData = {
        BaseSet: selectedInfo,
        Details: seletedRow
    };

    try {
        const response = await axios.post(`${apiUrl}/Storage/AddInStock`, requestData);
        if (response.status === 200) {
            console.log('Success:', response.data);
            handlePurchaseSearch();
            alert('提交成功');
        }
    } catch (error) {
        console.error('Error:', error);
        alert('提交失败');
    }
    };

    const handleSearch =async() => {

        try {
            const response= await axios.get(`${apiUrl}/Storage/GetInStockList?month=${month}`);
        
            if (response.status === 200) {
              alert('查詢成功');
              setRows(response.data)
              const com = response.data.length > 0 ? response.data[0].廠商:'';
              if(com === '') return;
              const searchResults = options.filter(option => option.manufacturer_AbbreviationName.includes(com));
              setSelectedOption(searchResults[0]);
            } else {
              alert('查詢失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('查詢失败');
          }
    };
    const handleDetailSearch =async() => {

        try {
            const response= await axios.get(`${apiUrl}/Storage/GetInStockDetail?orderNo=${orderNo}`);
        
            if (response.status === 200) {
              alert('查詢成功');
              setSelectedRow(response.data)
              const com = response.data.length > 0 ? response.data[0].廠商:'';
              if(com === '') return;
              const searchResults = options.filter(option => option.manufacturer_AbbreviationName.includes(com));
              setSelectedOption(searchResults[0]);

              setSelectedInfo({
                ...selectedInfo,
                入庫日期:response.data.length>0?response.data[0].日期:'',
                採購單號:response.data.length>0?response.data[0].單號:'',
                對帳月份:response.data.length>0?response.data[0].對帳月份:'',
                入庫類別:response.data.length>0?response.data[0].類別:'',
                序號:response.data.length>0?response.data[0].序:'',
                發票號碼:response.data.length>0 && response.data[0].發票no ?response.data[0].發票no:'',

              });
            } else {
              alert('查詢失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('查詢失败');
          }
    };

    const handlePurchaseSearch =async(value) => {

        try {
            const response= await axios.get(`${apiUrl}/Storage/GetDataFromPurchase?orderNo=${value}`);
        
            if (response.status === 200) {
              alert('查詢成功');
              setSelectedRow(response.data)
              const com = response.data.length > 0 ? response.data[0].廠商:'';
              if(com === '') return;
              const searchResults = options.filter(option => option.manufacturer_AbbreviationName.includes(com));
              setSelectedOption(searchResults[0]);
              setSelectedInfo({
                    ...selectedInfo,
                    入庫日期:response.data.length>0?response.data[0].日期:'',
                    採購單號:response.data.length>0?response.data[0].單號:'',
                    入庫類別:response.data.length>0?response.data[0].類別:'',
                    序號:response.data.length>0?response.data[0].序:'',
                
              });
            } else {
              alert('查詢失败');
            }
          } catch (error) {
            console.error('Failed to add data:', error);
            alert('查詢失败');
          }
    };

    const handleOptionChange = (event, newValue) => {
        setSelectedOption(newValue);
        if(newValue === null || newValue === undefined) return;
        setSelectedInfo((prevRequest) => ({   
            ...prevRequest,
            廠商: newValue.manufacturer_AbbreviationName || "",
        })); 
    };

    React.useEffect(() => {
    fetchOptions();
    }, []); 
    React.useEffect(() => {
    if(selectedOption !== null && selectedOption !== undefined) {
        setSelectedInfo((prevRequest) => ({   
            ...prevRequest,
            廠商: selectedOption.manufacturer_AbbreviationName || "",
        })); 
    }
    }, [selectedOption]);
  return (
    <Box sx={{ height: 600, width: '100%', display: 'flex',marginTop:'5%'  }}>
        <Box sx={{ height: 1200, width: '30%'}}>
            <TextField
            style={{width:'50%'}}
            id="standard-search"
            label="月份選擇"
            type="search"
            placeholder='yyyy-MM'
            variant="standard"
            value={month}
            onChange={(e)=>setMonth(e.target.value)}
            />
            <Button variant="contained" endIcon={<SendIcon />} onClick={handleSearch}>
                搜尋
            </Button>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 20,
                    },
                },
                }}
                onCellClick={handleCellClick}
                pageSizeOptions={[20,30,40]}
                disableRowSelectionOnClick
            />
        </Box>
        <Box sx={{ height: 600, width: '65%',marginLeft:'20px'}}>
            <TextField
            style={{width:'50%'}}
            id="standard-search"
            label="單號查詢"
            type="search"
            variant="standard"
            value={orderNo}
            onChange={(e)=>setoOderNo(e.target.value)}
            />
            <Button variant="contained" endIcon={<SendIcon />} onClick={handleDetailSearch}>
                查詢
            </Button>
            <Grid container spacing={2} style={{marginTop:'20px'}}>
                <Grid item xs={3}>
                    <TextField
                    style={{width:'50%'}}
                    id="standard-search"
                    label="入庫日期"
                    type="search"
                    variant="standard"
                    value={selectedInfo.入庫日期}
                    onChange={(e) => handleBaseChange('入庫日期', e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                    style={{width:'50%'}}
                    id="standard-search"
                    label="採購單號"
                    type="search"
                    variant="standard"
                    value={selectedInfo.採購單號}
                    onChange={(e) => handleBaseChange('採購單號', e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handlePurchaseSearch(e.target.value)
                        }
                    }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={options}
                            sx={{ width: '100%' }}
                            value={selectedOption}
                            onChange={handleOptionChange}
                            getOptionLabel={(option) => option.manufacturer_Seq+ option.manufacturer_AbbreviationName}
                            renderInput={(params) => <TextField {...params} label="廠商" />}
                        />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                    style={{width:'50%'}}
                    id="standard-search"
                    label="對帳月份"
                    type="search"
                    variant="standard"
                    value={selectedInfo.對帳月份}
                    onChange={(e) => handleBaseChange('對帳月份', e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl style={{width:'50%'}}>
                        <InputLabel id="入庫類別-label">入庫類別</InputLabel>
                        <Select
                            labelId="入庫類別-label"
                            id="入庫類別-select"
                            value={selectedInfo.入庫類別}
                            onChange={(e) => handleBaseChange('入庫類別', e.target.value)}
                        >
                            <MenuItem value="尚未選擇">尚未選擇</MenuItem>
                            <MenuItem value="入庫">入庫</MenuItem>
                            <MenuItem value="外購">外購</MenuItem>
                            <MenuItem value="託外入庫">託外入庫</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                    style={{width:'50%'}}
                    id="standard-search"
                    label="序號"
                    type="search"
                    variant="standard"
                    value={selectedInfo.序號}
                    onChange={(e) => handleBaseChange('序號', e.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                    style={{width:'50%'}}
                    id="standard-search"
                    label="發票號碼"
                    type="search"
                    variant="standard"
                    value={selectedInfo.發票號碼}
                    onChange={(e) => handleBaseChange('發票號碼', e.target.value)}
                    />
                </Grid>    
                <Grid item xs={3}>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleSummit}>
                    儲存
                    </Button>
                </Grid> 
                <Grid item xs={3}>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={handlePdfClickOpen}>
                    入庫PDF
                    </Button>
                </Grid> 
                <Grid item xs={3}>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleClickOpen}>
                    新增
                    </Button>
                </Grid> 
                <Dialog
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                      event.preventDefault();
                      const formData = new FormData(event.currentTarget);
                      const formJson = Object.fromEntries(formData.entries());
                      const email = formJson.email;
                      console.log(email);
                      handleClose();
                    },
                  }}
                >
                <DialogTitle>新增</DialogTitle>
                  <DialogContent>
                  <Grid container spacing={2} style={{marginTop:'20px'}}>
                  <Grid item xs={12}>
                        <TextField
                        style={{width:'50%'}}
                        id="standard-search"
                        label="採購單號 //請按z搜尋"
                        type="search"
                        variant="standard"
                        value={selectedInfo.採購單號}
                        onChange={(e) => handleBaseChange('採購單號', e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'z') {
                            e.preventDefault(); 
                            handlePurchaseSearch(e.target.value);
                        }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                        style={{width:'50%'}}
                        id="standard-search"
                        label="入庫日期"
                        type="search"
                        variant="standard"
                        value={selectedInfo.入庫日期}
                        onChange={(e) => handleBaseChange('入庫日期', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={options}
                                sx={{ width: '100%' }}
                                value={selectedOption}
                                onChange={handleOptionChange}
                                getOptionLabel={(option) => option.manufacturer_Seq+ option.manufacturer_AbbreviationName}
                                renderInput={(params) => <TextField {...params} label="廠商" />}
                            />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                        style={{width:'50%'}}
                        id="standard-search"
                        label="對帳月份"
                        type="search"
                        variant="standard"
                        value={selectedInfo.對帳月份}
                        onChange={(e) => handleBaseChange('對帳月份', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl style={{width:'50%'}}>
                            <InputLabel id="入庫類別-label">入庫類別</InputLabel>
                            <Select
                                labelId="入庫類別-label"
                                id="入庫類別-select"
                                value={selectedInfo.入庫類別}
                                onChange={(e) => handleBaseChange('入庫類別', e.target.value)}
                            >
                                <MenuItem value="尚未選擇">尚未選擇</MenuItem>
                                <MenuItem value="入庫">入庫</MenuItem>
                                <MenuItem value="外購">外購</MenuItem>
                                <MenuItem value="託外入庫">託外入庫</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                        style={{width:'50%'}}
                        id="standard-search"
                        label="序號"
                        type="search"
                        variant="standard"
                        value={selectedInfo.序號}
                        onChange={(e) => handleBaseChange('序號', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                        style={{width:'50%'}}
                        id="standard-search"
                        label="發票號碼"
                        type="search"
                        variant="standard"
                        value={selectedInfo.發票號碼}
                        onChange={(e) => handleBaseChange('發票號碼', e.target.value)}
                        />
                    </Grid>   
                    <Grid item xs={12}>
                      <DataGrid
                        rows={seletedRow}
                        columns={detailColumns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 20,
                            },
                        },
                        }}
                        pageSizeOptions={[20,30,40]}
                        disableRowSelectionOnClick
                        sx={{ height: 400 }}
                      />   
                    </Grid>  
                  </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={handleSummit}>新增採購到入庫</Button>
                  </DialogActions>
                </Dialog>    
                    <Dialog
                        fullScreen
                        open={openPdf}
                        onClose={handlePdfClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handlePdfClose}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>
                            <Button autoFocus color="inherit" onClick={handlePdfClose}>
                            列印預覽
                            </Button>
                        </Toolbar>
                        </AppBar>
                        <PDFViewer style={{ width: '100%', height: '100%' }}>
                            {seletedRow.length > 0 ? <MyDocument data={seletedRow} /> : null}
                        </PDFViewer> 
                    </Dialog> 
            </Grid>
            <DataGrid
                rows={seletedRow}
                columns={detailColumns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 20,
                    },
                },
                }}
                pageSizeOptions={[20,30,40]}
                disableRowSelectionOnClick
            />
        </Box>
    </Box>
  );
}



Font.register({
  family: 'TaipeiSansTCBeta-Bold',
  src: 'path/to/TaipeiSansTCBeta-Bold.ttf', // 替换为您的实际字体文件路径
});
Font.register({
  family: 'TaipeiSansTCBeta-Light',
  src: 'path/to/TaipeiSansTCBeta-Light.ttf', // 替换为您的实际字体文件路径
});
// 页面宽高转换为点（1cm = 28.28点）
const pageWidth = 24.1 * 72 / 2.54;
const pageHeight = 14 * 72 / 2.54;

// 表格宽度比例，根据您的表格设计调整
const cellWidth = pageWidth / 6; // 假设有8列

const styles = StyleSheet.create({
  page: {
      flexDirection: 'column',
      backgroundColor: 'white',
      padding: 24,
      width: pageWidth,
      height: pageHeight,
  },
  onePage: {
      flexDirection: 'row',
      backgroundColor: 'white',
      padding: 24,
      width: pageWidth,
      height: pageHeight,
  },
  title: {
      fontSize: 20,
      textAlign: 'center',
      fontFamily: 'TaipeiSansTCBeta-Bold',
      marginBottom: 10,
  },
  smallTitle: {
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'TaipeiSansTCBeta-Light',
    marginBottom: 10,
  },
  tableContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      fontFamily: 'TaipeiSansTCBeta-Bold', // 新增此行以确保容器内的内容（标题）居中
      width: '100%', // 确保容器宽度充满整个页面或其父容器
  },  
  headerRow: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 5, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerDashRow: {
    flexDirection: 'row',
    // borderBottomWidth: 1,
    // borderTopWidth: 1,
    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    // borderColor: 'black',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 5, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerBottomRow: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 120, // 根据需要调整，为标题和标头之间提供一些空间
  },
  headerRowDash: {
    flexDirection: 'row',
    width: '100%', // 确保行宽充满整个页面
    textAlign: 'center', 
    fontFamily: 'TaipeiSansTCBeta-Bold',// 确保文本居中（对于Text组件可能不起作用，需要在Text组件中单独设置）
    marginTop: 10, // 标题和标头之间的空间
    // borderTop: 1, // 添加上边框
    // borderBottom: 1, // 添加下边框
    // borderColor: 'black', // 边框颜色
    // paddingTop: 5, // 标头内容与上边框之间的空间
    // paddingBottom: 5, // 标头内容与下边框之间的空间
  },
  headerDashItem: {
    flex: 1, // 平分容器宽度
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
    borderWidth: 1,
    borderColor: 'black',
    paddingTop: 5, // 增加上边距
    paddingBottom: 5, // 增加下边距
},

  headerItem: {
      flex: 1, // 平分容器宽度
      textAlign: 'center', // 文本居中
      fontFamily: 'TaipeiSansTCBeta-Light',
      fontSize: 10,
  },
  header物品名: {
    width: '20%',
    textAlign: 'center', // 文本居中
    fontFamily: 'TaipeiSansTCBeta-Light',
    fontSize: 10,
  }
});

const currentDate = new Date();
const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份需要加1，因为它从0开始
const currentDay = String(currentDate.getDate()).padStart(2, '0');
const currentYear = String(currentDate.getFullYear());
const dateString = `${currentYear}/${currentMonth}/${currentDay}`;
// 创建文档组件
const MyDocument = ({ data}) => ( 

  <Document>
      <Page size={{ width: 842, height: 595 }} style={styles.page}>
        <View style={styles.tableContainer}>
          <Text style={styles.title}>福蒙精密工業股份有限公司</Text> 
        </View>
        <View style={styles.tableContainer}>
          <Text style={styles.title}>入庫驗收單</Text>
        </View>
        <View style={styles.tableContainer}>
          <Text style={styles.headerItem}>廠商:{data[0].廠商}</Text>
          <Text style={styles.headerItem}>入庫類別:{data[0].類別}</Text>
        </View>
        <View style={styles.tableContainer}>
          <Text style={styles.headerItem}>入庫倉庫:{data[0].memo}</Text>
          <Text style={styles.headerItem}>入庫單號:{data[0].單號}</Text>
        </View>
        <View style={styles.tableContainer}>
          <Text style={styles.headerItem}>序號:{data[0].序}</Text>
          <Text style={styles.headerItem}>入庫日期:{dateString}</Text>
        </View>
        <View style={styles.headerDashRow}>
            <Text style={styles.headerDashItem}>圖號</Text>
            <Text style={styles.headerDashItem}>物品名</Text>
            <Text style={styles.headerDashItem}>單位</Text>
            <Text style={styles.headerDashItem}>數量</Text>
            <Text style={styles.headerDashItem}>批號</Text>
        </View>
      {data.map((item, index) => (
      <>
        <View style={styles.headerDashRow}>
            <Text style={styles.headerDashItem}>{item.圖號}</Text>
            <Text style={styles.headerDashItem}>{item.物品名}</Text>
            <Text style={styles.headerDashItem}>{item.單位}</Text>
            <Text style={styles.headerDashItem}>{item.數量}</Text>
            <Text style={styles.headerDashItem}>{item.批號}</Text>
        </View>
        </>     
        ))}
        <View style={styles.headerBottomRow}>
            <Text style={styles.headerItem}>核准:</Text>
            <Text style={styles.headerItem}>審核:</Text>
            <Text style={styles.headerItem}>採購:</Text>
            <Text style={styles.headerItem}>品管:</Text>
            <Text style={styles.headerItem}>倉管:</Text>
        </View>
      </Page>            
  </Document>
);
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { apiUrl } from '../../apiUrl/ApiUrl';




const options = [
    { value: '空白', label: '空白' },
    { value: '盤點', label: '盤點' },
    { value: '調帳', label: '調帳' },
    { value: '生產領用', label: '生產領用'},
    { value: '採購', label: '採購' },
    { value: '入庫', label: '入庫' },
    { value: '出貨扣帳', label: '出貨扣帳'},
];


const columns = [
  { id: 'drawNum', label: '圖號', minWidth: 170 },
  { id: 'total', label: '總計', minWidth: 100 },
  {
    id: 'january',
    label: '一月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'february',
    label: '二月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'march',
    label: '三月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'april',
    label: '四月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'may',
    label: '五月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'june',
    label: '六月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'july',
    label: '七月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'august',
    label: '八月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'september',
    label: '九月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'october',
    label: '十月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'november',
    label: '十一月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'december',
    label: '十二月',
    minWidth: 70,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  }
];

const currentYear = new Date().getFullYear();

export default function MatCardStatistics() {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [Kind,setKind] = React.useState('空白');
  const [Year,setYear] = React.useState(currentYear);

  const yearOptions = [];
  // eslint-disable-next-line no-plusplus
  for (let i = currentYear - 10; i <= currentYear + 10; i++) {
    yearOptions.push({ value: i.toString(), label: i.toString() });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    const response = await axios.get(`${apiUrl}/MatCard/GetStatisticsMains?kind=${Kind}&month=${Year}`);
    setRows(response.data.matStatistics);
    setPage(0);  
  };

  const fetchData = async () => {
    try {    
      const response = await axios.get(`${apiUrl}/MatCard/GetStatisticsMains`);
      if (response.status === 200) {
        setRows(response.data.matStatistics);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


    useEffect(() => {
      fetchData();
      setPage(0);
    }, []); 

  return (
    <>
        <Box mt={2}>
            <Grid container spacing={10}>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        年份
                        </InputLabel>
                        <NativeSelect
                        onChange={(e)=>setYear(e.target.value)}
                        defaultValue={currentYear}
                        inputProps={{
                            name: 'year',
                            id: 'uncontrolled-native',
                        }}
                        >
                        {yearOptions.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        類別
                        </InputLabel>
                        <NativeSelect
                        onChange={(event) => setKind(event.target.value)}
                        defaultValue={Kind}
                        inputProps={{
                            name: 'kind',
                            id: 'uncontrolled-native',
                        }}
                        >
                        {options.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                        </NativeSelect>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Stack spacing={20} direction="row">
                        <Button variant="outlined" onClick={handleSubmit}>搜尋</Button>
                        <Button variant="outlined">列印</Button>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                        <TableRow hover role="checkbox" tabIndex={-1} key={uuidv4()}>
                            {columns.map((column) => {
                            const value = row[column.id];
                            return (
                                <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                        ))}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    </>
    
  );
}
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import { apiUrl } from '../../apiUrl/ApiUrl';


const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'mainDrawNum',
    headerName: '主件圖號',
    width: 200,
    editable: true,
  },
  {
    field: 'buildDate',
    headerName: '建檔日期',
    width: 200,
    editable: true,
  },
  {
    field: 'preDays',
    headerName: '提前天數',
    width: 200,
    editable: true,
  }
];

export default function BomMainDataGrid({data, newData, onMainDrawNumSelect}) {
  const [editItem, setEditItem] = useState(null);
  const [rows, setRows] = useState([]);  // 初始化為空陣列

  // 初始化 rows 資料，處理多筆資料
  useEffect(() => {
    if (newData && newData.mainBoms) {
      setRows(newData.mainBoms);  // newData 有多筆資料
    } else if (data && data.mainBoms) {
      setRows(data.mainBoms);  // 原始資料包含多筆
    }
  }, [data, newData]);  // 依賴於 data 和 newData

  const handleSave = async () => {
    try {
      const response = await axios.post(`${apiUrl}/Bom/EditMain`, editItem);
      if (response.data.statusCode === 200) {
        setRows(response.data.mainBoms);  // 更新多筆資料
        alert('修改成功');
      } else {
        alert('修改失敗');
      }
    } catch (error) {
      console.error('Failed to update BOM data:', error);
      alert('修改失敗');
    }
  };

  const processRowUpdate = (newRow, oldRow) => {
    setEditItem(newRow);  // 將更新後的行設定為 editItem
    return newRow;
  };

  const handleRowDoubleClick = (params) => {
    const mainDrawNum = params.row.mainDrawNum;  // 假設主件圖號存放在 mainDrawNum 欄位
    if (onMainDrawNumSelect) {
      onMainDrawNumSelect(mainDrawNum);  // 透過回調函數傳回給父元件
    }
  };

  return (
    <Box sx={{ height: 400, width: '80%', margin: 'auto' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 20]}
        disableRowSelectionOnClick
        onRowDoubleClick={handleRowDoubleClick}  // 監聽雙擊事件
      />
    </Box>
  );
}

import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Document, Page, Text, StyleSheet, View, Image,PDFDownloadLink ,PDFViewer ,Font ,Svg } from '@react-pdf/renderer';
import chFont from '../../../css/font/TaipeiSansTCBeta-Bold.ttf'
import chLight from '../../../css/font/TaipeiSansTCBeta-Light.ttf'
import { apiUrl } from '../../apiUrl/ApiUrl';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function StoDetails({data}) {
    const [newData,setNewData] = useState(null);
    const [selected, setSelected] = useState('');
    const [detailData,setDetailData] = useState(null);
    const [model,setModel] = useState('採購入庫明細表');
    const [editData,setEditData] = useState(null);


    const [orderNum,setOrderNum] = useState('');
    const [start,setStart] = useState('');
    const [end,setEnd] = useState('');

    console.log(data)
    const [page,setPage] = useState(1);
    // console.log(data)
    const workMargin = {
        marginTop: '3%',
        marginBottom:'5%'
    } 


    const setModelandGetDate = (newModel) =>{
        setModel(newModel);
    }

    return(
        <div className='container-fluid'>
            <div className='row'  style={workMargin}>
                <div className='col-5'/>
                <div className='col-2'>
                    <h2><span className="badge bg-success">{model}</span></h2>
                </div>
                <div className='col-5'/>              
            </div>
            <div className='row' style={workMargin}>
                <div className='col-3'>
                    {/* <ChooseModel setModel={setModel} setNewData={setNewData} setSelected={setSelected}/> */}
                </div>
                <div className='col-5'>
                    {/* {model === '採購入庫新增' ? <CreateForm/> : null}
                    {model === '採購入庫修改' ? <CreateForm editData={editData}/> : null} */}
                    {/* {model === '採購入庫查詢'? <SelectFilter setNewData={setNewData}/> : null} */}
                </div>
                <div className='col-2'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">搜尋條件</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <SelectFilter setNewData={setNewData} orderNum={orderNum} 
                    start={start} setOrderNum={setOrderNum} setEnd={setEnd} setStart={setStart}
                    end={end}/>
                    {/* {detailData != null ? <DetailListTable data={detailData} 
                    setDetailData={setDetailData} 
                    setModel={setModel}
                    setEditData={setEditData}/> : <div>目前沒有副表資料</div>} */}
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'>
                    <h2><span className="badge bg-dark">採購入庫資料</span></h2>
                </div>              
            </div>
            <div className='row'>
                <div className='col-12'/>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {data != null || newData != null?<ListTable setDetailData={setDetailData} 
                    setNewData={setNewData} data={newData || data} 
                    setModel={setModelandGetDate} page={page} 
                    setPage={setPage}
                    start={start}
                    end={end}
                    orderNum={orderNum}/> :<div>目前還沒取得資料或取不到資料</div>}
                </div>              
            </div>
        </div>     
    )
}

function ListTable({data,setNewData,setModel,setDetailData,page,setPage,orderNum,start,end}) {
    const [open, setOpen] = React.useState(false);
    const thStyle = {
        centered: {
            textAlign: 'center',
          }
    }
    const btnPageMargin = {
        marginLeft: '2%',
        marginRight: '2%',
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const nextPage = () => {
            axios.get(`${apiUrl}/Storage/GetMainsByFilter?orderNum=${orderNum}&start=${start}&end=${end}&page=${page+1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page+1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }

    const prePage = () => {
            axios.get(`${apiUrl}/Storage/GetMainsByFilter?orderNum=${orderNum}&start=${start}&end=${end}&page=${page-1}`)
            .then(response => {
                if(response.data.statusCode === 200) {
                    setNewData(response.data)
                    setPage(page-1)
                }else{
                    alert('已經是最後一筆了');
                }            
            })
            .catch(error =>{
                // handle error
                console.log(error);
            })
        
    }


    const GetDetails = (orderNum) => {
        axios.get(`${apiUrl}/Storage/GetDetailMains?orderNum=${orderNum}`)
        .then(response => {
            if(response.data.statusCode === 200) {
                setDetailData(response.data)
            }else{
                alert('找不到資料');
            }            
        })
        .catch(error =>{
            // handle error
            console.log(error);
        })
    }

    return(
        <>
        <button
        type="button"
        className="btn btn-warning"
        onClick={handleClickOpen}
        disabled={!data} // Button is disabled if data.storage is null or undefined
        >
        列印
        </button>
        <div style={{overflowX: "auto", height: "70vh",margin:'20px'}}>
        <table className="table custom-table table-striped" style={{width: "100%"}}>
            <thead className="sticky-header">
                    <tr>
                        <th scope="col" style={thStyle.centered}>#</th>
                        <th scope="col" style={thStyle.centered}>日期</th>
                        <th scope="col" style={thStyle.centered}>單號</th>
                        <th scope="col" style={thStyle.centered}>圖號</th>
                        <th scope="col" style={thStyle.centered}>物品名</th>
                        <th scope="col" style={thStyle.centered}>單位</th>
                        <th scope="col" style={thStyle.centered}>批號</th>
                        <th scope="col" style={thStyle.centered}>數量</th>
                        <th scope="col" style={thStyle.centered}>單價</th>
                        <th scope="col" style={thStyle.centered}>金額</th>
                    </tr>
                </thead>
                {data.storages !== null ?<tbody>
                    {data.storages.map((item, index) => (
                        <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>{index + 1}</th>
                            <td style={thStyle.centered}>{item.date}</td>
                            <td style={thStyle.centered}>{item.orderNum}</td>
                            <td style={thStyle.centered}>{item.drawNum}</td>     
                            <td style={thStyle.centered}>{item.itemName}</td>    
                            <td style={thStyle.centered}>{item.unit}</td>    
                            <td style={thStyle.centered}>{item.piNum}</td>    
                            <td style={thStyle.centered}>{item.amount}</td>    
                            <td style={thStyle.centered}>{item.unitPrice}</td>    
                            <td style={thStyle.centered}>{item.price}</td>             
                        </tr>
                    ))}
                </tbody>:null}
                {data.storage !== null ? 
                <tbody>
                    <tr key={uuidv4()}>
                            <th scope="row" style={thStyle.centered}>{1}</th>
                            <td style={thStyle.centered}>{data.storage.orderNum}</td>
                            <td style={thStyle.centered}>{data.storage.order}</td>
                            <td style={thStyle.centered}>{data.storage.manufacturers}</td>  
                            <td style={thStyle.centered}>
                                <button type="button" className="btn btn-warning" onClick={() => GetDetails(data.item.orderNum)}>詳細</button>
                            </td>             
                        </tr>
                </tbody>: null}
                
            </table>
            </div>
            <div style={{textAlign: 'center'}} className='mt-3'>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => prePage()} disabled={page === 1}>上一頁</button>
                    <span style={{margin: '0 10px'}}>目前在第{page}頁</span>
                <button type="button" style={btnPageMargin} className="btn btn-dark" onClick={() => nextPage()}>下一頁</button>
            </div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative',backgroundColor:'black' }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                </Toolbar>
                </AppBar>
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <MyDocument data={data.storages} start={start} end={end}/>
                </PDFViewer> 
            </Dialog>
        </>
    )
}
  
Font.register({
    family: 'TaipeiSansTCBeta-Bold',
    src: chFont, // 使用您的实际路径
  });
Font.register({
family: 'TaipeiSansTCBeta-Light',
src: chLight, // 使用您的实际路径
});
const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontSize: 11,
      fontFamily: 'TaipeiSansTCBeta-Light',
    },
    title: {
      fontSize: 18,
      textAlign: 'center',
      marginBottom: 20,
      fontFamily: 'TaipeiSansTCBeta-Bold',
    },
    smalltitle: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20,
        fontFamily: 'TaipeiSansTCBeta-Bold',
    },
    dateContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10, // 或你需要的值
      },
      sideDate: {
        margin: 5, // 或你需要的值
        // 如果你需要指定寬度，可以在這裡設置
      },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    // Custom widths for each column
    tableCol1: { width: '10%', borderStyle: 'solid', borderColor: '#bfbfbf', borderWidth: 1, },
    tableCol2: { width: '10%', borderStyle: 'solid', borderColor: '#bfbfbf', borderWidth: 1, },
    tableCol3: { width: '10%', borderStyle: 'solid', borderColor: '#bfbfbf', borderWidth: 1, },
    tableCol4: { width: '25%', borderStyle: 'solid', borderColor: '#bfbfbf', borderWidth: 1, },
    tableCol5: { width: '5%', borderStyle: 'solid', borderColor: '#bfbfbf', borderWidth: 1, },
    tableCol6: { width: '10%', borderStyle: 'solid', borderColor: '#bfbfbf', borderWidth: 1, },
    tableCol7: { width: '10%', borderStyle: 'solid', borderColor: '#bfbfbf', borderWidth: 1, },
    tableCol8: { width: '10%', borderStyle: 'solid', borderColor: '#bfbfbf', borderWidth: 1, },
    tableCol9: { width: '10%', borderStyle: 'solid', borderColor: '#bfbfbf', borderWidth: 1, },
    tableCell: {
      margin: 'auto',
      marginTop: 5,
      fontSize: 10,
      fontFamily: 'TaipeiSansTCBeta-Light',
    },
  });
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  };
  // Create Document Component
  const MyDocument = ({ data,start,end }) => {
    const printDate = formatDate(new Date());
    return (
      <Document>
        <Page size={{ width: 841.89, height: 595.28 }} style={styles.page}>
          <View style={styles.title}>
            <Text>福蒙精密工業股份有限公司</Text>
          </View>
          <View style={styles.smalltitle}>
            <Text>採購入庫明細表</Text>
          </View>
          <View style={styles.dateContainer}>
            <View style={styles.sideDate}>
                <Text>日期:{start}~{end}</Text>
            </View>
            <View style={styles.sideDate}>
            <Text>列印日期:{printDate}</Text>
          </View>
        </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, styles.tableCol1]}>
                <Text style={styles.tableCell}>日期</Text>
              </View>
              <View style={[styles.tableCol, styles.tableCol2]}>
                <Text style={styles.tableCell}>單號</Text>
              </View>
              <View style={[styles.tableCol, styles.tableCol3]}>
                <Text style={styles.tableCell}>圖號</Text>
              </View>
              <View style={[styles.tableCol, styles.tableCol4]}>
                <Text style={styles.tableCell}>物品名</Text>
              </View>
              <View style={[styles.tableCol, styles.tableCol5]}>
                <Text style={styles.tableCell}>單位</Text>
              </View>
              <View style={[styles.tableCol, styles.tableCol6]}>
                <Text style={styles.tableCell}>批號</Text>
              </View>
              <View style={[styles.tableCol, styles.tableCol7]}>
                <Text style={styles.tableCell}>數量</Text>
              </View>
              <View style={[styles.tableCol, styles.tableCol8]}>
                <Text style={styles.tableCell}>單價</Text>
              </View>
              <View style={[styles.tableCol, styles.tableCol9]}>
                <Text style={styles.tableCell}>金額</Text>
              </View>
            </View>
  
            {/* Table Rows for Data */}
            {data.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                <View style={[styles.tableCol, styles.tableCol1]}>
                  <Text style={styles.tableCell}>{item.date.split('T')[0]}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol2]}>
                  <Text style={styles.tableCell}>{item.orderNum}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol3]}>
                  <Text style={styles.tableCell}>{item.drawNum}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol4]}>
                  <Text style={styles.tableCell}>{item.itemName}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol5]}>
                  <Text style={styles.tableCell}>{item.unit}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol6]}>
                  <Text style={styles.tableCell}>{item.piNum}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol7]}>
                  <Text style={styles.tableCell}>{item.amount}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol8]}>
                  <Text style={styles.tableCell}>{item.unitPrice}</Text>
                </View>
                <View style={[styles.tableCol, styles.tableCol9]}>
                  <Text style={styles.tableCell}>{item.price}</Text>
                </View>
              </View>
            ))}
  
            {/* ... more rows as needed */}
          </View>
        </Page>
      </Document>
    );
  };
  






function SelectFilter({setNewData,orderNum,setOrderNum,start,setStart,end,setEnd}) {

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Replace with your actual endpoint
        const response = await axios.get(`${apiUrl}/Storage/GetMainsByFilter?orderNum=${orderNum}&start=${start}&end=${end}`);
        if(response.data.storages != null) {
            setNewData(response.data)
        }else {
            alert('找不到資料')
        }
    };
    return(
        <form onSubmit={handleSubmit}>
        <div className='container-fluid'>
            <div className='row mb-3'>
                <div className='col-6'>
                    <label htmlFor="inputText1">圖號
                    <input type="text"  className="form-control" id="inputText1" value={orderNum} onChange={(e) => setOrderNum(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-6'>
                    <label htmlFor="inputText1">起始日
                    <input type="text" placeholder='yyyy-mm-dd'  className="form-control" id="inputText1" value={start} onChange={(e) => setStart(e.target.value)}/>
                    </label>
                </div>
                <div className='col-6'>
                    <label htmlFor="inputText1">終點日
                    <input type="text" placeholder='yyyy-mm-dd'  className="form-control" id="inputText1" value={end} onChange={(e) => setEnd(e.target.value)}/>
                    </label>
                </div>
            </div>
            <div className='row' style={{ textAlign: 'center' }}>
                <div className='col-12'>
                    <button type="submit" className="btn btn-dark">搜尋</button>
                </div>
            </div>
        </div>      
  </form>
    )
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import { apiUrl } from '../../apiUrl/ApiUrl';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: '客戶',
    headerName: '客戶',
    width: 150,
  },
  {
    field: '出貨日期',
    headerName: '出貨日期',
    width: 150,
  },
  {
    field: '出貨單號',
    headerName: '出貨單號',
    width: 110,
  },
  {
    field: '訂單號碼',
    headerName: '訂單號碼',
    width: 110,
  },
  {
    field: '流水號',
    headerName: '流水號',
    width: 110,
  },
  {
    field: '批號',
    headerName: '批號',
    width: 110,
  },
  {
    field: '圖號',
    headerName: '圖號',
    width: 110,
  },
  {
    field: '物品名',
    headerName: '物品名',
    width: 110,
  },
  {
    field: '實交貨數',
    headerName: '實交貨數',
    type: 'number',
    width: 110,
  },
  {
    field: '訂購單價',
    headerName: '訂購單價',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: '應收金額',
    headerName: '應收金額',
    type: 'number',
    width: 110,
  },
  {
    field: '發票日期',
    headerName: '發票日期',
    width: 110,
  },
  {
    field: '發票號碼',
    headerName: '發票號碼',
    width: 110,
  },
  {
    field: '承製公司',
    headerName: '承製公司',
    width: 110,
  },
  {
    field: '帳款註記',
    headerName: '帳款註記',
    width: 110,
  },
];

export default function ShouldGetMoney() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const [fromDate, setFromDate] = React.useState(dayjs(formattedDate));
    const [toDate, setToDate] = React.useState(dayjs(formattedDate));
    const [rows,setRows] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [editedRows, setEditedRows] = React.useState([]);
    const [totalDeliveries, setTotalDeliveries] = useState(0);
    const [totalReceivables, setTotalReceivables] = useState(0);

    const [selectedOption, setSelectedOption] = useState({
      id: 753,
      label: null,
      manufacturer_Seq: "4500001",
      manufacturer_AbbreviationName: "台日",
      manufacturer_FullName: "台灣日立江森自控股份有限公司",
      manufacturer_UniformNum: "11915305",
      manufacturer_CityCode: "33810",
      manufacturer_FaxNum: "03-3245321",
      manufacturer_BossName: null,
      manufacturer_ContactName: null,
      manufacturer_CompanyAddress: "台北市南京東路三段63號",
      manufacturer_CompanyPhone: "03-3244711",
      manufacturer_CompanyFax: "03-3245321",
      manufacturer_ShipAddress: "桃園縣蘆竹鄉內厝村內溪路２９號",
      manufacturer_ShipAddress2: null,
      manufacturer_ShipAddress3: null,
      manufacturer_FactoryNum: null,
      manufacturer_FactoryFax: null,
      manufacturer_AccCode: "6116",
      manufacturer_PayMent: null
  });
    const [request, setRequest] = useState({
        manufacturer:selectedOption !== null? selectedOption.manufacturer_AbbreviationName : "",
        company:'福蒙',
        from:'',
        to:'',
        fromDate:'',
        toDate:'',  
        moneyStatus:'全部',
        filterWay:'',
    });

    const handleSave = async () => {
      try {
          const response = await axios.post(`${apiUrl}/ReceviableAccount/UpdatePrice`, rows);
          if (response.status === 200) {     
              setEditedRows([]);
              alert('修改成功');
              handleSearch();
          } else {
              alert('修改失敗');
          }
      } catch (error) {
          console.error('Failed to save data:', error);
          alert('修改失敗');
      }
  };

    const fetchOptions = async () => {
      try {    
        const response = await axios.get(`${apiUrl}/Manufacturer/list`);
        if (response.status === 200) {
          setOptions(response.data.manufacturers);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const handleChange = (event, newValue) => {
      setSelectedOption(newValue);
      if(newValue === null || newValue === undefined) return;
      setRequest((prevRequest) => ({   
          ...prevRequest,
          manufacturer: newValue.manufacturer_AbbreviationName,
      })); 
  };

  const handleSearch = async () => {
    const fromDateFormatted = dayjs(fromDate.$d).format('YYYY/MM/DD');
    const toDateFormatted = dayjs(toDate.$d).format('YYYY/MM/DD');

    // 构建请求参数
    const params = {
        ...request,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
    };

    console.log(params);

    try {
        // 发送GET请求
        const response = await axios.get(`${apiUrl}/ReceviableAccount/FilterShipData`, { params });
        if (response.status === 200) {
            console.log('Data received:', response.data);
            const deliverySum = response.data.reduce((sum, item) => sum + item.實交貨數, 0);
            const receivableSum = response.data.reduce((sum, item) => sum + item.應收金額, 0);
            setTotalDeliveries(deliverySum);
            setTotalReceivables(receivableSum);
            setRows(response.data);
            
        } else {
            alert('请求失败');
        }
    } catch (error) {
        console.error('Failed to fetch data:', error);
        alert('请求失败');
    }
  };

  const handleFilterShipDataList = async () => {
    const fromDateFormatted = dayjs(fromDate.$d).format('YYYY/MM/DD');
    const toDateFormatted = dayjs(toDate.$d).format('YYYY/MM/DD');

    // 构建请求参数
    const params = {
        ...request,
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
    };

    console.log(params);

    try {
        // 发送GET请求，设置响应类型为 blob 以接收 PDF 文件
        const response = await axios.get(`${apiUrl}/ReceviableAccount/FilterShipDataList`, {
            params,
            responseType: 'blob'  // 确保处理返回的内容为二进制数据
        });
        if (response.status === 200) {
            // 创建 Blob URL 并在新标签页打开
            const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            window.open(fileURL, '_blank');
            console.log('PDF file received and opened in a new tab.');
        } else {
            alert('请求失败');
        }
    } catch (error) {
        console.error('Failed to fetch data:', error);
        alert('请求失败');
    }
};


  const processRowUpdate = (newRow, oldRow) => {
    // 透過 newRow 的 id 找到 editedRows 陣列中的索引
    const index = editedRows.findIndex(row => row.id === newRow.id);
  
    // 若找到相同的 id，則先刪除
    if (index > -1) {
      editedRows.splice(index, 1);
    }
  
    // 將 newRow 加入 editedRows 陣列
    setEditedRows([...editedRows, newRow]);
    return newRow;
  };

  const handleRequestInputChange = (value, fieldName) => {
      setRequest((prevRequest) => ({
          ...prevRequest,
          [fieldName]: value.target ? value.target.value : value, // conditional check for value
      }));   
  };
    
    useEffect(() => {
      fetchOptions();
  }, []); 
  return (
    <>
     <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
        <Box sx={{ width: 300 }}>
            選擇客戶
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">客戶</InputLabel>
              <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={options}
                  sx={{ width: '100%' }}
                  value={selectedOption}
                  onChange={handleChange}
                  getOptionLabel={(option) => option.manufacturer_Seq+ option.manufacturer_AbbreviationName}
                  // 設置renderInput來渲染輸入元件
                  renderInput={(params) => <TextField {...params} label="廠商" />}
              />
            <FormLabel id="demo-row-radio-buttons-group-label">帳款狀態</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={request.moneyStatus}
                    onChange={(e) => handleRequestInputChange(e.target.value, 'moneyStatus')}
                >
                    <FormControlLabel value="未請款" control={<Radio />} label="未請款" />
                    <FormControlLabel value="已請款" control={<Radio />} label="已請款" />
                    <FormControlLabel value="全部" control={<Radio />} label="全部" />
                </RadioGroup>
            </FormControl>
        </Box>
        <Box sx={{ width: 300 }}>
            過濾條件
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">承製公司</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={request.company}
                label="Age"
                onChange={(e) => handleRequestInputChange(e.target.value, 'company')}
            >
                <MenuItem value='福蒙'>福蒙</MenuItem>
                <MenuItem value='華辰'>華辰</MenuItem>
                <MenuItem value='全部'>全部</MenuItem>
            </Select>
            <FormLabel id="demo-row-radio-buttons-group-label">搜尋條件</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={request.filterWay}
                    onChange={(e) => handleRequestInputChange(e.target.value, 'filterWay')}
                >
                    <FormControlLabel value="批號" control={<Radio />} label="批號" />
                    <FormControlLabel value="圖號" control={<Radio />} label="圖號" />
                    <FormControlLabel value="機種" control={<Radio />} label="機種" />
                </RadioGroup>
            </FormControl>
        </Box>
        <Box sx={{ width: 300 }}>         
            <TextField id="outlined-basic" label="從" variant="outlined" value={request.from} onChange={(e) => handleRequestInputChange(e.target.value, 'from')}/>          
            <TextField id="outlined-basic" label="到" variant="outlined"  value={request.to} onChange={(e) => handleRequestInputChange(e.target.value, 'to')}/>
        </Box>
        <Box sx={{ width: 300 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                    label="出貨日起"
                    value={fromDate}
                    onChange={(newValue) => setFromDate(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                    label="出貨日止"
                    value={toDate}
                    onChange={(newValue) => setToDate(newValue)}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    </Box>
    <Box sx={{ height: 600, width: '100%' }}>
    <Button variant="text" onClick={handleSearch}>搜尋</Button>
    <Button variant="text" endIcon={<SaveIcon />}  onClick={handleSave}>
      更新單價
    </Button>
    <Button variant="text" onClick={handleFilterShipDataList}>列印</Button>
    <div>交貨總數：{totalDeliveries.toLocaleString('zh-TW', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
    <div>應收帳款總計：{totalReceivables.toLocaleString('zh-TW', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: {
                pageSize: 100,
                },
            },
            }}
            pageSizeOptions={[100]}
            disableRowSelectionOnClick
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={error=>alert(error)}
            onRowEditCommit={(rowId, e) => {
              handleSave(rowId,e);
            }}
        />
    </Box>
    </>
  );
}